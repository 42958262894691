/* global time setting css start */
.ib__voice_send_modal .global__send__module__time__setting{
    text-align: center;
    border: 1px solid #ddd;
    padding: 23px 20px;
    border-radius: 6px;
    margin: 10px 0 0 0;
    width: 100%;
}
.ib__voice_send_modal .g__sms__custom_date_wrapper{
    display: flex;
    padding: 20px 0 0;
    grid-gap: 20px;
    justify-content: center;
}
.ib__voice_send_modal .time__setting__tabs{
    display: flex;
    justify-content: center;
    grid-gap: 15px;
}
.ib__voice_send_modal .global__send__module__time__setting__title{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    max-width: unset !important;
}
.ib__voice_send_modal .time__setting__tabs button{
    box-shadow: 0,0,0 rgba(0,0,0,0.0);
}
.ib__voice_send_modal .g__sms__send__date__alt__title,
.ib__voice_send_modal .g__sms__send__time__alt__title
{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--dark_blue);
    text-align: left;
}
.ib__voice_send_modal .g__sms__send__date__alt .MuiTextField-root,
.ib__voice_send_modal .g__sms__send__time__alt .MuiTextField-root {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 6px;
}
.ib__voice_send_modal .gloabal__send__module__time__setting__container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.ib__voice_send_modal .g__sms__send__date__alt .MuiTextField-root .MuiInputBase-input,
.ib__voice_send_modal .g__sms__send__time__alt .MuiTextField-root .MuiInputBase-input{
    height: 48px !important;
    margin: 0 !important;
}

.ib__voice_send_modal .time__setting__tabs button.MuiButtonBase-root.MuiButton-root {
    padding: 6px 16px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
}
/* global time setting css end */

/* for time error message */
.ib__voice_send_modal .global_sms_send_schedule_error_container{
    margin-top: 10px;
    color: var(--red);
    display: flex;
    justify-content: space-between;
}
.ib__voice_send_modal .global_sms_send_schedule_error_container span{
    /* width: 50%; */
    padding-left: 20px;
}