.headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(148, 157, 178, 0.24);
}

.headerTitle {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #3a414a;
    width: 90%;
    text-align: center;
}

.redButton {
    border-color: #e81313 !important;
    color: #e81313 !important;
    border-width: 2px !important;
    font-weight: 700 !important;
}

.bodyContainer {
    padding: 0 14px 24px;
    width: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    margin-top: 48px;
}

.bodyContainer::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.bodyContainer::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}

.bodyContainer::-webkit-scrollbar {
    scrollbar-width: thin;
}

.bodyContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}

.bodyContainer::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.modalBody {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.responseMessage {
    font-size: 13px;
    font-weight: 600 !important;
    padding: 0 25px;
    color: #3a414a;
    line-height: 1.2;
}

.modalFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    padding: 0 14px 24px;
    width: 600px;
}

.modalFooterCenter {
    justify-content: center !important;
}

.howToCheck {
    color: #3a414a;
    font-size: 11pt;
    font-weight: bold;
}

.direction {
    color: #3a414a;
    font-size: 11pt;
    font-style: italic;
}

.footerMessage {
    color: #3a414a;
    font-size: 8pt;
    font-style: italic;
    text-align: center;
}

.bold {
    font-weight: bold;
}

.thanksMessage {
    color: #1071e5;
    font-size: 14pt;
    font-weight: bold;
    margin-bottom: 4px;
}

.sorryMessage {
    color: #e12525;
    font-size: 14pt;
    font-weight: bold;
    margin-bottom: 4px;
}

.statueMessage {
    color: #3a414a;
    font-size: 13pt;
    font-weight: bold;
}

.helperTextContainer {
    margin-top: 30px;
}

.underlineText {
    border-bottom: 2px solid #3a414a;
}

.responseMessageButton {
    background-color: var(--blue-2);
    padding: 7px 11px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}