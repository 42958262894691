.App {
    width: 100%;
    margin: 20px auto;
    position: relative;
}

.WroCl {
    height: 100%;
    position: relative;
    width: 100%;
    min-height: 210px;
    overflow: hidden;
    border-radius: 10px;
}
.WroCl__custom {
    height: 100%;
    position: unset !important;
    width: 100%;
    min-height: 195px;
    overflow: hidden;
    border-radius: 10px;
}

.WroCl * {
    box-sizing: inherit;
}

.hppFhA {
    width: 100%;
    height: 100%;
}

.gpOkVY {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: 100%;
    transform: translate(-0%, -0%) scaleX(-1);
}
.gpOkVY__custom{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.MccLt {
    position: absolute;
    bottom: 35px;
    left: 0px;
    right: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 20px;
    z-index: 9;
}

.fWXksH {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.bTwNuH {
    font-family: Arial;
    font-size: 14px;
    color: rgb(255, 255, 255);
    letter-spacing: 1.75px;
    display: flex;
    margin-bottom: 5px;
}

.iSDLnZ {
    font-weight: 700;
    color: rgb(220, 101, 71);
    padding: 0px 5px;
}

.fFdAUW {
    height: 30px;
    width: 30px;
    border-width: 8px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.4);
    border-image: initial;
    border-radius: 50%;
}

.eTRucs {
    color: black;
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 50%;
    background: rgba(227, 73, 28, 0.8);
    outline: none;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    display: grid;
}

.eTRucs:hover {
    background: rgb(251, 109, 66);
}

.fJFZuh {
    position: absolute;
    top: 20px;
    right: 180px;
    font-family: Menlo, monospace;
    font-size: 20px;
    text-shadow: 1px 2px rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
}

.main {
    /*width: 50%;*/
    text-align: center;
}

.messageTitle {
    color: black;
}

.progresCircle {
    background-color: coral;
    width: 145px;
    height: 145px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-left: 5px;
    display: flex;
}

.progressTitle {
    color: white;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}

.progressArea {
    width: 160px;
    height: 160px;
    padding: 10px;
    margin: 0 auto;
}

.MuiCircularProgress-root {
    position: absolute;
}

.video-custom {
    width: auto;
    height: 300px;
}

.video_preview_side {
    width: 100%;
    min-height: 210px;
    display: inline-block;
    text-align: center;
    height: fit-content;
}
.custom__video_preview_side{
    padding: 0 !important;
    min-height: 280px !important;
    margin: 14px 0;
    width: 526px;
}

.video_preview_side h4{
    margin-top: 20%;
    margin-bottom: 10px;
}

.video-area {
    width: 100%;
    height: auto;
}

.video-right {
    display: flex;
    position: relative
}

.play-button {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: all 0.3s ease 0s !important;
    background: none;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    padding: 0px;
    outline: none;
    width: 75px !important;
    height: 75px !important;
    z-index: 999;
}

.play-button .MuiFab-label .MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    transition: all 0.3s ease 0s;
}

.video-inner {
    height: auto;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    text-align: center;
}
.video__inner__custom{
    height: 280px;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    text-align: center;
}

#video-static {
    width: 100%;
    height: auto;
    cursor: pointer;
    object-fit: cover;
    animation: 1s ease-out 0s 1 normal none running eMLfYp;
    background-repeat: no-repeat;
    border-radius: inherit;
}

.video-inner:hover .play-button {
    opacity: 0.9;
}

.play-button:active, .play-button:focus, .play-button:hover {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
    outline: 0px;
    background: rgb(255, 255, 255);
}

.dNKPms {
    background: #dedede;
    color: #5374a7;
    font-size: 15px;
    padding: 10px;

}

.cedskp {
    margin-top: 150px;
}

.SVGInline-svg {
    height: 70px;
    width: 130px;
}

.likeArea {
    width: 33%;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 11%;
    bottom: 15%;
    color: #fff;
    font-weight: 600;
}

.likeArea p{
    font-size: 16px;
    font-weight: 600;
}

.buttons {
    display: flex;
    justify-content: center;
}

.button-set {
    margin-right: 40px;
    text-align: center;
    cursor: pointer;
}

.button-set p {
    font-family: monospace;
    color: #dddddd;
    margin: 0;
}

.button-set i.fa-times-circle-o {
    font-size: 50px;
    color: #dddddd;
}

.button-set i.fa-check-circle-o {
    font-size: 50px;
    color: #3498db;
}

.button-set:hover i.fa-times-circle-o {
    color: #3498db;
}

.button-set:hover i.fa-check-circle-o {
    color: #ff7171;
}


.button-set i.heart-animate {
    -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: .5s; /* Safari 4.0 - 8.0 */
    animation: color-change infinite;
    animation-duration: .5s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes color-change {
    0%   {color:#3498db;}
    100% {color:#ff7171;}
}

/* Standard syntax */
@keyframes color-change {
    0%   {color:#3498db;}
    100% {color:#ff7171;}
}

.countdown {
    font-size: 7em;
}

.timer {
    position: absolute;
    left: 66%;
    bottom: 85%;
    z-index: 9;
    width: 100px;
}

.timer span {
    font-size: 23px;
    font-weight: 500;
    font-family: sans-serif;
    background-color: #3e3e3a8c;
    color: #ffffff;
    padding: 0px 5px;
}

.vJroN {
    width: 16px;
    height: 16px;
    float: left;
    background: rgb(229, 82, 38);
    border-radius: 50%;
    margin: 9px 8px 2px 0px;
}

.bPvwx {
    height: 35px;
    width: 35px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    text-align: center;
    display: grid;
    place-items: center;
}

.dBzviu {
    color: black;
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 4px;
    background: rgba(227, 73, 28, 0.8);
    outline: none;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}

.video_manage_side {
    width: 48%;
    /*height: 340px;*/
    float: right;
}

.cnsvMr {
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    vertical-align: middle;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 10px;
}
.elJgpH {
    cursor: pointer;
    pointer-events: initial;
    width: 90px;
    height: 90px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 30px 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    text-transform: uppercase;
    font-family: "Apercu Pro", apercu-pro, Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700;
    line-height: 16px;
    font-size: 11px;
    letter-spacing: 0.916667px;
    transform: perspective(1000px) translateZ(0px);
    border-radius: 16px;
    background: #34bfa3;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    outline: none;
    margin: 6px;
    transition: all 0.1s ease-in-out 0s;
}

.elJgpH i {
    font-size: 30px !important;
}

/*Loader start here*/
@keyframes ldio-v2sf9rqgjxh {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-v2sf9rqgjxh div {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 3px solid #1d3f72;
    border-top-color: transparent;
    border-radius: 50%;
}
.ldio-v2sf9rqgjxh div {
    animation: ldio-v2sf9rqgjxh 0.6711409395973155s linear infinite;
    top: 20px;
    left: 20px
}
.loadingio-spinner-rolling-hz4rqxhyeck {
    width: 100%;
    height: 40px;
    display: inline-block;
    overflow: hidden;
    background: 0 0;
}
.ldio-v2sf9rqgjxh {
    width: 40px;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    margin: 0 auto;
}
.ldio-v2sf9rqgjxh div { box-sizing: content-box; }

/*Loader end here*/
.video-section {
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
}

.video-list {
    max-height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
}

.video-list .video{
    height: 75px;
    width: 30%;
    display: inline-block;
    margin: 5px;
    max-width: 110px;
}

.video-list .video img{
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.video .no-data {
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    padding-top: 25%;
    height: 100%;
    width: 100%;
    color: #575962;
}

.video a {
    position: relative;
}

.video .view_counter {
    display: flex;
    position: absolute;
    top: -23px;
    right: 5px;
}

.view_counter span {
    margin-left: 5px;
    background-color: #90909085 !important;
    color: #fff;
    font-size: 13px;
}

.view_counter span i {
    font-size: 13px;
}

.video .video_video_delete {
    display: flex;
    position: absolute;
    top: -23px;
    left: 0px;
}

.video_video_delete span {
    margin-left: 5px;
    color: #fff;
    font-size: 13px;
}

.video_video_delete span i {
    font-size: 13px;
}

.video .video_view_title {
    display: flex;
    position: absolute;
    left: -2px;
    bottom: -28px;
}

.video_view_title span {
    margin-left: 5px;
    color: #fff;
    font-size: 13px;
}

.video_view_title span i {
    font-size: 13px;
}

.progress {
    border-radius: 1.25rem;
    margin-top: 40%;
}

/* New Video Style */
.video_preview_side .likeArea {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 20%;
    background: #000000bf;
}

body .video_preview_side {
    position: relative;
}

.video_preview_side .likeArea input, .video_preview_side .likeArea textarea, .video_preview_side .likeArea select {
    padding: 8px 12px;
    border: 0;
    border-radius: 0.4rem;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    background: rgba(255,255,255,0.4);
}

.video_preview_side .likeArea input:focus, .video_preview_side .likeArea textarea:focus, .video_preview_side .likeArea select:focus {
    outline: 0;
}

.likeArea textarea {
    margin: 8px 0;
}

.search-input {
    width: 75%;
    border-radius: 0.4rem;
    font-size: 15px;
}

.modal__scroll .video_contents {
    margin: 30px;
}

.voice_mail_icon {
    cursor: pointer;
}

.voice_mail_icon span, .voice_mail_icon label {
    height: 40px;
    width: 40px;
}
.video_library_wrapper {
    width: 850px;
}

.video_contents .video_email_bg_icon {
    flex-direction: column;
    text-align: center;
    padding: 10px !important;
}

.video_contents .voice_mail_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.video_contents.update_video_contents .video_email_bg_icon {
        background: url(https://s3.us-east-1.amazonaws.com/pypepro/videoFiles/2/2-1629956551.gif) no-repeat center center/ cover;
        min-height: 380px;
        overflow: hidden;
        position: relative;
}
.video_contents.update_video_contents .video_email_bg_icon p.overlay_text {
	z-index: 1;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	bottom: 20%;
	transform: translateY(-50%);
	height: auto;
	left: 0;
	right: 0;
	width: fit-content;
	padding: 10px;
	margin: 0 auto;
	border-radius: 4px;
	overflow: hidden;
	box-sizing: border-box;
}

.video_contents.update_video_contents .video_email_bg_icon p.overlay_text::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.55);
        z-index: -1;
}
.video_contents.update_video_contents .video_email_bg_icon p {
	color: #fff;
}
.video_contents.update_video_contents .flexItemCenter {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 380px;
}

.video_contents.update_video_contents video {
	margin-top: 0;
}

.update_video_contents .video_preview_side { 
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    height: auto;
    box-sizing: border-box;
}

.update_video_contents #video-static {
	object-fit: cover;
}

.update_video_contents .video_preview_side .likeArea {
	bottom: 60px;
}

.update_video_contents .video_preview_side .likeArea textarea::placeholder,
.update_video_contents .video_preview_side .likeArea input::placeholder {
        color: #000;
        opacity: 1;
        font-weight: 400;

}
.update_video_contents .video_preview_side .likeArea textarea,
.update_video_contents .video_preview_side .likeArea input {
        margin-bottom: 0;
        box-shadow: unset;
        color: #000;
        font-weight: 400;
        height: 45px;
}

.update_video_contents .video_preview_side .likeArea textarea {
        margin-bottom: 6px;
        resize: none;
        height: 50px;
}

body .update_video_contents .video_preview_side .likeArea input:focus {
        box-shadow: unset;
        border: 0;
}

body .update_video_contents .video_preview_side .likeArea .buttons {
        margin-top: 4px;
}
.update_video_contents .timer {
        bottom: unset; 
        left: unset;
        right: 0;
        top: 10px;
}

.cursor-pointer {
	cursor: pointer;
}

.update_countdown {
	font-size: 35px;
}
@media (min-width: 1025px) {
  .video_contents.update_video_contents .video_preview_side {
	  padding-right: 0;
  }
}

@media (max-width: 1024px) {
	.globalModalWrapper.cardBordcastTab.videoEmailModalWrapperV3.full-height-video-mail-modal {
		max-width: 950px;
		width: 90% !important;
		min-width: unset !important;
	}
	.video_contents.update_video_contents .flexItemCenter {
		padding: 40px 0;
		min-height: unset;
		
	}
}

.video_contents__alt .video_warning h4{
    font-size: 23px;
    margin-bottom: 10px;
}

.video_contents__alt .video_warning{
    text-align: center;
}

.video_contents__alt .video_warning p{
    font-size: 13px;
}

.likeArea .template_info input, .likeArea .template_info input{
    height: 30px;
}