.activity__modal_overflow .contact__search__again__btn{
    border: 1px solid var(--direct_mail_green);
    background: var(--direct_mail_green);
    padding: 8px 12px;
    margin: 6px 0 14px 11px;
    color: #fff !important;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
}
.scheduleSelectFieldWrapper input {
    height: 35px!important;
    margin: 0!important;
}
.text-danger{
    color:#dc3545!important;
}