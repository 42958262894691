.contact_timeline_email_body img {
    max-width: 100%;
}

.contact_timeline_email_body h1 {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    font-size: 40px;
   }

.inbox-chat-text{
    background: rgba(60, 126, 243, 0.1);
    border-radius: 10px;
    min-height: 40px;
    max-width: 500px;
    padding:16px;

}
.inbox-chat-text-header{
    background: var(--accent-color) ;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    padding: 4px 14px;
}

.inbox-chat-text-rm-padding{
    padding: 0px !important;
}


.inbox-chat-text-email{
   padding:4px 16px;
}

.chat__reply{
    background:#3C7EF3;
    gap:6px;
    display:flex;
    float:right;
    color:white;
    padding: 5px 11px 5px 5px;
    margin:10px 12px 10px 0;
    border-radius:4px;
    font-size:12px;
    cursor: pointer;
}


/* .contact_timeline_email_body span {
   font-size: 30px;
} */