.review_wrapper {
    background: white;
    border-radius: 5px;
    padding: 10px;
    margin-top: 18px;
}
.review_wrapper .call__review{
    margin-bottom: 45px;
    text-align: center;
}
.review_wrapper .form-group label{
    font-size: 20px;
}
.review_wrapper .form-group input.form-control{
    border-radius: 5px;
    padding-left: 10px;
    margin-top: 18px;
    border: 1px solid #f2f2f2;
}
.review_wrapper .form-group button {
    margin: 10px;
}
.review__star {
    display: flex;
    align-items: revert;
    text-align: center;
    justify-content: space-evenly;
    width: 75%;
    margin: 30px auto;
}
.review__star .material-icons {
    font-size: 30px;
    cursor: pointer;
}
.review__star .material-icons {
    color: #08be7c;
}

.call__review_wrapper .call_outcome, .call__review_wrapper .review_right {
    background: white;
    border: 1px solid #dddddd;
    border-radius: 5px;
    height: 340px;
    display: grid;
    place-items: center;
    align-content: center;
}

.call__review_wrapper .call_outcome label {
    color: #212121;
    font-size: 15px;
}
