/* ----==============Progess bar css================--- */
.prgoress_bar {
    margin-top: -13px;
}

.text {
    text-align: end;
}

.back_btn {
    margin-left: 120px;
    margin-top: 40px;
}

.back_btn button {
    border: unset;
    background: unset;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.65);
    cursor: pointer;
    text-transform: capitalize;
    position: relative;
    background: #ddd;
    padding: 8px 16px 8px 36px;
    border-radius: 4px;
    z-index: 1;
}

.back_btn button:focus {
    color: rgba(19, 49, 89, 0.65);
    background: #ddd;
}

.back_btn button::before {
    content: '';
    background: url(./images/signup/back_btn_iocn.svg);
    height: 20px;
    width: 16px;
    z-index: 1;
    position: absolute;
    left: 6px;
}

.next_btn button {
    border: unset;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #fff;
    width: 100%;
    cursor: pointer;
    padding: 13px 0px;
    text-transform: capitalize;
    background: var(--light_blue);
    border-radius: 5px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* .next_btn button::after{ content: ''; background: url(./images/signup/next_btn_icon.svg); height: 20px; width: 16px; position: absolute; top: 14px; right: 183px; } */
.next_btn button:disabled {
    color: rgba(19, 49, 89, 0.65);
    background: #E5E8EF;
}

.topbar_content {
    margin: 20px 50px;
}

.from_content h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    margin-bottom: 25px;
}

.login_form_input input {
    background: #F6F7FB !important;
    border: 1px solid rgba(60, 126, 243, 0.1) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.35);
    padding: 2px 10px !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid rgba(60, 126, 243, 0.1) !important;
}

.login_form_input input:focus {
    box-shadow: unset !important;
    border-bottom: 1px solid rgba(60, 126, 243, 0.1) !important;
}

input.invalid:not([type]), input.invalid:not([type]):focus, input[type=text].invalid:not(.browser-default), input[type=text].invalid:not(.browser-default):focus, input[type=password].invalid:not(.browser-default), input[type=password].invalid:not(.browser-default):focus, input[type=email].invalid:not(.browser-default), input[type=email].invalid:not(.browser-default):focus, input[type=url].invalid:not(.browser-default), input[type=url].invalid:not(.browser-default):focus, input[type=time].invalid:not(.browser-default), input[type=time].invalid:not(.browser-default):focus, input[type=date].invalid:not(.browser-default), input[type=date].invalid:not(.browser-default):focus, input[type=datetime].invalid:not(.browser-default), input[type=datetime].invalid:not(.browser-default):focus, input[type=datetime-local].invalid:not(.browser-default), input[type=datetime-local].invalid:not(.browser-default):focus, input[type=tel].invalid:not(.browser-default), input[type=tel].invalid:not(.browser-default):focus, input[type=number].invalid:not(.browser-default), input[type=number].invalid:not(.browser-default):focus, input[type=search].invalid:not(.browser-default), input[type=search].invalid:not(.browser-default):focus, textarea.materialize-textarea.invalid, textarea.materialize-textarea.invalid:focus, .select-wrapper.invalid>input.select-dropdown, .select-wrapper.invalid>input.select-dropdown:focus {
    box-shadow: 1px solid rgba(60, 126, 243, 0.1) !important;
    border-bottom: 1px solid rgba(60, 126, 243, 0.1) !important;
}

.login_form_input label {
    top: -10px !important;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
}

input:not([type]):focus:not([readonly])+label, input[type=text]:not(.browser-default):focus:not([readonly])+label, input[type=password]:not(.browser-default):focus:not([readonly])+label, input[type=email]:not(.browser-default):focus:not([readonly])+label, input[type=url]:not(.browser-default):focus:not([readonly])+label, input[type=time]:not(.browser-default):focus:not([readonly])+label, input[type=date]:not(.browser-default):focus:not([readonly])+label, input[type=datetime]:not(.browser-default):focus:not([readonly])+label, input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, input[type=tel]:not(.browser-default):focus:not([readonly])+label, input[type=number]:not(.browser-default):focus:not([readonly])+label, input[type=search]:not(.browser-default):focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label {
    color: #3C7EF3;
}

.copyright_text {
    margin: 0 12px;
    clear: both;
    position: absolute;
    top: 560px;
    /* width: 100%; */
    text-align: center;
    left: 50%;
    transform:translateX(-50%);
}

/* Style the form */
.from_content {
    max-width: 450px;
    width: 100%;
}

/* Style the input fields */
input {
    padding: 10px;
    max-width: 100%;
    font-size: 17px;
    font-family: Raleway;
    border: 1px solid #aaaaaa;
}

/* Mark input boxes that gets an error on validation: */
input.invalid {
    background-color: #ffdddd;
}

/* Hide all steps by default: */
.tab {
    display: none;
}

/* Make circles that indicate the steps of the form: */
.step {
    height: 5px;
    width: 25%;
    margin: 0 -2px;
    background-color: #fff;
    border: none;
    display: inline-block;
}

/* Mark the active step: */
.step.active {
    opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
    background-color: #3C7EF3;
}

.custom__container {
    max-width: 1470px;
    margin: 55px auto;
}

.singup_right img {
    position: relative;
}

.singup_right span {
    position: absolute;
    top: 136px;
    right: 54px;
}

body .singup_left {
    width: 100%;
    display: flex;
    justify-content: center;
}

body .singup_right {
    width: 607px !important;
    padding: 0 !important;
}

.company_select input {
    border: 1px solid #3C7EF3 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 2px 2px rgb(60 126 243 / 10%) !important;
    border-radius: 5px !important;
    border-bottom: 1px solid #3C7EF3 !important;
    margin-bottom: 42px !important;
    padding: 10px 14px !important;
}

.company_select {
    position: relative;
    margin-top: 40px;
}

.company_select .caret {
    display: none;
}

.company_select .select_icon {
    position: absolute;
    right: 20px;
    top: 12px;
}

.company_select label {
    top: -40px;
    font-size: 16px;
    color: var(--dark_blue);
}

.company_select .dropdown-content {
    top: 45px !important;
}

.input_company_name {
    margin-top: 40px !important;
}

/* ==============Packages page css============= */
.price_packages {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 30px;
}

.packages_content h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #3C7EF3;
    margin: 0;
}

.packages_content p {
    font-size: 16px;
    line-height: 24px;
    color: rgba(15, 36, 64, 0.65);
    margin: 0;
}

.packages_content p span {
    font-size: 12px;
    line-height: 18px;
    color: #68EBCA;
}

.packages_content {
    clear: both;
    background: #ffffff;
    padding: 60px;
}

.mnth_year_content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.first_price_list {
    max-width: 300px;
    width: 100%;
    box-shadow: 0px 10px 10px -5px rgba(60, 126, 243, 0.25);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    border: 1px solid #cdcdcd;
}

.first_price_list .ribon {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    position: absolute;
    color: var(--white);
    background: var(--light_blue);
    text-transform: capitalize;
    transform: rotate(-42.48deg);
    padding: 7px 36px;
    top: 26px;
    left: -36px;
}

.first_price_list .card-content {
    padding: unset;
    padding-top: 20px;
    min-height: 78px;
}

.center_price_list h5 {
    margin-top: 48px !important;
}

.first_price_list h5 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #133159;
}

.first_price_list h1 {
    font-weight: 500;
    font-size: 72px;
    line-height: 108px;
    letter-spacing: 0.01em;
    color: #3C7EF3;
    text-transform: uppercase;
}

.price_content {
    padding-top: 0px !important;
}

.price_content small {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #3C7EF3;
    position: absolute;
    left: 60px;
    top: 112px;
}

.secong_pric_list small {
    top: 64px !important;
    left: 55px !important;
}

.price_content h2 {
    font-weight: 500;
    font-size: 72px;
    line-height: 108px;
    letter-spacing: 0.01em;
    color: var(--light_blue) !important;
    margin: 0;
    position: relative;
    display: inline-block;
}

.price_content h2 span {
    font-weight: normal;
    font-size: 20px;
    line-height: 36px;
    text-transform: capitalize;
    color: rgba(19, 49, 89, 0.65);
}

.price_content sup {
    position: absolute;
    top: 12%;
    left: -21px;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
}

.price_content p {
    margin: 0 !important;
    margin-top: -20px !important;
}

.show_more_btn {
    transform: rotate(90deg);
    margin-left: -54px;
    background: #3C7EF3;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 20px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    cursor: pointer;
}

.switch label .lever {
    background: rgba(60, 126, 243, 0.2);
    border-radius: 10px;
}

.switch label .lever:after {
    background-color: #3C7EF3;
}

.switch label .lever:before {
    background: rgba(60, 126, 243, 0.2) !important;
}

.switch label input[type=checkbox]:checked+.lever {
    background: rgba(60, 126, 243, 0.2);
}

.switch label input[type=checkbox]:checked+.lever:after {
    background-color: #3C7EF3;
}

.mnth_year_content label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
}

.mnth_year_content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: rgba(19, 49, 89, 0.35);
    margin-left: 30px;
}

.pric_list_content {
    border: unset !important;
}

.pric_list_content .collection-item {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    color: rgba(19, 49, 89, 0.65);
    padding: 2px 20px;
}

.pric_list_content .collection-item span {
    margin-right: 8px;
}

.pric_list_content .collection-item:last-child {
    border-bottom: 1px solid #e0e0e0 !important;
}

.select_plan_btn {
    padding-bottom: 20px;
}

.select_plan_btn button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--light_blue);
    background: var(--white);
    box-shadow: unset;
    padding: 15px 65px 40px 65px;
    text-transform: capitalize;
}

.select_plan_btn button {
    box-shadow: unset;
    color: var(--white);
    background: var(--light_blue);
    border-radius: 5px;
}

.select_plan_btn button:hover {
    box-shadow: unset;
    color: var(--white);
    background: var(--light_blue);
    border-radius: 5px;
}

/* ===============Payment css============== */
.price_package_content {
    padding-top: 30px;
    display: flex;
    background: #E5E5E5;
    min-height: 100vh;
}

.price_package_content .col.xl7.payment_content, .price_package_content .col.xl5 {
    width: auto;
    background: #fff;
    margin: 0;
    border-radius: 10px;
    padding: 25px;
}

.signup__wrapper__wr_v2 .price_package_content{
    min-height: 100vh !important;
    padding-bottom: 30px;
    height: unset !important;
}
.price_package_content .col.xl7.payment_content {
    max-width: 789px;
    width: 100%;
}

.price_package_content .price_checkout {
    max-width: 400px;
    width: 100%;
    border-bottom: 3px solid #3C7EF3;
}

.price_package_content>.row {
    max-width: 1289px;
    width: 100%;
    column-gap: 100px;
    display: flex;
    margin: 0 auto;
    align-items: flex-start;
}

.price_package_content>.row::after {
    display: none;
}

.payment_content {
    max-width: 771px;
    width: 100%;
}

.top_title h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 0.01em;
    color: var(--light_blue);
    margin: 0;
}

.top_title p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(15, 36, 64, 0.65);
    margin: 0;
}

.payment_card_form {
    margin-top: 10px;
}

.payment_card_form .row {
    margin-bottom: 10px !important;
}

.card_no_input input {
    padding: 24px 69px !important;
    background: #FFFFFF;
    border: 1px solid rgba(60, 126, 243, 0.3) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    position: relative;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.01em !important;
    color: var(--dark_blue) !important;
}

.card_no_input label {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.01em !important;
    color: var(--dark_blue) !important;
    top: -10px !important;
}

.card_no_input span {
    position: absolute;
    top: 6px;
    left: 10px;
    border-right: 1px solid #C5D8FB;
    ;
    padding-right: 5px;
}

.card_no_input input:focus {
    box-shadow: unset !important;
}

.payment_input input {
    background: #FFFFFF;
    border: 1px solid rgba(60, 126, 243, 0.3) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    padding: 24px 20px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.01em !important;
    color: var(--dark_blue) !important;
}

.payment_input input:focus {
    box-shadow: unset !important;
}

.payment_input label {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.01em !important;
    color: var(--dark_blue) !important;
    top: -10px !important;
}

/* =======Scrqure payment======= */
.secqure_payment {
    margin-top: -50px;
}

.secqure_payment h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    margin: 0;
}

.secqure_payment p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.65);
    text-align: center;
}

.packages ul {
    display: flex;
    justify-content: space-between;
}

.price_list h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: var(--dark_blue);
    margin: 0;
}

.price_list {
    margin-top: 20px;
}

.price_list p {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--light_blue);
    margin: 0;
}

.price_list p span {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: var(--dark_blue);
}

.warrning {
    padding-top: 40px;
}

.warrning p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: rgba(19, 49, 89, 0.65);
}

.warrning p span {
    color: var(--dark_blue);
}

.payment_btn {
    margin: 30px 0 0px;
}

.payment_btn a {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    text-transform: capitalize;
    background: var(--light_blue);
    border-radius: 5px;
    padding: 15px 65px;
}

.privecy_policy p {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: var(--light_blue);
}

.packages .package_list {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: rgba(19, 49, 89, 0.65);
}

.packages .price {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: var(--light_blue);
}

.packages .sub_total {
    color: var(--dark_blue);
    font-weight: 500;
}

.price_sub_total {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #3C7EF3;
}

.subtoral {
    border-top: 1px solid rgba(19, 49, 89, 0.05);
}

.tax {
    border-bottom: 1px solid rgba(19, 49, 89, 0.05);
}

/* ========Congratulations page css======= */
.congratulations_page {
    margin-top: 100px;
    padding: 20px;
}

.congratularion img {
    margin-top: 65px;
}

.congratularion h1 {
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    letter-spacing: 0.01em;
    color: var(--light_blue);
    margin: 60px 0 0;
}

.congratularion p {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.65);
    margin: 0;
}

.congratularion a {
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #3C7EF3;
}

#regForm input {
    font-family: var(--poppins) !important;
    font-size: 16px !important;
    color: var(--dark_blue);
    box-shadow: unset !important;
}

#regForm input:focus {
    box-shadow: unset !important;
}

#regForm .login_form { 
    margin-top: 0;
    margin-bottom: 0;
}

#regForm .login_form_input label.active {
    top: -10px !important;
    left: 10px;
}

#regForm .login_form_input label {
    top: -2px !important;
    left: 23px;
}

#regForm .input-field {
    margin-bottom: 0;
    margin-top: 25px;
}

.top__bar__content__v2 .top__bar__content__v2__logo{
    text-align: center;
}

.topbar_content .lognin_link p{
    margin-bottom: 0px;
    margin-top: 0px;
}

#regForm .copyright_text {
    margin: 0 12px;
    clear: both;
    top: 660px;
    padding-bottom: 30px;
}

@media (max-width: 1366px) {
    .copyright_text { 
        top: 615px;
    }
}

.from_content h5 { 
    margin-bottom: 10px;
}

/* Media Query */
@media(max-width: 1500px) {
    .custom__container {
        max-width: 1330px;
    }
}

@media(max-width: 1400px) {
    .custom__container {
        max-width: 1230px;
    }
}

@media(max-width: 1366px) {
    .custom__container {
        max-width: 1116px;
        margin-top: 50px !important;
    }

    .singup_right img {
        width: 500px;
        margin-left: 150px;
    }

    /* .next_btn button{ margin-top: -10px; } */
    .copyright_text {
        top: 630px;
    }

    .price_package_content>.row {
        max-width: calc(100% - 128px);
        margin: 0 64px;
        column-gap: 68px;
    }

    .price_package_content .col.xl7.payment_content {
        width: 771px;
    }

    .first_price_list h5 {
        font-size: 16px;
    }

    .first_price_list h1, .price_content h2 {
        font-size: 32px;
    }

    .pric_list_content .collection-item {
        font-size: 14px;
    }

    .privecy_text span {
        margin: 0 5px;
    }
}

@media(max-width: 1271px) {
    .packages_content {
        padding: 40px 5px;
    }
}

@media(max-width: 1024px) and (min-width: 800px) {
    .price_package_content>.row {
        max-width: calc(100% - 53px);
        margin: 0 31px;
        column-gap: 30px;
    }

    /* .singup_right{ display: none; } */
    .from_content {
        margin-left: 180px;
        margin-bottom: 100px;
    }

    .first_price_list {
        max-width: 280px;
        width: 100%;
    }
}

@media(max-width: 900px) {
    .price_package_content>.row {
        max-width: calc(100% - 39px);
        margin: 0 20px;
        column-gap: 20px;
    }

    .top_title h1 {
        font-size: 20px;
    }

    .price_package_content .col.xl7.payment_content {
        max-width: 400px;
        padding: 10px;
    }

    .card_no_input label, .payment_input label {
        font-size: 10px !important;
    }

    .bank_logo ul li img {
        height: 50px;
        width: 75px;
    }

    .top_title p {
        font-size: 12px;
    }

    .packages .package_list, .packages .price, .price_sub_total, .packages .sub_total, .privecy_policy p, .secqure_payment p {
        font-size: 10px;
    }

    .price_list p span, .price_list h2 {
        font-size: 16px;
    }

    .price_list p {
        font-size: 20px;
    }

    .packages_content p span, .warrning p {
        font-size: 8px;
    }

    .warrning {
        padding-top: 15px;
    }

    .payment_btn a {
        padding: 10px 40px;
    }

    .singup_right {
        display: none;
    }

    .from_content {
        width: 450px;
        margin-left: 180px;
        margin-bottom: 100px;
    }

    .first_price_list h5 {
        font-size: 10px;
    }

    .first_price_list h1 {
        font-size: 16px;
    }

    .pric_list_content .collection-item {
        font-size: 10px;
    }

    .select_plan_btn button {
        font-size: 12px;
    }
}

.signup__wrapper {
    background: white;
}

.signup__wrapper .pre-loader {
    height: 100vh;
    display: grid;
    place-items: center;
}

.from_content .sign__up_form input {
    background-color: #f9f9f9 !important;
}

.from_content .sign__up_company_type .MuiInputBase-root {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    background: #ebebeb;
    border-radius: 5px;
}

.packages_content__wrapper__alt.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    grid-gap: 15px;
    justify-content: center !important;
}

.packages_content__wrapper__alt.MuiGrid-container .MuiGrid-grid-xs-4, .packages_content__wrapper__alt.MuiGrid-container .MuiGrid-grid-lg-4 {
    flex-grow: unset !important;
    max-width: 490px !important;
    width: 100% !important;
    flex-basis: unset !important;
}

.packages_content__wrapper__alt .first_price_list {
    max-width: unset !important;
}

.packages_content__wrapper__alt .first_price_list .pric_list_content {
    display: inline-block;
    text-align: center !important;
}

.sign__up_company_type_dropdown .MuiMenu-paper {
    max-height: 400px;
}
.signup__wrapper__wr_v2 .back__button__update__lighter{
    margin: 25px 0 0 0 !important;
}

/* Updated Sign-up Form - 23.12.21 */
@media (max-width: 1366px) {
    .signup__wrapper {
        height: 100vh;
    }

    .signup__wrapper .singup_right {
        text-align: right;
        padding-right: 10px !important;
    }

    .signup__wrapper .singup_right img {
        margin-left: auto;
        max-width: 500px !important;
        width: 100% !important;
        height: auto;
    }
}

@media (max-width: 1200px) {
    .back_btn {
        margin-left: 0;
        margin-top: 80px;
    }

    .custom__container {
        max-width: 1000px;
    }

    .from_content h5 {
        font-size: 20px;
        margin-bottom: 15px;
    }
}

@media (max-width: 1024px) {
    .from_content {
        margin: 0 auto;
        max-width: 450px;
        width: 100% !important;
    }

    .custom__container {
        max-width: 100%;
        margin: 0;
    }

    .logo {
        position: fixed;
        top: 20px;
        left: 50px;
        margin: 0 !important;
    }

    #regForm .copyright_text {
        margin: 0 12px;
        clear: both;
    }

    .congratulations_page .site_logo img, .main_logo .login-page-logo, .topbar_content .logo .sign_up__logo {
        max-width: 200px;
        height: auto !important;
    }

    .topbar_content .lognin_link {
        position: fixed !important;
        right: 20px !important;
        top: 30px !important;
        left: auto !important;
        float: none;
        width: 100% !important;
    }

    .custom__container {
        margin-top: 100px;
    }

    .signup__wrapper .singup_right {
        text-align: right;
        padding-right: 10px !important;
        margin-left: auto !important;
        float: none;
        max-width: 450px !important;
    }

    .width100 {
        width: 100%;
    }

    .signup__wrapper .singup_right img {
        max-width: 450px !important;
        width: 100% !important;
    }
}

@media (max-width: 900px) {
    body .singup_left {
        width: 100% !important;
    }
    .signup__wrapper__wr_v2 .custom__container{
        margin-top: 20px !important;
    }

    .signup__wrapper__wr_v2 #card_no_input__v2__v3 .StripeElement{
        padding: 10px !important;
    }

    .packages_content h1 {
        font-size: 26px;
        line-height: 40px;
    }
}

@media (max-width: 759px) {
    #regForm .copyright_text {
        top: 620px;
    }

    .topbar_content .lognin_link p {
        font-size: 12px;
    }

    .topbar_content .back_btn {
        margin-left: 6px;
    }

    .topbar_content .lognin_link {
        top: 60px !important;
    }

    .logo {
        top: 10px;
        left: 0;
        right: 0;
        margin: 0 auto !important;
        text-align: center;
    }
    .top__bar__content__v2 .top__bar__content__v2__logo{
        top:51px !important;
    }
    .top__bar__content__v2 .top__bar__content__v2__logo .sign_up__logo{
        max-width: 171px !important;
    }

    .topbar_content>.col {
        width: 100% !important;
        padding: 0 !important;
    }
    
    .custom__container {
        margin-top: 30px;
    }
    .top__bar__content__v2 .text_v2{
        text-align: center;
        margin-top: -30px !important;
        padding-left:36px;
    }
    .top__bar__content__v2 .back_btn{
        margin-top: 43px !important;
    }
    .signup__wrapper__wr_v2 .copyright_text{
        top: 657px !important;
        text-align: center;
    }
    .signup__wrapper__wr_v2 .back__button__update__lighter{
        max-width: 450px;
        width: 100%;
        margin: 0 auto !important;
    }


    .signup__wrapper__wr_v2 .price_package_content>.row{
        flex-direction: column;
        column-gap: 20px;
    }
    .signup__wrapper__wr_v2 .price_package_content{
        min-height: 100vh !important;
        padding-bottom: 30px;
        height: unset !important;
    }
    .signup__wrapper__wr_v2 .price_package_content .col.xl7.payment_content{
        max-width: 100%;
        width: 100%;
        min-height: unset !important;
    }
    .signup__wrapper__wr_v2 .price_package_content .col.xl5{
        max-width: 100%;
        width: 100%;
        min-height: unset !important;
        margin-top: 20px;
    }
    .signup__wrapper__wr_v2 .secqure_payment__flex__wrapping{
        flex-wrap: wrap !important;
    }
    .signup__wrapper__wr_v2 #card_no_input__v2__v3 iframe{
        margin: 0px 5px !important;
        min-width: 98% !important
    } 

}


@media screen and (max-width:555px){
    .signup__wrapper__wr_v2 .back__button__update__lighter{
        margin: 55px auto 0 auto!important;
    }
}

.signup__wrapper .MuiSelect-select:focus {
    background-color: transparent !important;
}

.border-radius-10{
    border-radius: 10px;
}

.text--blue{
    color: #4285f4 !important;
}

.bill-info-outer-box{
    border: 2px solid #cdcdcd;
    border-radius: 10px;
    padding: 25px;
}

.congratulations_page .congratularion img {
    width: 200px;
    height: 200px;
}

.signup__wrapper .trial_package_addtional_charge_title h2{
    font-size: 14px;
    background: #81ecec;
    padding: 6px 10px;
    display: inline-block;
    font-weight: 500;
    margin: 0;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.signup__wrapper .mt-3.my-1,
.signup__wrapper .first_price_list{
    height: 100%;
}

.signup__wrapper .first_price_list .card-content {
    margin-top: auto;
}

.signup__wrapper .first_price_list {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:575px) {
    #regForm .copyright_text {
        /* width: 93%; */
        max-width: 675px;
    }
    #regForm .copyright_text .privecy_text span{
        margin: 0 !important;
    }

    .top__bar__content__v2 .lognin_link{
        margin-top: -16px;
    }


}

.sign-up-small{
    font-size: 65%;
}

.error-message-signup {
    font-size: 12px;
    margin: 20px 0 0 2px;
    color: #d85f5f;
}

.height-100 {
    height: 100%;
}
