.react-fine-uploader-gallery-file-input-container {
    width: 164px !important;
}

span.fa.fa-photo{
    margin-right: 5px;
}

.file-upload-stats{
    margin: 10px 0;
    padding: 5px 10px;
    border: 1px solid #eee;
    border-radius: 0.25rem;

}
.file-upload-stats .react-fine-uploader-filename{
    font-size: 12px;
    margin: 0;
    padding: 0;
    font-weight: 500;
}
.file-upload-stats .react-fine-uploader-file-progress-bar-container {
    width: 160px;
    border: 1px solid #ddd;
    background-color: #e9ecef;
    border-radius: .25rem;
    overflow: hidden;
    height: 12px;
}

.file-upload-stats .react-fine-uploader-file-progress-bar-container .react-fine-uploader-file-progress-bar {
    width: 100%;
    max-width: 160px;
    height: 12px;
    background: #6458cf;
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: 0.4rem 0.4rem;
}
.file-upload-stats button{
    margin-left: 10px;
    padding: 0 5px;
    font-size: 8px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 18px;
    height: 18px;
}