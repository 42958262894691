.inbox__madal__top__wrapper .flext__col__alt{
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 1;
}

.inbox__madal__top__wrapper .inbox__modal_title__alt{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--dark_blue);
}

.inbox__madal__top__wrapper .inbox__modal_text__field__alt{
    min-height: unset !important;
    padding: 12px 15px;
    background: #F6F7FB;
    border: 1px solid #E5E8EF !important;
    border-radius: 5px;
    color: rgba(19, 49, 89, 0.65) !important;
    font-family: Poppins;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px;
    width: 100% !important;
    border-radius: 5px !important;
    padding-left: 15px !important;
    margin: 0 !important;
}

.inbox__madal__top__wrapper .flext__col__alt__extra{
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: calc(50% - 10px);
}
.inbox__madal__top__wrapper .flext__col__alt__extra .MuiSelect-select{
    color: rgba(19, 49, 89, 0.65);
    min-height: unset !important;
    padding: 0px 15px;
    /* background: #F6F7FB; */
    border: 0px solid #E5E8EF;
    border-radius: 5px;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 24px;
    margin-top:10px
}
.inbox__madal__top__wrapper .modal__title__margin__top{
    margin-top: 20px;
}

.inbox__madal__top__wrapper .inbox__input__from__control__wr{
    width: 100%;
}
.inbox__madal__top__wrapper .inbox__input__from__control{
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: unset !important;
    width: 295px !important;
    width: 100% !important;
    height: 45px !important;
    max-width: 100% !important;
}

.inbox__madal__top__wrapper .flext__col__alt__extra .DayPickerInput input{
    min-height: unset !important;
    padding: 12px 15px;
    background: #F6F7FB;
    border: 1px solid #E5E8EF !important;
    border-radius: 5px;
    color: rgba(19, 49, 89, 0.65) !important;
    font-family: Poppins;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px;
    width: 100% !important;
    border-radius: 5px !important;
    padding-left: 15px !important;
    margin: 0 !important;
}
.inbox__madal__top__wrapper .inbox__input__from__control__wr .MuiOutlinedInput-notchedOutline {
    border: 1px solid #E5E8EF !important;
}

.inbox__madal__top__wrapper .inbox__input__from__control__wr .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0,0,0,0.0);
    border-width: 0;
}
.inbox__madal__top__wrapper .inbox__input__from__control__wr  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0,0,0,0.0);
}

.inbox__madal__top__wrapper .massage_send_close_button .send_massage span{
    width: unset;
    height: unset;
    border: unset;
    display: unset;
    border-radius: unset;
    line-height: unset;
    text-align: unset;
    margin-right: unset;
}
.inbox__madal__top__wrapper .massage_send_close_button .send_massage svg, .inbox__madal__top__wrapper .massage_send_close_button .close_btn svg{
    width: 20px;
    height: 22px;
}