
.video-landing-page-wrapper{
    position: relative;
}
.video-landing-page-wrapper:after {
    width: 100%;
    height: 100%;
    content: '';
    background: url('./assets/conversion-bg.png');
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    left: 0; 
    right: 0;
    z-index: -1;
}
.video-landing-page-container {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: #ccc 0 0 16px;
    max-width: 800px;
}
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.embed-responsive-16by9::before {
    padding-top: 56.25%;
}
.embed-responsive::before {
    display: block;
    content: "";
}
.video-player-body .embed-responsive .video--button {
    z-index: 1;
    position: absolute;
    height: 60px;
    width: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    cursor: pointer;
    background: transparent;
    border-radius: 100px;
    border: none;
    padding: 0;
    outline: 0;
    box-shadow: none;
}
.video-player-body .embed-responsive .video--button span{
    font-size: 60px;
    color: #fff;
}
.video-end-caption {
    position: absolute;
    background: rgba(0,0,0,0.85);
    z-index: 9;
    color: #fff;
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    font-family: monospace;
}

.video-start-caption {
    position: absolute;
    background: rgba(0,0,0,0.55);
    z-index: 9;
    color: #fff;
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    font-family: monospace;
}

.video-start-caption-content {
    width: 80%;
    margin: 25% auto;
    text-align: center;
}


.video-end-caption-content {
    width: 80%;
    margin: 25% auto;
    text-align: center;
}

.video-end-caption-content p {
    margin-bottom: 75px;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
}

.video-end-caption-action {
    background: none;
    color: #fff;
    border: none;
}
#video-static {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    animation: 1s ease-out 0s 1 normal none running eMLfYp;
    background-repeat: no-repeat;
}
.embed-responsive .embed-responsive-item, 
.embed-responsive embed, .embed-responsive iframe, 
.embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.video_footer textarea {
    width: 100%;
    height: 4rem;
    background-color: transparent;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    padding: 10px;
}

.video_footer textarea:focus-visible{
  /* outline:var(--green2) ; */
  outline: var(--gray_dark) auto 1px;
}
.video_footer .reply-action-container .btn {
    background:var(--gray-6);
    box-shadow: none;
    color: var(--white);
 }
 .video_footer .reply-action-container button.btn{
  background: var(--green2);
 }
 .video_footer .reply-action-container .image_upload{
     display: inline;
     vertical-align: middle;
 }
 .preview-image{
    position: relative;
    display: flex;
    width: 130px;
    margin-bottom: 20px;
 }
 .preview-image img{
     width: 100%;
     height: 100%;
 }
 .preview-image span{
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    background: var(--gray_dark);
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(69 65 78 / 20%) 0px 0px 15px 1px;
    cursor: pointer;
 }

 .video_end_caption_action_wr{
     display: flex;
     justify-content: center;
     align-items: center;
 }
.video_end_caption_action_wr .video-end-caption-action{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 3px;
}
@media only screen and (max-width: 768px) {
    .video-end-caption-content {
        margin: 5% auto 25% auto;
    }

    .video-end-caption-content p {
        margin-bottom: 1rem;
    }
}