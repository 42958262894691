.video_thumbnails.modal_thumbnail .manage_modal_card a{
    position: absolute;
    bottom: 15px;
    left: calc(50% - 80px);
}

.manage_modal_card p.template-title {
    position: absolute;
    top: 10px;
    left: 20px;
    color: white;
    background: #15151582;
}

.saved_reply_modal_wrapper .manage_modal_card p.template-title {
    width: calc(100% - 110px);
    padding: 0 5px;
    border-radius: 4px;
}
.saved_reply_modal_wrapper .video_thumbnails_scroll.awesome__scroll_bar {
    height: 100%;
    margin: 0;
}

.saved_reply_modal_wrapper .video_thumbnails {
    margin: 0;
}
.saved_reply_modal_wrapper .manage_modal_input_fields {
    padding-right: 0;
}
.saved_reply_modal_wrapper .search_input {
    margin-right: 10px;
}