.overlay{
    background-color: #00000080;
  }
.mainWrp{
  width: 600px;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.header{
  width: 100%;
  min-height: 40px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border-bottom: 1px solid #e7dada;
}
.bodyWrp{
  width: 100%;
  height: 400px;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.footerWrp{
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  box-sizing: border-box;
  gap: 20px;
}
.close{
  cursor: pointer;
  display: inline-flex;
}
.button{
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.6px;
  border-radius: 7px;
  width: 100%;
  font-size: 18px;
}
.cancelButton{
  color: #f3eeef;
  background-color: #d53d3d;
}
.submitButton{
  color: #fff;
  background-color: #006df5;
}
.bodyTitle{
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #133159;
}
.descriptionText{
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #133159;
  text-align: center;
}
.highlightWrp{
  width: 100%;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 7px;
  gap: 10px;
  margin: 20px 0px;
}
.highlightWrpWarning{
  background-color: #fdf6b2;
}
.highlightBody{
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.titleWarning{
  color: #8e4b10;
}
.submitButtonWarning{
  color: #fff;
  background-color: rgba(181, 80, 226, 0.88);
}

.suggestionItem {
  background-color: beige;
  padding: 10px;
  font-size: 12px;
  border-radius: 4px;
}

.suggestionItem button {
  float: right;
  margin-top: 11px;
}

.closeButton {
  background-color: #9670ff;
  border: 0;
  color: #fff;
  padding: 10px 15px;
  border-radius: 4px;
}

.bodyWrp:focus-visible {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.bodyWrp::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.bodyWrp::-webkit-scrollbar-track {
  background: rgba(60, 126, 243, 0.1);
  border-radius: 4px;
}
.bodyWrp::-webkit-scrollbar {
  scrollbar-width: thin;
}
.bodyWrp::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #949db2;
  box-sizing: border-box;
}
.bodyWrp::-webkit-scrollbar-track {
  background: #f4f5f7;
  box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
  -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.buttonUse {
  background-color: #38bb38;
  border: 0;
  padding: 4px 15px;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
}