.packages_content__wrapper__alt.MuiGrid-container .MuiGrid-grid-xs-4.sign_up__new_agency_package {
    flex-grow: unset !important;
    max-width: 490px !important;
    width: 100% !important;
    flex-basis: unset !important;
}

.sign_up__new_agency_package .sign_up__periods_wrapper {
    display: flex;
    justify-content: center;
    grid-gap:10px !important;
    margin-bottom: 20px;
}

.sign_up__new_agency_package .sign_up__select_period {
    display: flex;
    flex-direction: column;
    width: 140px;
    height: 85px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 3px #ccc;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
}

.sign_up__new_agency_package .sign_up__select_period.active {
    color: #0039aac9;
    background: #00c0ff1c;
    box-shadow: 0 0 0px 2px #0074a336;
}

.sign_up__new_agency_package .sign_up__select_period svg {
    position: absolute;
    right: 0;
    top: 0;
    color: #31b900;
}

.sign_up__new_agency_package .sign_up__select_period:not(.active) svg {
    display: none;  
}

.sign_up__new_agency_package .sign_up__select_period span.price-amount {
    font-size: 25px;
}
@media screen and (max-width:460px) {
    .sign_up__new_agency_package .sign_up__periods_wrapper {
        flex-wrap: wrap;
        
        
    }
    .sign_up__new_agency_package .sign_up__periods_wrapper .price-amount{
        font-size: 20px;
    }

}