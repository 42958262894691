.headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(148, 157, 178, 0.24);
}

.modalBody {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    padding: 0 14px 24px;
    width: 600px;
}

.modalFooterCenter {
    justify-content: center !important;
}

.redButton {
    border-color: #e81313 !important;
    color: #e81313 !important;
    border-width: 2px !important;
    font-weight: 700 !important;
}

.headerTitle {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #3a414a;
}

.headerTitleCenter {
    width: 100%;
    text-align: center;
}

.welcomeMessage {
    font-size: 13px;
    font-weight: 600 !important;
    padding: 0 25px;
    color: #3a414a;
    line-height: 1.2;
}

.congestedMessage {
    font-size: 13px;
    font-weight: 600 !important;
    text-align: justify;
    padding: 0 25px;
    color: #3a414a;
    line-height: 1.4;
}

.videoWrapper {
    display: flex;
    justify-content: center;
}

.mr1 {
    margin-right: 8px !important;
}

.overwriteButton {
    background-color: #9670ff !important;
    box-shadow: none !important;
    border: 1px solid #9670ff;
}

.overwriteButton:hover {
    background-color: #9670ff !important;
}

.overwriteButton:active {
    background-color: #9670ff !important;
    box-shadow: none !important;
}

.askConsent {
    font-size: 18px;
    font-weight: 600 !important;
    color: #3a414a;
    width: 465px;
    text-align: center;
    padding: 30px 20px;
}

.restrictButtonGroup {
    display: flex;
}

.redButtonFill {
    background-color: #e81313 !important;
    box-shadow: none !important;
    border: 1px solid #e81313;
}

.redButtonFill {
    background-color: #e81313 !important;
    border-color: #e81313 !important;
}

.redButtonFill:hover {
    background-color: #e81313 !important;
    border-color: #e81313 !important;
}

.redButtonFill:focus-visible {
    background-color: #e81313 !important;
    border-color: #e81313 !important;
}

.redButtonFill:focus {
    background-color: #e81313 !important;
    border-color: #e81313 !important;
}

.width140px {
    width: 140px !important;
}

.width300px {
    width: 300px !important;
}

.width240px {
    width: 240px !important;
}

.modalBodyHeader {
    padding: 10px;
}

.modalBodyTitleWrapper {
    margin-left: 10px;
}

.modalBodyTitle {
    color: #3a414a;
    font-size: 18px;
    font-weight: 600 !important;
    margin: 0 !important;
    padding: 0 !important;
}

.modalBodyStart {
    align-items: flex-start !important;
}

.priceSubtitle {
    padding: 0 !important;
    font-size: 13px !important;
    margin: 2px 0 0 !important;
    color: #3a414a !important;
}

.contentBody {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    padding: 0 15px;
}

.priceItem {
    height: 150px;
    width: 50%;
    border: 2px solid #3a414a80;
    border-radius: 5px;
    padding: 14px;
    color: #3a414a !important;
}

.prodTitle {
    font-size: 13px;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 4px !important;
    font-weight: 600;
}

.prodPrice {
    font-size: 22px;
    padding: 0 !important;
    margin: 0 !important;
    font-weight: 600;
}

.prodDesc {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.prodDesc img {
    width: 25px;
    height: 25px;
}

.overwriteButton {
    background-color: #9670ff !important;
    box-shadow: none !important;
    border: 1px solid #9670ff;
}

.overwriteButton:hover {
    background-color: #9670ff !important;
}

.overwriteButton:active {
    background-color: #9670ff !important;
    box-shadow: none !important;
}

.bottomMessage {
    font-size: 10px;
    margin: 0;
    padding: 0 14px;
    font-weight: 600;
    color: #3a414a !important;
}

.bottomMessage:not(:last-child) {
    margin-top: 10px !important;
}

.bodyContainer {
    padding: 0 14px 24px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bodyContainer::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.bodyContainer::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}

.bodyContainer::-webkit-scrollbar {
    scrollbar-width: thin;
}

.bodyContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}

.bodyContainer::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.balanceSum {
    width: 100%;
    padding: 0 15px !important;
    color: #fff;
    background-color: red;
    margin-top: 10px;
}

.balanceSum p {
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
}

.balanceOne {
    font-size: 16px;
    text-align: center;
}

.redirectLink {
    font-weight: bold;
    text-decoration: underline;
    margin-right: 16px;
    cursor: pointer;
    font-size: 17px;
}

.videoWrapperv2 {
    position: relative;
    padding-bottom: 56.25%;
    padding-right: 10px;
    height: 0;
}