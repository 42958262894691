.campaigns-single-item-main44.upload-single-item-main2.upload-single-item-main button.create_new_tag {
    background: transparent;
    border: 1px solid #ddd;
    padding: 8px;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 15px;
}

.campaigns-single-item-main44.upload-single-item-main2.upload-single-item-main button.create_new_tag:hover {
    background: lightgrey;
}

.campaigns-single-item-main44.upload-single-item-main2.upload-single-item-main .not_found_tag {
    margin-left: 15px;
    margin-top: 20px;
}

.search-bar-top-area2.search-bar-top-area .search_input_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border-radius: 5px;
}

.search-bar-top-area2.search-bar-top-area .search_input_wrapper .search-icon {
    padding: 10px;
    height: 100%;
    display: grid;
    place-items: center;
    color: #ddd;
}

.search-bar-top-area2.search-bar-top-area .search_input_wrapper input.ad__custom__input{
    margin: 0;
    padding: 0 !important;
}

/* css alter */
.imr__add_tag_modal_alt .modalBody.relative-modal-body{
    overflow: hidden !important;
}
.imr__add_tag_modal_alt .imr__atma__wrp{
    height: calc(100%) !important;
    padding-bottom: 4px !important;
}