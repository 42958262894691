.onboarding__modal__wr__v2__update{
    height: calc(100vh - 120px);
}
.onboarding__modal__wr__v2__update:focus{
    outline: 0;
}

.onboarding__modal__wr__v2__update .onboarding__modal__loading__v2{
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.onboarding__modal__wr__v2__update .onboarding__modal__loading__v2>div{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.onboarding__modal__wr__v2__update .onboarding__step__one__title{
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 22px;
    
}
.onboarding__modal__wr__v2__update .onboarding__setp__btn__group{
    /* padding: 16px 0 0 0; */
}
.onboarding__modal__wr__v2__update .onboarding__setp__btn__group button span {
    display: inline-flex;
    align-items: flex-start;
}
.onboarding__modal__wr__v2__update .onboarding__setp__btn__group button{
    padding: 7px 10px 2px 10px !important;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__sub{
    margin-top: 23px;
    font-weight: 600;
}

.onboarding__modal__wr__v2__update .onboarding__number__section__label{
    font-size: 16px;
    color: var(--dark_blue);
    margin-top: 8px;
    display: inline-block;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__select .MuiSelect-select{
    border: 1px solid var(--gray);
    padding: 10px;
    border-radius: 4px;

}

.onboarding__modal__wr__v2__update .onboarding__number__section__input__extra {
    width: 100%;
    flex: unset;
    max-width: 66%;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__input input{
    border: 1px solid var(--gray);
    padding: 10px;
    border-radius: 4px;
    height: 39px;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__input textarea{
    resize: none;
    border: 1px solid var(--gray);
    padding: 10px;
    border-radius: 4px;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__input textarea:focus{
    outline: none;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__input{
    position: relative;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__input .search_icon{
    position: absolute;
    right: 16px;
    top: 11px;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__input input:focus{
    border-bottom-color: var(--gray) !important;
    box-shadow: unset !important;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__top__back {
    margin-bottom: 16px;
}
.onboarding__modal__wr__v2__update .onboarding__number__section__top__back .onboarding__number__section__top__back__btn {
    background: var(--gray_dark);
    padding: 5px 19px;
    border-radius: 4px;
    color: var(--dark_blue);
    display: flex;
    align-items: center;
}
.onboarding__modal__wr__v2__update  .onboarding__vn__search__empty {
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.onboarding__modal__wr__v2__update .onboard__step__canspam__form__sub{
    font-weight: 600;
    margin-top: 23px;
}
.onboarding__modal__wr__v2__update .onboard__step__required__field{
    color: var(--red);
}
.onboarding__modal__wr__v2__update .onboard__step__canspam__form__msg{
    display: flex;
    align-items: center;
    grid-gap: 10px;
}
.onboarding__modal__wr__v2__update .email__provider__icon{
    width: 64px;
    height: 64px;
}
.onboarding__modal__wr__v2__update .congratulation__step__v2{
    text-align: center;
    padding-top: 64px;
}
.onboarding__modal__wr__v2__update .already__completed{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.onboarding__modal__wr__v2__update .onboarding__step__one__title_log_out{
    position: absolute;
    top: 3px;
    right: 20px;
    background-color: #303f9f;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    color: #fff;
    padding: 6.2px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.02857em;
}
@media (max-width: 1024px){
    .onboarding__modal__wr__v2__update .email__provider__icon {
        top: 20px;
        left: 50px;
        margin: 0 !important;
    }
}

@media screen and (max-width:768px) {
    .onboarding__modal__wr__v2__update .choose_another_email_btn_control{
        padding: 0 2px;
        font-size: 12px;
    }
    .onboarding__modal__wr__v2__update .email_provider_going_to_onboard_p{
        margin-top: 0;
    }
    .onboarding__modal__wr__v2__update .congratulation__step__v2 {
        text-align: center;
        padding-top: 30px;
    }
    .onboarding__modal__wr__v2__update .onboarding__number__section__input__extra{
        max-width: 100%;
    }
    .onboarding__modal__wr__v2__update .onboarding__step__one__iframe_id{
        width: 100% !important;
    }
    .onboarding__modal__wr__v2__update .onboarding__setp__btn__group__inner{
        display: flex;
        flex-direction: column;
        max-width: 140px;
        width: 100%;
        grid-gap: 9px;
    }
    .onboarding__modal__wr__v2__update .onboarding__setp__btn__group__inner .MuiButton-root{
        margin: 0 !important;
    }
    .onboarding__modal__wr__v2__update .onboarding_group__inner_img_width{
        width: 100%;
    }


}

@media screen and (max-width:601px) {
    .onboarding__modal__wr__v2__update  #onboarding__step__one__iframe_id{
        width: 100% !important;
        height: 250px !important;
    }
    .onboarding__modal__wr__v2__update .spamp_form_top_pl_padding{
        padding: 0 !important;
    }
    .on_ns_input_wr .spamp_form_pl_padding{
        float: unset !important;
    }
    .onboarding__modal__wr__v2__update .spamp_form_pl_padding{
        padding-left: 0 !important;
        margin: 0 !important;
    }
    .onboarding__modal__wr__v2__update .margin_top_zero_alt{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .onboarding__modal__wr__v2__update .can_spam_form_send_btn_wr{
        margin-bottom: 30px !important;
    }
    .on_ns_input_wr .mt-4.margin_top_zero_alt{
        margin-left: 0 !important;
    }
    .on_ns_input_wr .margin_top_zero_alt + hr{
        display: none;
    }
    .onboarding__modal__wr__v2__update .can_spam_form_send_btn_wr{
        margin-top: 11px;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width:400px) {
    .onboarding__modal__wr__v2__update .onboard_steper_label_container .MuiStepper-root{
        padding: 0 !important;
    }
    .onboarding__modal__wr__v2__update .onboard_steper_label_container .MuiStep-alternativeLabel{
        padding: 0 !important;
    }
}