.custom__field__chip__contact {
    font-size: 10px !important;
    margin-left: 8px;
    height: 17px !important;
}

.m-datatable__row .m-datatable__cell .form_uploaded__files {
    color: #039be5;
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.m-datatable__row .m-datatable__cell .form_uploaded__files svg {
    margin-right: 10px;
}

.m-datatable__row .m-datatable__cell .form_uploaded__files:hover {
    color:#044668
}