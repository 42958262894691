.leadstatus {
  display: flex;
  justify-content: center;
}
.leadOwnerBoxWidth {
  width: 45%;
}

.leadTypeBoxWidth {
  width: 45%;
}
.basicinputbackground .MuiSelect-selectMenu .selectedTitle {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1366px) {
  .leadstatus {
    display: block;
  }
  .leadTypeBoxWidth {
    width: 100%;
  }
  .leadOwnerBoxWidth {
    width: 100%;
    margin-bottom: 20px;
  }
  .leadOwnerTextWidth {
    line-height: 1.2;
    width: 100%;
  }
}

