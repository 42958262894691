.top-bar-dial-result .MuiPaper-rounded ul {
    max-height: initial;
}

.top-bar-dial-result .MuiPaper-root .options-icon-list ul.tabs li.tab {
    border: none !important;
}

.top-bar-dial-result .MuiPaper-root .options-icon-list ul.tabs li.tab:hover {
    background: transparent !important;
}

.dial-search-wrapper {
    padding-top: 13px;
}

.top-bar-dial-result .dial-search-wrapper input {
    font-size: 14px;
    line-height: 30px;
    color: #546376;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 16.6px, inset 4px 4px 2px rgb(8 20 36 / 10%);
    border-radius: 10px;
    border-bottom: unset !important;
    padding: 0px 8px 0px 32px;
    width: 240px;
}

.top-bar-dial-result .dial-search-wrapper svg {
    position: relative;
    left: 30px;
    top: 8px;
    color: #e5e8ef;
    z-index: 3;
}

.top-bar-dial-result .dial-search-wrapper input:focus {
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 16.6px, inset 4px 4px 2px rgb(8 20 36 / 10%) !important;
}

.selected__contact>.contactList {
    height: 212px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.selected__contact>.contactList>small:nth-child(1) {
    color: #b7b7b7;
}

.selected__contact .detail-box {
    border: 1px solid #d3d3d3;
    box-shadow: 0px 3px 3px 0px rgba(69, 65, 78, 0.2);
    background: #fff;
    border-radius: 7px;
    padding: 8px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 48px;
    cursor: pointer;
    margin: 0 10px;
}

.detail-box .img-circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.detail-box .user-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 8px;
}

.detail-box .user-info p {
    font-size: 14px;
    line-height: 16px;
    color: #555;
    margin: 0;
    opacity: 1;
    text-align: left;
}

.detail-box .user-info span {
    font-size: 12px;
    line-height: 14px;
    margin-top: 3px;
    color: #a4a4a4;
}

.selected__contact .contact__remover {
    margin-left: auto;
    color: #b7b7b7;
}

/**/
.dial-search-wrapper {
    margin-top: 13px;
    padding: 0 10px;
    position: relative;
}

.top-bar-dial-result .dial-search-wrapper svg {
    position: absolute;
    left: 20px;
    top: 50%;
    color: #e5e8ef;
    transform: translateY(-50%);
}


.top-bar-dial-result .dial-search-wrapper input {
    font-size: 14px;
    line-height: 30px;
    color: #546376;
    border-radius: 10px;
    border-bottom: unset !important;
    padding: 0 10px 0 35px;
    width: 100% !important;
    margin-left: 10px;
    box-shadow: unset !important;
    border: 1px solid #ddd !important;
    box-sizing: border-box;
    margin-bottom: 0;
}

/* =========Dailerpad New  css=========== */
.flex-column {
    flex-direction: column !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.option_btn button span.btn_text {
    display: block;
}

.option_btn {
    column-gap: 47px;
    row-gap: 20px;
    padding: 35px 0px;

}

.call__option_topbar {
    padding-top: 40px;
    row-gap: 13px;
}

.call_option .name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #3C7EF3;
    margin: 0;
}

.call_option .phn_no {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #133159;
}

.rcd_timer {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #546376;
}

.call_timer {
    background: #FFFFFF;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 26px;
    width: 101px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.option_btn button {
    border: unset;
    background-color: unset;
    cursor: pointer;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
}

button .btn_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.01em;
    color: #546376;
}

button .btn_icon {
    background: #E5E8EF;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

button .active {
    background: #3C7EF3;
}

.call_ended {
    background: #F6F7FB;
    border-radius: 10px;
    margin: 20px 30px 110px;
    grid-row-gap: 20px;
    row-gap: 20px;
    padding-bottom: 20px;
}

.title_timer {
    border-bottom: 1px solid #E5E8EF;
    width: 100%;
    row-gap: 5px;
    padding: 40px 0px 20px;
}

.call_ended_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.01em;
    color: #FF264A;
    margin: 0;
}

.call_time {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #546376;
}

.call_end_icon {
    background: #E5E8EF;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.call_ended button {
    border: unset;
    background-color: unset;
    cursor: pointer;
}

.call_end_btn {
    background: #E5E8EF !important;
}

.call_end_btn p {
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.01em;
    color: #3C7EF3;
    margin: 0;
}

.call_option .call-button.end-call {
    background: var(--red);
}

.call_option-blue .call-button.end-call-blue {
    background: #3C7EF3;
}

.extension_dialer {
    position: absolute;
    top: 0;
    background: white;
}

.secondDialTrigger {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.secondDialTrigger button {
    border-radius: 30px;
    min-height: 45px;
    display: inline-flex;
    align-items: center;
    box-shadow: unset !important;
}

.secondDialTrigger button.btn__dial {
    background: #f00;
    flex: .1;
}

.secondDialTrigger button.call-button {
    background: #f00;
    border: 0;
    margin: 0;
    flex: .7;
}

.secondDialTrigger button.call-transfer {
    border: 0;
    margin: 0;
    flex: .7;
}

.secondDialTrigger>button {
    width: max-content;
    color: #fff;
}

.secondDialTrigger>button:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
}

.secondDialTrigger>button:nth-child(2)>i {
    padding-right: 0px;
}

.second__call {
    position: absolute;
    background: #ffffff;
    visibility: hidden;
    top: 0;
    width: 100%;
    border-radius: 10px;
}

.second__call.active {
    visibility: visible;
}

.call__screen__control {
    position: absolute;
    top: 0;
    right: -20px;
    z-index: 99999999999999999;
    display: flex;
    flex-direction: column;
}

.call__screen__control button {
    background: #fff;
    border: none;
    padding: 5px;
    box-shadow: 0px 0px 10px 1px rgba(69, 65, 78, 0.2);
    cursor: pointer;
}

.call__screen__control button:focus {
    outline: none;
}

.call__screen__control button.active {
    box-shadow: none;
}

.first__call__active {
    margin-bottom: 5px;
}

.global__dialer .contact-info ul li {
    background: #f2f2f2;
    padding: 5px;
    border-radius: 5px;
}

.global__dialer .contact-info .person-info .contact-number {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #546376;
}

.hangup_call_histoy_wrpper__new {
    display: flex;

}

.hangup_call_histoy_wrpper__new .hangup_call_histoy_wrpper__new_end {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    border-radius: 5px;
}

.hangup_call_histoy_wrpper__new .hangup_call_histoy_wrpper__new_next {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    border-radius: 5px;
    font-size: 10px;
    color: #fff;
}

.hangup_call_histoy_wrpper__new .hangup_call_histoy_wrpper__new_next span {
    display: inline-flex;
}

.hangup_call_histoy_wrpper__new .hangup_call_histoy_wrpper__new_next span svg path {
    stroke: #fff;
}

/*===============top bar dial phn call css==================================*/

/* Shaking */
@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.shaking-call-button {
    animation: shake 0.5s infinite;
}