
.additional__info__modal__wr .aditional__info__modal_title {
    font-size: 14px;
    color: var(--dark_blue);
    padding-bottom: 4px;
}

.additional__info__modal__wr .aditional__info__modal_input {
    border: 1px solid var(--gray_dark)!important;
    color: var(--gray);
    box-shadow: none!important;
    padding-left: 15px!important;
    box-sizing: border-box!important;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
}