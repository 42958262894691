.modalWrapper {
    display: grid;
    place-content: center;
  }
  .modalInner {
    width: 700px;
    height: fit-content;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
  }
  .modalInner:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
  
  .modalInner::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .modalInner::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
  }
  .modalInner::-webkit-scrollbar {
    scrollbar-width: thin;
  }
  .modalInner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
  }
  .modalInner::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
  }
  
  .paddingWrp {
    padding: 24px;
  }
  
  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    border-bottom: 1px solid rgba(148, 157, 178, 0.24);
  }

  .headerWrapper h5 {
    font-size: 16px;
    text-align: center;
  }
  
  /* Modal header Starts here */
  
  .headerTitle {
    margin: 0;
    font-family: Poppins, Arial, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #1c1b1f;
    font-weight: 500;
  }
  
  .headerClose {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  
    display: grid;
    place-content: center;
  
    cursor: pointer;
  }
  
  .headerClose:hover {
    background-color: rgba(237, 0, 57, 0.03);
  }
  
  .headerClose svg path {
    color: red;
  }
  
  /* Modal body Starts here */
  .modalBody {
  }
  
  .required {
    color: red;
  }
  
  .formControl {
    width: 100%;
  }
  
  .formControl:not(:last-child) {
    margin-bottom: 10px;
  }
  
  .formControl .label {
    font-size: 14px;
    margin-bottom: 3px;
  }
  
  .formControl .MuiInputBase-root {
    color: black !important;
  }
  .MuiList-root li {
    color: red !important;
  }
  .textArea {
    border-radius: 4px;
    padding: 8.5px 14px;
    border-color: #ced4da;
  }
  
  /* Modal footer Starts here */
  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
  
    margin-top: 15px;
  }
  
  .save {
    background-color: green;
  }
  