.gloabl-contact-details .g-contact-detail-topbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 10px;
}
.gloabl-contact-details .tab-options{
    display: flex;
    justify-content: center;
    align-items: center;
}
.gloabl-contact-details .send-action-button-list {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 20px; */
}
.gloabl-contact-details .send-action-button-list span{
    margin:0px 5px; 
    cursor: pointer;
    display: flex;
}
.gloabl-contact-details .tab-options .tab-button{
    min-height: 36px;
    min-width: 45px;
    border: 0;
    border-radius: 4px;
    color: #fff;
    margin-right: 5px;
    background: #727272;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.gloabl-contact-details .tab-options .tab-button.tab-active{
    color: #fff;
    background: #3c7ef3;
}
.gloabl-contact-details .tab-options .tab-button svg{
    color: #fff;
}
.gloabl-contact-details .g-won-lost-action-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.gloabl-contact-details .g-won-lost-action-container .won-action,
.gloabl-contact-details .g-won-lost-action-container .lost-action
{
    color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 8px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.gloabl-contact-details .g-won-lost-action-container .won-action{
    margin-right: 10px;
    background: #22674f;
    margin-left: 10px;
}
.gloabl-contact-details .g-won-lost-action-container .won-action span
{
    margin-left: 5px;
    color: #fff;
    text-transform: capitalize;
}
.gloabl-contact-details .g-won-lost-action-container .lost-action span{
    margin-left: 5px;
    color: #000;
    text-transform: capitalize;
}
.gloabl-contact-details .g-won-lost-action-container .won-action.disable-button,
.gloabl-contact-details .g-won-lost-action-container .lost-action.disable-button
{
    cursor: not-allowed!important;
    opacity: .5;
}
.gloabl-contact-details .g-won-lost-action-container .won-action svg{
    margin-top: -5px;
}
/* basic */
.gloabl-contact-details .g-basic-info-content{
    display: flex;
}
.gloabl-contact-details .g-basic-info{
    background: #fff;
    border: 1px solid #e5e8ef;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    flex: 1 1;
}
.gloabl-contact-details .g-basic-info.g-left-side{
    margin-right: 20px;
    width: 50%;
}
.gloabl-contact-details .g-basic-info.g-right-side{
    width: 50%;
}
.gloabl-contact-details .g-basic-info-title{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #133159;
}
.gloabl-contact-details .g-basic-info-lists li{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #546376;
    word-break: break-all;
    margin-right: 5px;
    align-items: center !important;
    display: flex;
}
.gloabl-contact-details .g-basic-info-lists li > span{
    margin-right: 10px;
    border: 1px solid #546376;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.gloabl-contact-details .g-basic-info-lists li .g-each-item-icon{
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;
}
.gloabl-contact-details .g-basic-info-lists li+li {
    margin-top: 20px;
}
.gloabl-contact-details .g-basic-info-each .g-each-item-edit {
    position: relative;
}
.gloabl-contact-details .g-basic-info-each .g-each-item-edit input{
    box-sizing: border-box !important;
    max-width: 100%;
    border-radius: 6px!important;
    height: 30px!important;
    margin: 0!important;
    box-shadow: unset!important;
    border: 1px solid #ddd!important;
    padding-left: 10px!important;
    padding-right: 65px!important;
    font-size: 14px!important;
}
.gloabl-contact-details .g-basic-info-each .g-each-item-edit span{
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 4px;
}
.gloabl-contact-details .g-basic-info-each .g-each-item-edit span + span {
    right: 10px;
}
.gloabl-contact-details .loading-content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
.gloabl-contact-details .g-each-item-icon svg{
    font-size: 18px !important;
}
.gloabl-contact-details .g-basic-info-each.g-go-to-profile{
    justify-content: flex-end;
}
/* more info */
.gloabl-contact-details .g-more-info-content{
    margin-top: 10px;
    border-top: 2px solid #fff;
    display: flex;
}
.gloabl-contact-details .g-more-info-content .g-more-info-left-side{
    width: 50%;
    padding: 20px 0px;
    flex: 1 1;
}
.gloabl-contact-details .g-more-info-content .g-more-info-right-side{
    width: 50%;
    /* padding: 20px 20px 20px 0px; */
    padding: 20px 0px 20px 20px;
    flex: 1 1;
}
.gloabl-contact-details .g-contact-custom-fields{
    padding: 20px;
}
.gloabl-contact-details .g-more-info-column{
    position: relative;
}
.gloabl-contact-details .g-collapsible-header{
    background: rgba(60,126,243,.1);
    border-radius: 10px;
    padding: 7.5px 20px;
    font-family: var(--poppins);
    justify-content: space-between!important;
    align-items: center!important;
    display: flex!important;
    border-bottom: var(--border__bottom__1px);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
}
.g-collapsible-header{
    background: rgba(60,126,243,.1);
    padding: 7.5px 20px;
    font-family: var(--poppins);
    justify-content: space-between!important;
    align-items: center!important;
    display: flex!important;
    border-bottom: var(--border__bottom__1px);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.g-collapsible-header-for-deal{
    background: #181f48;
    padding: 7.5px 20px;
    font-family: var(--poppins);
    justify-content: space-between!important;
    align-items: center!important;
    display: flex!important;
    border-bottom: var(--border__bottom__1px);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.gloabl-contact-details .g-collapsible-active{
    display: block;
}
.gloabl-contact-details .g-header-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: .01em;
    color: #133159;
}
.g-header-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: .01em;
    color: #133159;
    gap: 10px;
}
.g-header-title-for-deal{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: .01em;
    color:#fff;
    gap: 10px;
}
.g-custom-caret-for-deal svg path{
    fill:#fff;
}
.g-custom-caret-for-deal{
    transform: rotate(180deg);
}
.gloabl-contact-details .g-custom-caret{
    transition: all .4s linear;
    transform: rotate(180deg);
}
.gloabl-contact-details .g-header-title+span{
    display: inline-flex;
}
.gloabl-contact-details .g-collapsible-header svg{
    display: inline-flex;
    align-items: center;
    height: 100%;
    width: 20px;
}
.gloabl-contact-details .g-header-title > svg{
    margin-right: 8px;
}
.gloabl-contact-details .g-collapsible-header.expand .g-custom-caret{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.gloabl-contact-details .g-more-info-left-side, .gloabl-contact-details .g-more-info-right-side{
    border-bottom: 2px solid #fff;
}
.gloabl-contact-details .g-empty-content{
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.gloabl-contact-details .g-collapsible-body{
    transition: all .4s ease;
    /* display: block!important; */
    border-radius: 0 0 10px 10px;
    background: #fff;
    padding: 0;
    border-bottom: 1.29px solid #e5e8ef;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.gloabl-contact-details .g-collapsible-active .g-collapsible-body{
    opacity: 1;
    visibility: visible;
    max-height: 400px;
    overflow-y: auto;
}
.gloabl-contact-details .g-custom-field-each-content{
    justify-content: space-between;
    margin: 0 0 10px;
    flex-direction: column;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    display: inline-flex!important;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 6px;
}
.gloabl-contact-details .g-custom-field-topbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.gloabl-contact-details .g-custom-field-topbar button{
    padding: 7px 10px;
    border: 0;
    background: rgba(60,126,243,.2784313725490196);
    border-radius: 5px;
    font-size: 14px;
    margin-left: auto;
    cursor: pointer;
}
.gloabl-contact-details .g-custom-field-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 8px;
}
.gloabl-contact-details .g-custom-field-wrapper span{
    font-size: 12px;
}
.gloabl-contact-details .g-custom-field-wrapper .g-custom-field-icon{
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 3;
    cursor: pointer;
}
.gloabl-contact-details .g-custom-field-wrapper .g-custom-field-icon:first-child{
    right: 30px;
}
.gloabl-contact-details .g-custom-field-edit-content{
    width: 100%!important;
}
.gloabl-contact-details .g-custom-field-edit-content .g-custom-contact-field-edit-each{
    margin: 0;
    position: relative;
}
.gloabl-contact-details .g-custom-field-edit-content .g-custom-contact-field-edit-each input{
    height: unset!important;
    padding: 6px!important;
    border: 1px solid #ddd!important;
    margin: 0;
    background: #fff!important;
    border-radius: 5px!important;
    margin-top: 10px!important;
    font-size: 16px!important;
    line-height: 22px;
    letter-spacing: .01em;
    color: #8896a8;
    position: relative;
    box-sizing: border-box!important;
    max-width: 100%;
    font-family: var(--poppins) !important;
}
.gloabl-contact-details .g-custom-field-edit-content .g-custom-field-textarea{
    padding: 6px!important;
    border: 1px solid #ddd!important;
    padding-right: 55px !important;
    height: 70px;
    border-radius: 5px;
    resize: none;
}
.gloabl-contact-details .g-custom-field-edit-content .g-custom-field-date-picker{
}
.gloabl-contact-details .g-custom-field-edit-content .g-custom-field-check-box-group{
    margin-left: unset;
}
.gloabl-contact-details .g-custom-field-edit-content .g-custom-field-radio-group{
    margin-left: unset;
}
.gloabl-contact-details .g-custom-field-edit-content .g-custom-field-check-box-group .g-custom_checkbox,
.gloabl-contact-details .g-custom-field-edit-content .g-custom-field-radio-group .g-custom_checkbox{
    display: flex;
    align-items: center;
    margin: 0 0 6px;
}
.gloabl-contact-details .g-custom-field-edit-content .g-custom-field-check-box-group .g-custom_checkbox svg,
.gloabl-contact-details .g-custom-field-edit-content .g-custom-field-radio-group .g-custom_checkbox svg{
    margin-right: 5px;
    cursor: pointer;
}
.gloabl-contact-details .g-custom-field-edit-content .g-custom-field-select{
    padding-right: 65px!important;
    border-radius: 5px;
}
.gloabl-contact-details .g-contact-tags{
    margin: 10px 10px 0;
}
.gloabl-contact-details .g-empty-content-tags{
    justify-content: center;
    align-items: center;
    display: flex!important;
    height: 70px;
    cursor: default !important;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 6px 10px;
}
.gloabl-contact-details  .g-add-tag-content{
    padding: 10px;
    width: 100%;
}
.gloabl-contact-details .g-add-more-tag-button{
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 6px 10px;
    margin: 0 10px 0 0;
}
.g-add-more-tag-button{
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 0px 10px;
    margin: 16px 10px 0 0;
    align-items: center;
}
.gloabl-contact-details .g-add-more-tag-button svg{
    font-size: 18px;
}
.gloabl-contact-details .g-add-tag-content .g-loader-content{

}
.gloabl-contact-details .g-add-tag-content .g-tag-type-search{

}
.gloabl-contact-details .g-add-tag-content .g-tag-search{
    display: inline-flex;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    margin: 5px 0 10px;
    font-size: 15px;
    box-shadow: unset!important;
}
.gloabl-contact-details .g-add-tag-content .g-all-tag-content{
    max-height: 150px;
    padding: 10px 10px 10px 0;
}
.gloabl-contact-details .g-add-tag-content .g-create-assign-text{
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 6px 10px;
    margin: 0 10px 0 0;
}
.gloabl-contact-details .g-each-tag-content{
    display: flex;
}
.gloabl-contact-details .g-selected-tag-each{
    display: inline-flex;
    cursor: pointer;
    color: rgba(82, 82, 82, 0.74);
}
.g-selected-tag-each{
    display: inline-flex;
    cursor: pointer;
    color: rgba(82, 82, 82, 0.74);
    gap:10px
}
.gloabl-contact-details .g-selected-tag-each span{
    margin-left: 5px;
}
.gloabl-contact-details .g-all-tag-content.awesome__scroll_bar{
    padding-bottom: 0px !important;
}
.gloabl-contact-details .g-assign-tag-button{
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 6px 10px;
    margin: 0px 0px 10px 10px;
}
.g-assign-tag-button{
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 20px 24px;
    color:white;
    margin: 0px 0px 10px 10px;
    background-color:black;
    gap:8px;
}
.g-assign-close-button{
    display: inline-flex;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 5px 24px;
    color:white;
    margin: 0px 0px 10px 10px;
    background-color:red;
    gap:8px;
}
.gloabl-contact-details .g-contact-tags button{
    margin: 0 5px 5px 0;
    padding: 7px 10px;
    border: 0;
    background: rgba(60,126,243,.2784313725490196);
    border-radius: 5px;
    font-size: 14px;
}
.gloabl-contact-details .g-contact-tags button span{
    cursor: pointer;
    margin: 2px;
}
.gloabl-contact-details .g-contact-campaign-list-content{
    /* padding: 20px; */
    margin: 20px;
    padding: 0;
    padding-right: 12px!important;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    max-height: 435px;
    overflow-y: auto;
}
.gloabl-contact-details .g-more-info-column .g-header-count{
    border-radius: 50%;
    background-color: #fff;
    min-width: 30px;
    text-align: center;
    justify-content: center;
    height: 30px;
    align-items: center;
    display: inline-flex;
    font-family: var(--poppins);
}
.g-header-count{
    border-radius: 50%;
    background-color: #fff;
    min-width: 25px;
    text-align: center;
    justify-content: center;
    height: 25px;
    align-items: center;
    display: inline-flex;
    font-family: var(--poppins);
    font-weight: 300;
}
.gloabl-contact-details .g-not-found-contact-campaign{
    text-align: center;
    font-size: 14px;
    display: inline-block;
}
.gloabl-contact-details .g-campaign-add-hints{
    font-style: italic;
    font-size: 14px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}
.gloabl-contact-details .g-search-campaign-content .g-search-campaign{
    border: 1px solid #ddd!important;
    border-radius: 6px!important;
    margin: 10px 0!important;
    padding: 4px 6px!important;
    font-size: 14px;
    cursor: pointer;
    display: inline-flex;
    max-width: 100%;
    outline: none;
    height: 3rem;
}
.gloabl-contact-details .g-header-title .material-icons{
    margin-right: 8px;
}
.gloabl-contact-details .g-campaign-each-container{
    background: rgba(60,126,243,.1);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
}
.gloabl-contact-details .g-campaign-each-container .g-campaign_name{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
}
.gloabl-contact-details .g-campaign-each-container .g-campaign_email{
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .01em;
    color: #546376;
}
.gloabl-contact-details .g-campaign-each-container button{
    padding: 7px 10px;
    border: 0;
    background: rgba(60,126,243,.2784313725490196);
    border-radius: 5px;
    font-size: 14px;
    margin-left: auto;
    font-family: var(--poppins)!important;
}
.gloabl-contact-details .g-eligible-campaign{
    display: inline-flex;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
}
.gloabl-contact-details .g-eligible-campaign svg{
    margin-right: 10px;
}
.gloabl-contact-details .g-assign-campaign-button-content{
    border: 1px solid #ddd!important;
    border-radius: 6px!important;
    margin: 10px 0!important;
    padding: 4px 6px!important;
    height: 35px;
    font-size: 14px;
    cursor: pointer;
    display: inline-flex!important;
    align-items: center;
}
.gloabl-contact-details .g-assign-campaign-button-content span{
    display: flex;
    align-items: center;
}
.gloabl-contact-details .g-contact-source-content{
    margin: 20px;
    padding: 0;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    max-height: 435px;
    overflow-y: auto;
}
.gloabl-contact-details .customFormControl.alt{
    margin-top: 0!important;
}
.gloabl-contact-details .g-header-action-content{
    display: flex;
    align-items: center;
    font-family: var(--poppins);
    cursor: pointer;
}
.g-header-action-content{
    display: flex;
    align-items: center;
    font-family: var(--poppins);
    cursor: pointer;
}
.gloabl-contact-details .g-header-action-content .g-add-task-button{
    height: 20px;
    margin-right: 10px;
}
.g-add-task-button{
    height: 20px;
    margin-right: 10px;
   
}
.gloabl-contact-details .g-loading-content{
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.gloabl-contact-details .g-no-appointment{
    color: #d00;
    font-size: 13px;
    margin-top: 3px;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}
.gloabl-contact-details .g-contact-appointment-list.awesome__scroll_bar{
    padding-bottom: 0px !important;
}
.gloabl-contact-details .g-contact-appointment-each{
    display: flex;
    padding: 15px;
}
.g-contact-appointment-each{
    display: flex;
    padding: 15px;
    gap: 10px;
}
.gloabl-contact-details .g-contact-appointment-each .g-appointment-check{
    display: flex !important;
    margin-right: 5px;
}

.gloabl-contact-details .g-contact-appointment-each .g-appintment-info{
    display: flex;
    flex-direction: column;
}
.g-contact-appointment-each .g-appintment-info{
    display: flex;
    flex-direction: column;
}
.gloabl-contact-details .g-contact-appointment-each .g-appintment-info .g-appintment-info-title{
    color: rgb(3, 155, 229);
    display: block;
    cursor: pointer;
}
.gloabl-contact-details .g-contact-appointment-each .g-appintment-info .g-appintment-info-date{
    display: block;
}
.gloabl-contact-details .g-contact-appointment-each .g-appintment-info .g-appintment-info-user{
    display: block;
    /* border-bottom: unset; */
    color: #039be5;
}
.gloabl-contact-details .g-done_activity_title{
    font-weight: 700;
    font-size: 14px;
    color: #133159;
}
.gloabl-contact-details .g-contact-appointment-list{
    padding: 0px 15px 15px 15px;
    margin-bottom: 15px;
}
.gloabl-contact-details .g-done_activity_title span{
    cursor: pointer;
}
.gloabl-contact-details .g-contact-deal-list-each{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    margin: 10px 0px;
    border-radius: 6px;
    border: 1px solid #ddd;
}
.gloabl-contact-details .g-contact-deal-list-each .g-deal-title{
    color: rgb(3, 155, 229);
    font-size: 15px;
}
.gloabl-contact-details .g-contact-deal-list-each .g-deal-stage{
    font-size: 11px;
    color: #9c9696;
}
.gloabl-contact-details .g-deal-value-content .g-deal-win{
    text-align: center;
}
.gloabl-contact-details .g-contact-delete-content{
    padding: 13px;
    background-color: red;
    color: white;
    cursor: pointer;
    border-radius: 6px;
    align-items: center;
    display: flex;
}
.gloabl-contact-details .g-empty-custom-field{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}
.gloabl-contact-details .g-contact-list-content.awesome__scroll_bar{
    padding-bottom: 0px;
}
.gloabl-contact-details .g-contact-list-content{
    background: #fff;
    border: 1px solid #e5e8ef;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 10px 60px 50px;
    max-height: 312px;
    overflow-y: auto;
    margin-top: 15px;
}
.gloabl-contact-details .g-contact-list-content .g-empty-note-content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}
.gloabl-contact-details .g-note-button-groups{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.gloabl-contact-details .g-note-add-content .g-note-add-button{
    display: flex;
    align-items: center;
    padding: 13px 15px;
    height: 50px;
    background: #3c7ef3;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .01em;
    color: #fff;
    cursor: pointer;
    border: 0!important;
    margin: 20px 0 20px 20px;
    font-family: Poppins;
    font-style: normal;
    width: auto!important;
}
.gloabl-contact-details .g-note-add-content .g-note-add-button svg{
    margin-right: 10px;
}
.gloabl-contact-details .g-note-add-content .g-note-cancel-button{
    display: flex;
    align-items: center;
    padding: 13px 15px;
    height: 50px;
    background: #f33c3c;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .01em;
    color: #fff;
    cursor: pointer;
    border: 0!important;
    margin: 20px 0 20px 20px;
    font-family: Poppins;
    font-style: normal;
    width: auto!important;
}
.gloabl-contact-details .g-note-add-content .g-note-cancel-button svg{
    margin-right: 10px;
}
.gloabl-contact-details .g-contact-list-content .g-contact-note-each-content{
    background: #f6f7fb;
    border-radius: 10px 10px 10px 0;
    position: relative;
    margin-bottom: 15px;
}
.gloabl-contact-details .g-contact-list-content .g-contact-note-each-content .g-note-list-header-contect{
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #133159;
    padding: 20px 20px 13px;
    border-bottom: 1px solid #e5e8ef;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.gloabl-contact-details .g-contact-list-content .g-contact-note-each-content .g-note-list-header-contect .g-note-left-icon{
    border: 1px solid #546376;
    position: absolute;
    left: -40px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    letter-spacing: .01em;
    color: #3c7ef3;
    cursor: pointer;
}
.gloabl-contact-details .g-contact-list-content .g-note-list-header-contect .g-note-header{
    align-items: center;
    display: flex;
}
.gloabl-contact-details .g-note-header .g-note-edit-icon{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: .01em;
    color: #3c7ef3;
    cursor: pointer;
    margin-left: 24px;
}
.gloabl-contact-details .g-note-message{
    padding: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #546376;
}
.gloabl-contact-details .g-note-message p{
    padding: 0!important;
}
.gloabl-contact-details .g-note-message > *{
    padding: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #546376;
}
.gloabl-contact-details .g-note-header .g-note-edit-icon svg{
    margin-right: 5px;
}
.gloabl-contact-details .g-note-header .g-note-edit-icon svg g{
    fill: #3c7ef3;
}
.gloabl-contact-details .g-note-left-icon svg g{
    fill: #546376;
}
.gloabl-contact-details .action-icon-video-email{
    border: 1px solid #6a7787;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    color: #6a7787;
    cursor: pointer;
}
.gloabl-contact-details .g-contact-delete-content svg{
    margin-right: 10px;
}
.gloabl-contact-details .action-icon-direct-email{
    border: 1px solid var(--gray);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.gloabl-contact-details .action-icon-note-tab {
    border: 1px solid var(--gray);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.gloabl-contact-details .action-icon-note-tab svg g{
    fill: #546376;
}
.gloabl-contact-details .g__contact__details__back__button{
    color: var(--dark_blue);
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 8px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
