.notification-wrapper.bottomCenter,
.notification-wrapper {
	z-index: 99999999999;
	position: fixed;
	bottom: 10px;
	left: 50%;
    transform: translateX(-50%);
	width: 450px;
} 

.notification-wrapper.topCenter { 
	top: 10px;
    left: 50%; 
    transform: translate(-50%, 0);
    bottom: unset;
}

.notification-wrapper.center { 
	top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
    bottom: unset;
}

.notification-wrapper.topRight { 
	top: 10px;
	right: 10px; 
    left: unset;
    transform: unset;
}
.notification-wrapper.bottomRight { 
	top: unset;
	right: 10px;
    bottom: 10px;
    left: unset;
    transform: unset;
}

.notification-wrapper.centerRight { 
	top: 50%;
    transform: translate(0, -50%);
	right: 10px;
    left: unset;
    bottom: unset;
}

.notification-wrapper.bottomLeft {
    top: unset;
    left: 10px;
    bottom: 10px;
    transform: unset;
}
.notification-wrapper.topLeft {
    top: 10px;
	left: 10px;
    transform: unset;
    bottom: unset;
    
}
.notification-wrapper.centerLeft {
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
    bottom: unset;
}

.notification__message_body {
	margin-left: 10px;
	margin-right: auto;
}
.notification-item {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	overflow: hidden;
	margin-bottom: 0px;
	animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;
	width: 100%;
}

@keyframes SlideLeft {
	0% {
		margin-left: 120%;
	}

	100% {
		margin-left: 0;
	}
}

@keyframes SlideRight {
	0% {
		margin-left: 0;
	}

	100% {
		margin-left: 120%;
	}
}

.notification-item.exit {
	animation: SlideRight 0.4s;
    animation-fill-mode: forwards;
}

.notification-item p {
	margin: 0;
	padding: 10px;
}

.notification-item .bar {
	height: 10px;
}

.notification-item .bar {
	background-color: #65d266;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 6px;
}

.notification-item.error .bar {
	background-color: red;
}

/* Update - 27.8.21 */
.notification-item {
	border-radius: 5px;
	background: #fff;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	font-size: 20px;
	line-height: 24px;
	font-weight: 600;
	color: #333;
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
	color: rgba(51, 51, 51, 0.726);
	padding: 12px 20px 20px 30px;
}
.notification-item::before {
	position: absolute;
	left: 15px;
	height: calc(100% - 25px);
	content: "";
	width: 5px;
	top: 10px;
	background: #000;
	border-radius: 100px;
	bottom: 10px;
}
.notification-item.info::before {
	background: #077dc3;
}
.notification-item .errorIcon {
	color: #077dc3;
	font-size: 30px;
}
.notification-item.info {
	background-color: #077dc3;
	color: #fff;
}
.notification-item.info .bar {
	background: #f8f8f8;
}

.notification-item .timesIcon {
	color: #d00;
	cursor: pointer;
}
.notification-item.info .timesIcon {
	color: #fff;
	cursor: pointer;
}

.notification-item.info .errorIcon {
	color: #fff;
}

.notification-item.info::before {
	background: #fff;
}

/*  */
.notification-item.success {
	background-color: #65d266;
	color: #fff;
}
.notification-item.success .bar {
	background: #fff;
}
.notification-item.success .timesIcon {
	color: #fff;
	cursor: pointer;
}

.notification-item.success .errorIcon {
	color: #fff;
}

.notification-item.success::before {
	background: #fff;
}

.notification-wrapper .globalIcon {
	display: none;
}

.notification-item.warning {
	background-color: #f0a933;
	color: #fff;
}
.notification-item.warning .bar {
	background: #fff;
}
.notification-item.warning .timesIcon {
	color: #fff;
	cursor: pointer;
}

.notification-item.warning .errorIcon {
	color: #fff;
}

.notification-item.warning::before {
	background: #fff;
}

/*  */
.notification-item.error {
	background-color: #d00;
	color: #fff;
}
.notification-item.error .bar {
	background: #fff;
}
.notification-item.error .timesIcon {
	color: #fff;
	cursor: pointer;
}

.notification-item.error .errorIcon {
	color: #fff;
}

.notification-item.error::before {
	background: #fff;
}


.notification-wrapper .warning .globalIcon.warningIcon {
    display: inline-block;
}
.notification-wrapper .info .globalIcon.infoIcon {
    display: inline-block;
}
.notification-wrapper .success .globalIcon.successIcon {
    display: inline-block;
}
.notification-wrapper .error .globalIcon.errorIcon {
    display: inline-block;
}