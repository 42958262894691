.global--quick-reply-card-content {
    height: 280px;
    background: #f6f7fb;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin: 5px;
    width: 270px;
    display: inline-block;
    position: relative;
}
.global--quick-reply-card-content .quick-reply-header{
    width: 100%;
    display: flex;
    padding: 10px;
    height: 55px;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
    align-items: center;
}
.global--quick-reply-card-content .quick-reply-title{
    padding-right: 20px;
    color: var(--dark_blue);
    font-size: 15px;
}
.global--quick-reply-card-content .quick-reply-type-content{
    padding: 3px 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.global--quick-reply-card-content .quick-reply-type-text{
    color: var(--dark_blue);
    font-size: 13px;
    margin-left: 5px;
}
.global--quick-reply-card-content .quick-reply-type-icon{
    color: #3C7EF3;
    align-items: center;
    display: flex;
}
.global--quick-reply-card-content .quick-reply-footer{
    border-top: 1px solid #ddd;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.global--quick-reply-card-content .quick-reply-body{
    height: calc(100% - 95px);
    padding: 5px;
}
.global--quick-reply-card-content .quick-reply-footer .quick-reply-select-button{
    color: var(--dark_blue);
    font-size: 15px;
}
.global--quick-reply-card-content .quick-reply-body .quick-reply-body-content{
    background-color: #edf6fa;
    border-radius: 6px;
    padding: 10px;
    /* text-align: justify; */
}
.global--quick-reply-card-content .quick-reply-email-subject {
    margin-bottom: 10px;
}
.global--quick-reply-card-content .quick-reply-email-subject .__subject_title{
    text-decoration: underline;
    margin-right: 5px;
    color: var(--dark_blue);
}
.global--quick-reply-card-content .__subject_text {
    color: var(--dark-blue-65);
}
.global--quick-reply-card-content .quick-reply-email-message .__message_title{
    text-decoration: underline;
    margin-right: 5px;
    color: var(--dark_blue);
}
.global--quick-reply-card-content .quick-reply-sms-message{
    text-align: justify;
    color: var(--dark_blue);
    font-size: 15px;
    word-break: break-word;;
}
.global--quick-reply-card-content .quick-reply-voice-message{
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.global--quick-reply-card-content .quick-reply-video-email{
    height: 100%;
}
.global--quick-reply-card-content .quick-reply-video-email .quick-reply-video-email-gif{
    height: 100%;
    width: 100%;
}
.global--quick-reply-card-content .quick-reply-mms{
    width: 100%;
}
.global--quick-reply-card-content .quick-reply-mms .__mms-message{
    color: var(--dark_blue);
    word-break: break-word;
}
.global--quick-reply-card-content .quick-reply-mms .__mms-img{
    width: 100%;
    margin-top: 10px;
}
.global--quick-reply-card-content .quick-reply-pdf-file{
    width: 100%;
}
.global--quick-reply-card-content .quick-reply-pdf-file .__pdf-message{
    word-break: break-word;
    color: var(--dark_blue);
}
.global--quick-reply-card-content .quick-reply-pdf-file .__pdf-file{
    width: 100%;
    margin-top: 10px;
}
.global--quick-reply-card-content .quick-reply-icon{

}
.global--quick-reply-card-content .quick-reply-email-message .__message_text p{
    word-wrap: break-word;
}