.MuiSelect-selectMenu small.subTitle {
    display: none;
}

.new__dropdown_effect{
    color: #133159 !important;
    background: white !important;
}

.new__dropdown_effect.Mui-selected{
    background-color: #d8dffd !important;
}

.new__dropdown_effect:hover{
    background-color: #cbd4ff !important;
}
.new__dropdown_effect div{
    color: #133159 !important;
}
.new__dropdown_effect .subTitle{
    color: #133159eb !important;
}
