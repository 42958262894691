 .dmpc__wr{
    min-height: calc(100vh - 133px);
    background: #fff;
    border-radius: 8px;
    padding: 20px 16px;
}
/* steper start */
.dmpc__wr .dmpc__setper_wr .MuiStepLabel-labelContainer .MuiStepLabel-label {
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    letter-spacing: 0.16px;
    color:  #546376;
}
.dmpc__wr .dmpc__setper_wr .MuiStepper-root{
    width: 80%;
    padding: 24px !important;
}
.dmpc__wr .dmpc__setper_wr .MuiStepper-root .MuiStepIcon-active,
.dmpc__wr .dmpc__setper_wr .MuiStep-completed .MuiStepIcon-completed{
    color: #3C7EF3 !important;
}
.dmpc__wr .dmpc__setper_wr .MuiStepper-root .MuiStepLabel-active,
.dmpc__wr .dmpc__setper_wr .MuiStep-completed .MuiStepLabel-completed{
    color: #3C7EF3 !important;
}
.dmpc__wr .dmpc__setper_wr .MuiStepper-root .MuiStep-root .MuiSvgIcon-root{
    color: rgba(84, 99, 118, 0.6);
}
.dmpc__wr .dmpc__setper_wr .MuiStepLabel-root {
    cursor: pointer;
}
.dmpc__wr .dmpc_btn_circle_loader>div{
    display: inline-flex;
}
.dmpc__wr .dmpc_btn_circle_loader svg{
    width: 18px;
    height: 18px;
}
.dmpc__wr .dmpc_btn_circle_loader svg path {
    fill: #fff;
}



/* steper end */


.dmpc__wr .dmpc__steper__content .dmpc_1st_middle{
    /* margin: 70px auto 0 auto; */
    margin: 30px auto 0 auto;
    width: 100%;
    text-align: center;
    
}

.dmpc__wr .dmpc__steper__content .dmpc_1st_middle__subtitle{
    font-size: 16px;
    line-height: 24px;
    color: var(--gray);
    letter-spacing: 0.15px;
    font-weight: 400;
}
.dmpc__wr .dmpc__steper__content .dmpc_1st_middle__title{
    font-size: 34px;
    font-weight: 500;
    line-height: 41px;
    color: var(--gray);
    letter-spacing: 0.8px;
    margin: 0 0 4px 0;
}
.dmpc__wr .dmpc__steper__content .dmpc_1st__middle_postCard{
    display: flex;
    grid-gap: 48px;
    justify-content: center;
    margin-top: 32px;
}
.dmpc__wr .dmpc__steper__content .dmpc_1st__mp__single{
    border: 1px solid #E5E8EF;
    border-radius: 4px;
    padding: 32px;
    text-align: center;
    cursor: pointer;
}
.dmpc__wr .dmpc__steper__content .dmpc_1st__mp__single .dmpc_1st__middle__icon{
    margin-bottom: 24px;
}

.dmpc__wr .dmpc__steper__content .dmpc_1st__mcard{
    font-size: 16px;
    font-weight: 500;
    line-height: 21.34px;
    color: var(--gray);
    letter-spacing: 0.8px;
    margin: 0 0 4px 0;
}
.dmpc__wr .dmpc__steper__content .dmpc_1st__mprice{
    font-size: 14px;
    font-weight: 400;
    line-height: 18.66px;
    color: var(--gray);
}
.dmpc__wr .dmpc__steper__content .dmpc_1st__mp__active{
    border-color: #3C7EF3;
}
.dmpc__wr .dmpc__steper__content .dmpc_1st__mp__active .dmpc_1st__mcard{
    color: #3C7EF3;
}

/* select customization  */
.dmpc_templete_uis_select_wr .dmpc_templete_uis_select_item{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.16px;
    color: var(--gray);
    padding: 10px 20px;
    position: relative;
}
.dmpc_templete_uis_select_wr .dmpc_templete_uis_select_item:after{
    position: absolute;
    content:'';
    height: 100%;
    width: 2px;
    background-color: #3C7EF3;
    left: 0;
    top:0;
    opacity: 0;

}
.dmpc_templete_uis_select_wr .dmpc_templete_uis_select_item:hover{
    background-color: rgba(60, 126, 243, 0.1) !important;
}
.dmpc_templete_uis_select_wr .dmpc_templete_uis_select_item:hover::after{
    opacity: 1;
}
.dmpc_templete_uis_select_wr .dmpc_templete_uis_select_item_add{
    display: flex;
    align-items: center;
    color: #3C7EF3;
    grid-gap: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.16px;
    padding: 10px 20px;
}
.dmpc_templete_uis_select_wr .dmpc_templete_uis_select_item_add span{
    display: inline-flex;
}


/* global select category */
.dmpc__wr .select_category_empty{
    display: flex;
    justify-content: center;
    height: calc(100vh - 376px);
    align-items: center;
    width: 100%;
    color: var(--gray);
    font-size: 14px;

}
.dmpc__wr .gallery_prview_select_empty{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--gray_dark);
    color: var(--gray);
    font-size: 14px;

}


/* ========== updated at 18-07-2022 ===============*/


.dmpc__wr .gifc_1st_middle .gift_1st_middle_top{
    text-align: center;
}
.dmpc__wr .gifc_1st_middle .gift_1st_mt_title{
    font-size: 34px;
    font-weight: 500;
    line-height: 41px;
    color: #546376;
    letter-spacing: 0.25px;
    margin: 0 0 4px 0;
}
.dmpc__wr .gifc_1st_middle .gift_1st_mt_subtitle{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #546376;
    letter-spacing: 0.15px;
    margin: 0 0 20px 0;
}
.dmpc__wr .gifc_1st_middle .gift_1st_middle_gallery{
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(203px, 1fr) );
    grid-gap: 16px;
    height: calc(100vh - 455px);
    padding-bottom: 16px !important;
    padding-right: 16px;
}
.dmpc__wr .gifc_1st_middle .gift_1st_middle_gallery1{
    height: calc(100vh - 455px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.dmpc__wr .gifc_1st_middle .gift_1st_mg_single {
    border: 2px solid #E5E8EF;
    border-radius: 4px ;
    padding: 6px;
    text-align: center;
    cursor: pointer;
}
.dmpc__wr .gifc_1st_middle .gift_1st_mg_single_active{
    border-color: #3C7EF3;
}
.dmpc__wr .gifc_1st_middle .gift_1st_mg_single .gift_1st_mg_img{
    height: 170px;
    max-height: 170px;
    width: 100%;
}
.dmpc__wr .gifc_1st_middle .gift_1st_mg_single .gift_1st_mg_title{
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #546376;
    margin: 0 0 4px 0;
}
.dmpc__wr .gifc_1st_middle .gift_1st_mg_single_active .gift_1st_mg_title{
    color: #3C7EF3;
}
.dmpc__wr .gifc_1st_middle .gift_1st_mg_single .gift_1st_mg_sub{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #546376;
    margin: 0;
}
/* ===================First Step Gift Card End===============================*/


/* access denied */
.dmpc__setper_wr .dmpc__steper__content.dmpc__process__block{
    background: #98a1ad59;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    display: flex;
    font-size: 20px;
    font-weight: 700;
}

/* header css start */
.dmch__wr{
    border-bottom: 2px solid var(--light-blue-10);
    display: flex;
    justify-content: space-between;
}
.dmch__wr .dmch__ls__title{
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.16px;
    color: var(--dark_blue);
    margin: 0;
    
}
.dmch__wr .dmch__ls{
    display: flex;
    align-items: center;
    grid-gap: 8px;
    padding-bottom: 10px;
}
.dmch__wr .dmch__ls__price{
    background: rgba(60, 126, 243, 1);
    color: #fff;
    border-radius: 30px;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.16px;
    font-weight: 400;
    padding: 1px 6px;

}
.dmch__wr .dmch__rs svg {
    cursor: pointer;
}

/* ===================Second Step Start===============================*/


/* canva css start */
.dmpc__wr .dmpc_2nd_middle .dmpc_templste_ud_canva{
    border: 2px dashed;
    border-radius: 5px;
    border-color: #3C7EF3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    background-color: #fff;
    flex-direction: column !important;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templste_ud_canva .dmpc_templste_ud_canva_btn{
    border: 0px solid #3C7EF3;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #fff;
    display: flex;
    border-radius: 4px;
    grid-gap: 10px;
    background-image: linear-gradient(to right, #02CAEB , #7A65E6);
    align-items: center;
    padding: 12px 15px;
    cursor: pointer;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templste_ud_canva .dmpc_templste_ud_canva_btn span{
    display: inline-flex;
}
.dmpc_templste_ud_canva .dmpc_templste_ud_canva_img{
    width: 150px;
    height: 120px;
}

/* canva modal css start*/
.dmpc_canva_modal_body .dmpc_canva_modal_body_top{
    margin-top: 22px;
}
.dmpc_canva_modal_body .dmpc_canva_modal_body_title{
    font-size: 16px;
    line-height: 19.76px;
    font-weight: 600;
    letter-spacing: 0.25px;
    color: var(--dark_blue);
    margin:0 0 4px 0 !important;
}
.dmpc_canva_modal_body .dmpc_canva_modal_body_content{
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: var(--gray);
    margin:0  !important;
}
.dmpc_canva_modal_body .dmpc_canva_modal_body_content span{
    font-weight: 700;
    color: #3C7EF3;
}
.dmpc_canva_modal_body .dmpc_canva_modal_body_important{
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: var(--red);
    letter-spacing: 0.25px;
    margin:16px 0 4px 0 !important;
}
.dmpc_canva_modal_body .dmpc_canva_modal_body_note{
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: var(--gray);
    margin:0  !important;
}
.dmpc_canva_modal_body .dmpc_canva_modal_gallery{
    display: flex;
    justify-content: center;
    grid-gap: 50px;
    margin-top: 89px;
}
.dmpc_canva_modal_body .dmpc_canva_modal_gallery .dmpc_canva_modal_g_sigle{
    max-width: 336px;
    flex-basis: 336px;
    width: 100%;
    position: relative;
}
.dmpc_canva_modal_body .dmpc_canva_modal_g_sigle .dmpc_canva_modal_g_sigle_span{
    position: absolute;
    right: -26px;
    top: -39px;
}
.dmpc_canva_modal_gallery .dmpc_canva_modal_g_sigle img{
    width: 100%;
}
.dmpc_canva_modal_body .dmpc_canva_modal_g_ok{
    text-align: center;
    margin-top: 62px;
}
.dmpc_canva_modal_body .dmpc_canva_modal_g_ok__btn{
    background: #3C7EF3;
    display: inline-flex;
    grid-gap: 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    padding: 14px 16px;
}
.dmpc_canva_modal_body .dmpc_canva_modal_g_ok__btn:hover{
    background: #315faf;
}
.dmpc_canva_modal_body .dmpc_canva_modal_g_ok__btn span{
    display: inline-flex;
}

.dmpc__wr .dmpc_2nd_middle .dmpc_2nd_middle_top{
    display: flex;
    justify-content: space-between;
    grid-gap: 12px;
    align-items: center;
    padding: 8px 24px 0 24px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_2mt_left_title{
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: var(--dark_blue);
    margin: 0 0 4px 0;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_2mt_left_subtitle{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: #546376;
    margin: 0;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_2mt_right{
    display: flex;
    align-items: center;
    grid-gap: 10px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_2mt_right__upbtn{
    border: 1px solid #3C7EF3;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #3C7EF3;
    display: flex;
    border-radius: 4px;
    grid-gap: 10px;
    background-color: #fff;
    align-items: center;
    padding: 11px 15px;
    cursor: pointer;

}
.dmpc__wr .dmpc_2nd_middle .dmpc_2mt_right__canbtn{
    border: 0px solid #3C7EF3;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #fff;
    display: flex;
    border-radius: 4px;
    grid-gap: 10px;
    background-image: linear-gradient(to right, #02CAEB , #7A65E6);
    align-items: center;
    padding: 12px 15px;
    cursor: pointer;

}
.dmpc__wr .dmpc_2nd_middle .dmpc_2mt_right__canbtn span,
.dmpc__wr .dmpc_2nd_middle .dmpc_2mt_right__upbtn span{
    display: inline-flex;
}


.dmpc__wr .dmpc_2nd_middle .dmpc_templete_view{
    display: flex;
    margin-top: 16px;
    background: #F4F5F7;
    border-radius: 8px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_view .dmpc_tv_left{
    flex-basis: 35%;
    width: 100%;
    max-width: 35%;
    padding: 2px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_view .dmpc_tv_right{
    flex-basis: 65%;
    width: 100%;
    max-width: 65%;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_top{
    display: flex;
    align-items: center;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_top .dmpc_tv_lt_btn{
    border: 1px solid #E5E8EF;
    background-color: #E5E8EF;
    color: var(--gray);
    padding: 12px 8px;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-align: center;
    cursor: pointer;

}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_top .dmpc_tv_lt_btn:first-child{
    border-top-left-radius: 8px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_top .dmpc_tv_lt_btn:last-child{
    border-top-right-radius: 8px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_top .dmpc_tv_lt_btn_active{
    border-color: #6258FF;
    background: #6258FF;
    color: #fff;
}

.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_innter{
    background: #fff;
    padding: 4px;
    height: calc(100% - 50px);
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_innter .dmpc_tv_left_select{
    margin: 12px 0 8px 0;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_select .dmpc_tv_left_select_formControll{
    width: 100%;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_select .dmpc_tv_left_sf{
    border: 1px solid #E5E8EF;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray);
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_select .dmpc_tv_left_sf .MuiSelect-select{
    padding: 6px 12px;
}

.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_gallery{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 4px;
    height: calc(100vh - 200px);
    padding-right: 5px;
    padding-bottom: 10px !important;
    align-content: flex-start;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_gallery_single{
    height: 170px;
    cursor: pointer;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_gallery_single_active{
    border: 1px dashed #133159;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_left_gallery img{
    width: 100%;
    height: 100%;
}


.dmpc__wr .dmpc_2nd_middle .dmpc_tv_right{
    padding: 24px 20px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_right .dmpc_tv_right_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.dmpc__wr .dmpc_2nd_middle .dmpc_tv_right_inner_img {
    max-width: 705px;
    width: 100%;
    height: 479.6px;
    position: relative;
    box-shadow: 1px 1px 5px #aaa;
    margin: 0 auto;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% !important;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_right_inner_img .dmpc_tv_right_inner_img__top__border{
    border: 1px dashed #f24124;
    bottom: 0;
    left: 0;
    margin: 2%;
    position: absolute;
    right: 0;
    top: 0;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_tv_right_inner_img .dmpc_tv_right_inner_img__bottom__border{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px dashed #4285f4;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    left: 0;
    margin: 1%;
    position: absolute;
    right: 0;
    top: 0;
}
    

.dmpc__wr .dmpc_2nd_middle .dmpc_tv_right_inner_img img{
    width: 100%;
    height: auto;
    max-height: 100%;
}




.dmpc__wr .dmpc_2nd_middle .dpmp_tv_footer_line_wr{
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    padding-top: 16px;
    max-width: 705px;
    width: 100%;
    margin: 0 auto;
}
.dmpc__wr .dmpc_2nd_middle .dpmp_tv_footer_line_wr .dpmp_tv_footer_line_left{
    display: flex;
    align-items: center;
    grid-gap: 6px;
    color: #504e4e;
    font-size: 10px;
    font-weight: 400;
}
.dmpc__wr .dmpc_2nd_middle .dpmp_tv_footer_line_right_border{
    border-bottom: 1px dashed #4285f4;
    width: 20px;
    height: 0;
}
.dmpc__wr .dmpc_2nd_middle .dpmp_tv_footer_line_left_border{
    border-bottom: 1px dashed var(--red);
    width: 20px;
    height: 0;
}

/* second step upload css start */
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload{
    background-color: #F4F5F7;
    padding: 24px;
    border-radius: 8px;
    margin-top: 16px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload_top{
    display: flex;
    align-items: center;
    grid-gap: 8px;
}

.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload_top .dmpc_templete_up_title{
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    color: var(--dark_blue);
    margin: 0;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_ui_single_cat{
    margin-top: -8px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_ui_single{
    width: 30%;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_upload_input{
    display: flex;
    align-items: center;
    grid-gap: 16px;
    padding: 24px 0 0 0;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_upload_drag{
    padding-top: 24px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_upload_input .dmpc_templete_uis_formControll{
    width: 100%;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_ui_single_label{
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: var(--dark_blue);
    letter-spacing: 0.16px;
    margin: 0 0 4px 0;
    display: block;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_upload_input .dmpc_templete_ui_single_input{
    border: 1px solid #E5E8EF;
    background: #fff;
    border-radius: 4px;
    height: 40px;
    font-size: 13px;
    line-height: 24px;
    padding: 8px 12px;
    color: #546376;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_ui_single_input:focus{
    box-shadow: 0 0 0 rgba(0,0,0,0.0) !important;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_ui_single_input::placeholder{
    color: var(--gray);
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_uis_formControll .dmpc_templete_uis_sf{
    border: 1px solid #E5E8EF;
    background: #fff;
    border-radius: 4px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_uis_formControll .dmpc_templete_uis_sf input:not([type]){
    margin: 0;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_uis_formControll .MuiSelect-select.MuiSelect-select{
    padding: 8px 40px 7px 5px !important;
    font-size: 13px;
    line-height: 24px;
    color: var(--gray);
    display: flex;
    align-items: center;
    grid-gap: 4px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_uis_formControll .MuiSelect-select.MuiSelect-select span{
    display: inline-flex;
}

/* drag and drop */
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload_drag .dmpc_templete_ud_title{
    color: #133159;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.16px;
    margin: 0 0 10px 0;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload_drag .MuiDropzoneArea-root{
    border:2px dashed;
    border-color: #3C7EF3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.dmpc__wr .dmpc_2nd_middle .MuiDropzoneArea-root .MuiDropzoneArea-text{
    font-size: 14px;
    color: #133159;
    font-weight: 600;
    margin: 0;
}
.dmpc__wr .dmpc_2nd_middle .MuiDropzoneArea-root .MuiDropzoneArea-icon {
    color: #3C7EF3;
    width: 40px;
    height: 32px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload_drag .dmpc_templete_ud_file_limit{
    font-size: 11px;
    color: var(--red);
    line-height: 16.5px;
    font-weight: 500;
    margin-top: 6px;
}

/* for notecard second step right side design start */
.dmpc_notecard_preview_right{
    flex-basis: 65%;
    width: 100%;
    max-width: 65%;
    padding: 24px 20px;
}
.dmpc_notecard_preview_right .dmpc_npr_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.dmpc_notecard_preview_right .dmpc_npr_inner .dmpc_npr_i__image__preview{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% !important;
    cursor: pointer;
    max-width: 705px;
    width: 100%;
    height: 516.65px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border: 1px solid #979797;
    -webkit-box-shadow: 1px 1px 5px #aaa;
    box-shadow: 1px 1px 5px #aaa;
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    /* padding-top: 73%; */
    position: relative;
    overflow: hidden;
}
.dmpc_npr_i__image__preview .dmpc_npr_i_ip_cut_zone{
    border: 3px dashed #f24124;
    bottom: 0;
    left: 0;
    margin: 4%;
    position: absolute;
    right: 0;
    top: -48px;
}
.dmpc_npr_i_ip_cut_zone .dmpc_npr_i_ip_safety_zone{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 3px dashed #4285f4;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    left: 0;
    margin: 2%;
    position: absolute;
    right: 0;
    top: -34px;
}
.dmpc__type_message__image_preview_container{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #979797;
    -webkit-box-shadow: 1px 1px 5px #aaa;
    box-shadow: 1px 1px 5px #aaa;
    margin: 0 auto;
    padding-top: 145.83333333%;
    background-color: #fff;
    width: 705px;
    height: 1030.11px;
}
.dmpc__type_message__image_preview_container .dmpc_tmipc_image_preview{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% !important;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.dmpc_tmipc_image_preview .dmpc_tmipc_ip_cut_zone{
    border: 1px dashed #f24124;
    bottom: 0;
    left: 0;
    margin: 4%;
    position: absolute;
    right: 0;
    top: 0;
}
.dmpc_tmipc_image_preview .dmpc_tmipc_ip_folding_zone{
    position: relative;
    margin: 73% 4% 0;
    border-top: 1px dashed #4285f4;
}
/* for notecard second step right side design end */

/* greeting card image preview start */
.dmpc_greeting_preview_right{
    flex-basis: 65%;
    width: 100%;
    max-width: 65%;
    padding: 24px 20px;
}
.dmpc_greeting_preview_right .dmpc_gpr_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.dmpc_greeting_preview_right .dmpc_gpr_inner .dmpc_gpr_landscape_i__image__preview{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% !important;
    cursor: pointer;
    width: 522px;
    height: 378px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border: 1px solid #979797;
    -webkit-box-shadow: 1px 1px 5px #aaa;
    box-shadow: 1px 1px 5px #aaa;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    /* padding-top: 73%; */
    position: relative;
    overflow: hidden;
}
.dmpc__wr .dmpc_gpr_select_landscape_portrait_wr{
    height: 479.6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--gray_dark);
    color: var(--gray);
    font-size: 14px;
}

.dmpc_greeting_preview_right .dmpc_gpr_inner .dmpc_gpr_portrait_i__image__preview{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% !important;
    cursor: pointer;
    height: 522px;
    width: 378px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border: 1px solid #979797;
    -webkit-box-shadow: 1px 1px 5px #aaa;
    box-shadow: 1px 1px 5px #aaa;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    padding-top: 73%;
    position: relative;
    overflow: hidden;
}

.dmpc_tm_zd_gg_process_container{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    border: 1px solid #979797;
    -webkit-box-shadow: 1px 1px 5px #aaa;
    box-shadow: 1px 1px 5px #aaa;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 30px;
}
.dmpc_tm_zd_gg_process_container.zd_gg_portrait{
    width: 378px;
    height: 522px;
}
.dmpc_tm_zd_gg_process_container.zd_gg_landscape{
    height: 378px;
    width: 522px;
}
.dmpc_tm_zd_gg_process_container .dmpc_tm_zd_gg_pc_fp_image{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    /* background-position: 50% !important; */
}
.dmpc_tm_zd_gg_process_container .dmpc_tm_zd_gg_pc_sp_container{
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dmpc_tm_zd_gg_process_container .dmpc_tm_zd_gg_pc_sp_container span{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    font-size: 30px;
    white-space: pre-line;
    word-break: break-all;
    border: 1px dashed red;
}
.dmpc_tm_zd_gg_page__change__stepper{
    position: absolute;
    right: 0;
    grid-gap: 10px;
    display: flex;
    font-size: 14px;
    align-items: center;
    top: -10px;
}
.dmpc_tm_zd_gg_page__change__stepper span:nth-child(1),
.dmpc_tm_zd_gg_page__change__stepper span:nth-child(3){
    cursor: pointer;
    font-size: 18px;
}
.dmpc_tm_zd_gg_process_c{
    position: relative;
}

/* later card css */
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_view .dmpc_later_tv_right{
    flex-basis: 65%;
    width: 100%;
    max-width: 65%;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_later_tv_right{
    padding: 24px 20px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_later_tv_right .dmpc_later_tv_right_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.dmpc__wr .dmpc_2nd_middle .dmpc_later_tv_right_inner_img {
    max-width: 705px;
    width: 100%;
    height: 910.89px;
    position: relative;
    box-shadow: 1px 1px 5px #aaa;
    margin: 0 auto;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% !important;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_later_tv_right_inner_img .dmpc_later_tv_right_inner_img_top_borer{
    border: 1px dashed var(--red);
    bottom: 0;
    left: 0;
    margin: 1%;
    position: absolute;
    right: 0;
    top: 0;
}





.dmpc__wr .dmpc_2nd_middle .dpmp_later_tv_footer_line_wr{
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    padding-top: 16px;
}
.dmpc__wr .dmpc_2nd_middle .dpmp_later_tv_footer_line_wr .dpmp_later_tv_footer_line_left{
    display: flex;
    align-items: center;
    grid-gap: 6px;
    color: #504e4e;
    font-size: 10px;
    font-weight: 400;
}
.dmpc__wr .dmpc_2nd_middle .dpmp_later_tv_footer_line_right_border{
    border-bottom: 1px dashed var(--dark_blue);
    width: 20px;
    height: 0;
}
.dmpc__wr .dmpc_2nd_middle .dpmp_later_tv_footer_line_left_border{
    border-bottom: 1px dashed var(--red);
    width: 20px;
    height: 0;
}

.dmpc_later_tv_right_inner_img .dmpc__letter__select__address_block {
    background-color: #ff000066;
    border: 1px solid red;
    width: 37%;
    top: 7.5%;
    position: absolute;
    height: 18.5%;
    left: 7%;
    border-radius: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 120%;
}


/* for notecard */
.dmpc__wr .dmpc_2nd_middle .dmpc_templete_view .dmpc_note__card_tv_right{
    flex-basis: 65%;
    width: 100%;
    max-width: 65%;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_note__card_tv_right{
    padding: 24px 20px;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_note__card_tv_right .dmpc_note__card_tv_right_inner{
    padding-left: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.dmpc__wr .dmpc_2nd_middle .dmpc_note__card_tv_right_inner_img {
    height: 516.65px;
    width: 705px;
    position: relative;
    box-shadow: 1px 1px 5px #aaa;
}

.dmpc__wr .dmpc_2nd_middle .dmpc_note__card_tv_right_inner_img:before{
    content: "";
    width: 98%;
    height: 98%;
    top: 2%;
    left: 2%;
    border: 1px dashed var(--red);
    position: absolute;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_note__card_tv_right_inner_img::after{
    content: "";
    width: 98%;
    height: 98%;
    top: 1%;
    left: 1%;
    border: 1px dashed blue;
    position: absolute;
}
.dmpc__wr .dmpc_2nd_middle .dmpc_note__card_tv_right_inner_img img{
    width: 100%;
    height: auto;
    max-height: 100%;   
}

.dmpc__wr .dmpc_2nd_middle .dpmp_note__card_tv_footer_line_wr{
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    padding-top: 16px;
}
.dmpc__wr .dmpc_2nd_middle .dpmp_note__card_tv_footer_line_wr .dpmp_note__card_tv_footer_line_left{
    display: flex;
    align-items: center;
    grid-gap: 6px;
    color: #504e4e;
    font-size: 10px;
    font-weight: 400;
}
.dmpc__wr .dmpc_2nd_middle .dpmp_note__card_tv_footer_line_right_border{
    border-bottom: 1px dashed var(--dark_blue);
    width: 20px;
    height: 0;
}
.dmpc__wr .dmpc_2nd_middle .dpmp_note__card_tv_footer_line_left_border{
    border-bottom: 1px dashed var(--red);
    width: 20px;
    height: 0;
}





/* greeting card image preview end */





/* =====================Second Step End============================== */


/* =====================Third Step Start============================== */
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_middle_top{
    display: flex;
    justify-content: space-between;
    grid-gap: 12px;
    align-items: center;
    padding: 0 24px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3mt_left_title{
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: var(--dark_blue);
    margin: 0 0 4px 0;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3mt_left_subtitle{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: #546376;
    margin: 0;
}




.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_templete_view{
    display: flex;
    margin-top: 16px;
    background: #F4F5F7;
    border-radius: 8px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_templete_view .dmpc_3rd_tv_left{
    flex-basis: 35%;
    width: 100%;
    max-width: 35%;
    padding: 2px;
    position: relative;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_templete_view .dmpc_3rd_tv_left .dmpc_expand_scroll_icon{
    position: absolute;
    right: -10px;
    top: 50%;
    background: #fff;
    height: 65px;
    width: 12px;
    display: flex;
    align-items: center;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: col-resize;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_templete_view .dmpc_3rd_tv_right{
    flex-basis: 65%;
    max-width: 65%;
    position: relative;
    
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_right .dmpc_3rd_tv_right_text_loader{
    position: absolute;
    right:112px;
    top: 64px;
    z-index: 9;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_right .dmpc_3rd_tv_right_text_loader svg{
    width: 35px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_right .dmpc_3rd_tv_right_inner_img{
    max-width: 705px;
    width:100%;
    background-size: cover !important;
    height: 481.4px;
    box-shadow: 1px 1px 5px #aaa;
    margin: 0 auto;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% !important;
    cursor: pointer;
    align-items: center;
    position: relative;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_right .dmpc_3rd_tv_right_inner_red_border{
    border: 1px dashed #f24124;
    bottom: 0;
    left: 0;
    margin: 2%;
    position: absolute;
    right: 0;
    top: 0;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_right .dmpc_3rd_tv_right_inner_border{
    align-items: center;
    border: 1px dashed #4285f4;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    left: 0;
    margin: 1%;
    position: absolute;
    right: 0;
    top: 0;
}

.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_no_image_found{
    /* background: #fff; */
    height: 100%;
    padding: 141px 23px 50px 240px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_no_image_found .dmpc_3rd_no_image_found_inner {
    display: flex;
    justify-content: space-between;
    max-width: 250px;
    margin: 0 auto 0 auto;
    grid-gap: 130px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_no_image_found .dmpc_3rd_no_image_found_inner .dmpc_3rd_postage{
    border: 1px solid var(--gray);
    padding: 8px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--gray);
    max-width: 100px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_no_image_found .dmpc_3rd_no_image_found_inner .dmpc_3rd_postage_title{
    font-size: 12px;
    font-weight: 300;
    color: var(--gray);
    flex-shrink: 0;
    max-width: 192px;
  
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_no_image_found .dmpc_3rd_postage_uppercase{
    font-size: 22px;
    line-height: 28px;
    padding: 72px 0px 0 0px;
    margin: 0 auto;
    max-width: 250px;
    color: #404040;
    font-weight: 300;

}

.dmpc__wr .dmpc_3rd_middle .dpmp_tv_footer_line_wr{
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    padding-top: 16px;
}
.dmpc__wr .dmpc_3rd_middle .dpmp_tv_footer_line_wr .dpmp_tv_footer_line_left{
    display: flex;
    align-items: center;
    grid-gap: 6px;
    color: #504e4e;
    font-size: 10px;
    font-weight: 400;
}
.dmpc__wr .dmpc_3rd_middle .dpmp_tv_footer_line_right_border{
    border-bottom: 1px dashed #4285f4;
    width: 20px;
    height: 0;
}
.dmpc__wr .dmpc_3rd_middle .dpmp_tv_footer_line_left_border{
    border-bottom: 1px dashed var(--red);
    width: 20px;
    height: 0;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_top{
    display: flex;
    align-items: center;
}

.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_innter{
    background: #fff;
    padding: 4px;
    height: 100%;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_textarea_wr{
    position: relative;
    border: 1px solid #E5E8EF;
    border-radius: 4px;
    padding-bottom: 30px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_innter .pdm_type_msg_generate_file {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    border: 1px solid #3C7EF3;
    background-color: #3C7EF3;
    border-radius: 4px;
    align-items: center;
    grid-gap: 10px;
    padding: 12px 16px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-top: 8px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_innter .dmpc_3rd_tv_left_select{
    margin: 12px 0 8px 0;
    display: flex;
    grid-gap: 8px;
    align-items: center;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_select .dmpc_3rd_tv_left_select_formControll{
    width: 100%;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_select .dmpc_3rd_tv_left_sf{
    border: 1px solid #E5E8EF;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray);
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_select .dmpc_3rd_tv_left_sf .MuiSelect-select{
    padding: 6px 12px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_select .dmpc_3rd_tv_left_sf .MuiSelect-select:focus{
    background-color: transparent !important;
}

.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tag_formControll .dmpc_3rd_tag_s{
    /* border: 1px solid #E5E8EF; */
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray);
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tag_s .MuiSelect-select{
    padding: 3px 25px 3px 4px;
    box-shadow: 0 0 1px;
    border-radius: 2px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tag_s .MuiSelect-select:focus{
    background-color: transparent !important;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_innter .dmpc_3rd_tv_left_textarea{
    /* border: 1px solid #E5E8EF;
    border-radius: 4px; */
    resize: none;
    height: 300px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--gray);
    padding: 8px 12px;
    border: none;
}
.dmpc__wr .dmpc_3rd_middle  .dmpc_3rd_tv_left_hints{
    display: flex;
    align-items: center;
    grid-gap: 5px;
    font-size: 10px;
    font-weight: 400;
    color: var(--red);
}
.dmpc__wr .dmpc_3rd_middle  .dmpc_3rd_tv_left_hints_bold{
    font-weight: 500;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_left_innter .dmpc_3rd_tv_left_textarea:focus{
    outline: none;
}

.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_right{
    padding: 24px 20px;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_right .dmpc_3rd_tv_right_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_right_inner_img img{
    width: 100%;
}



/* for letter preview design start */
.dmpc_3rd_tv_right_inner.tmpp__inner_letter .dmpc_3rd_tv_right_inner_img{
    background-repeat: no-repeat !important;
    width: 703px !important;
    background-size: cover !important;
    height: 912.35px !important;
    box-shadow: 1px 1px 5px #aaa;
    position: relative;
}
.dmpc_3rd_tv_right_inner.tmpp__inner_letter .dmpc_3rd_tv_right_inner_img .letter-address-block{
    position: absolute;
    top: 70px;
    left: 50px;
    width: 260.5px;
    height: 165.5px;
    background-color: #ff000066;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.dmpc_3rd_tv_right_inner.tmpp__inner_letter .dmpc_3rd_tv_right_inner_img .dmpc__letter__folding__hints{
    position: relative;
    border-top: 1px dashed #4285f4;
    border-bottom: 1px dashed #4285f4;
    margin-top: 44.1%;
    margin-left: 1%;
    margin-right: 1%;
    padding-top: 47.1%;
}
/* for letter preview design end */

/* =====================Third Step End============================== */
.dmpc__wr .dmpc_4th_middle .dmpc_4th_middle_top{
    display: flex;
    justify-content: space-between;
    grid-gap: 12px;
    align-items: center;
    padding: 0 24px;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4mt_left_title{
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: var(--dark_blue);
    margin: 0 0 4px 0;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4mt_left_subtitle{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: #546376;
    margin: 0;
}

.dmpc__wr .dmpc_4th_middle_container{
    background: #F4F5F7;
    border-radius: 8px;
    padding: 16px;
    min-height: calc(100vh - 348px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
}

.dmpc__wr .dmpc_4th_middle .dmpc_4th_mc_title{
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.25px;
    color: #133159;
    margin: 16px 0;
    text-align: center;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_mc_tab{
    display: flex;
    justify-content: center;
    text-align: center;
}
.dmpc__wr .dmpc_4th_middle  .dmpc_4th_mc_tab_single{
    background: #fff;
    border-top: 1px solid #E5E8EF;
    border-bottom: 1px solid #E5E8EF;
    border-right: 1px solid #E5E8EF;
    padding: 12px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.16px;
    color: #546376;
    cursor: pointer;
}
.dmpc__wr .dmpc_4th_middle  .dmpc_4th_mc_tab_single:first-child{
    border-left: 1px solid #E5E8EF;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.dmpc__wr .dmpc_4th_middle  .dmpc_4th_mc_tab_single:last-child{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_mc_tab_active{
    background: #3C7EF3;
    font-weight: 500;
    color: #fff;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_mc_tab_contanier{
    padding-top: 16px;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_immediate_title{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #133159;
    letter-spacing: 0.25px;
}

/* day tab */
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day_hour{
   position: relative;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day_hour_inner{
    display: flex;
    align-items: center;
    grid-gap: 8px;
    margin-bottom: 4px;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day_hour .day_select_formControll_65{
    width: 65px;
    max-width: 65px;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day_hour .day_select_formControll_97{
    width: 97px;
    max-width: 97px;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day_hour .day_select_formControll_132{
    width: 132px;
    max-width: 132px;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day_hour .day_select_sf{
    border: 1px solid #E5E8EF;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray);
    background: #fff;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day .dmpc_4th_tab_day_inner{
    display: flex;
    grid-gap: 24px;
    justify-content: center;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day .dmpc_4th_tab_day_range{
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: var(--red);
    position: absolute;
    width: 150px;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day .dmpc_4th_tab_day_range_left52{
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: var(--red);
    position: absolute;
    width: 150px;
    left: 52%;
}

.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day_hour .day_select_sf .MuiSelect-select{
    padding: 7px 12px;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day_hour .day_select_sf .MuiSelect-select:focus{
    background-color: transparent !important;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_tab_day_hour .dmpc_4th_tab_day_hour_title{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: var(--gray);
}

.dmpc__wr .dmpc_4th_middle .dmpc_4th_footer{
    padding-top: 80px;
}



/* for date tab */
.dmpc__wr .dmpc_4th_middle .dmpc_4th_middle_dateInput{
    border: 1px solid #E5E8EF;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray);
    background: #fff;
    height: 41px;
    padding: 10px;
    margin: 0;
}
.dmpc__wr .dmpc_4th_middle .dmpc_4th_middle_dateInput:focus{
    box-shadow: 0 0 0 rgba(0,0,0,0.0) !important;
    border-color: #E5E8EF;
    /* border-bottom: #E5E8EF!important; */
}
.dmpc__wr .dmpc_4th_middle .day_select_formControll .MuiInputBase-root.MuiInput-root{
    border: 1px solid #E5E8EF;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray);
    background: #fff;
}
.dmpc__wr .dmpc_4th_middle .day_select_formControll .MuiInputBase-root.MuiInput-root input{
    height: 30px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray);
    height: 38px;
    padding: 7px 5px;
    margin: 0;
}
.dmpc__wr .dmpc_4th_middle .day_select_formControll .MuiInputBase-root.MuiInput-root .MuiIconButton-root{
    padding: 9px;
}
.dmpc__wr .dmpc_4th_middle .day_select_formControll .MuiInputBase-root.MuiInput-root .MuiIconButton-root svg{
    width: 15px;
}

.dmpc_4th_mc_tab .dmpc_4th_mc_tab_single.dmpc__send_type_block__block{
    cursor: not-allowed;
    opacity: 0.5;
}
/* =====================Fourth Step End============================== */
/* footer and go back and save continue btn */
.dmpc__wr .dmpc_tv_right_inner_btn{
    display: flex;
    justify-content: center;
    grid-gap: 32px;
    padding-top: 32px;
}
.dmpc__wr .dmpc_tv_right_inner_btn .dmpc_tv_right_inner_backbtn{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #133159;
    border: 1px solid #133159;
    border-radius: 4px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 12px 16px;
    cursor: pointer;
}
.dmpc__wr .dmpc_tv_right_inner_btn .dmpc_tv_right_inner_savebtn span,
.dmpc__wr .dmpc_tv_right_inner_btn .dmpc_tv_right_inner_backbtn span{
    display: inline-flex;
}
.dmpc__wr .dmpc_tv_right_inner_btn .dmpc_tv_right_inner_savebtn{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    border: 1px solid #3C7EF3;
    background-color: #3C7EF3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 12px 16px;
    cursor: pointer;
}










/* responsive */
@media screen and (max-width:1400px) {
    /* 1st step */
    .dmpc__wr .dmpc__steper__content .dmpc_1st_middle{
        margin: 30px auto 0 auto;
    }
    /* ==============================Second Step Start===================== */
    .dmpc__wr .dmpc__setper_wr .MuiStepper-root{
        width: 100%;
    }
   
    .dmpc__wr .dmpc_2nd_middle .dmpc_tv_right_inner_img img{
        height: 100%;
    }
    /* select canva img */
    .dmpc_canva_modal_body .dmpc_canva_modal_gallery{
        grid-gap: 40px;
        margin-top: 45px;
    }
    .dmpc_canva_modal_body .dmpc_canva_modal_g_sigle .dmpc_canva_modal_g_sigle_span svg{
        width: 45px;
    }
    .dmpc_canva_modal_body .dmpc_canva_modal_g_sigle .dmpc_canva_modal_g_sigle_span{
        right: -21px;
        top: -33px;
    }
    /* ==============================Second Step End===================== */
    .dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tag_s .MuiSelect-select{
        padding: 3px 25px 3px 4px;
    }
   
    .dmpc__wr .dmpc_3rd_middle .dmpc_3rd_no_image_found .dmpc_3rd_no_image_found_inner{
        grid-gap: 54px;
    }
    /* =====================Third Step End============================== */
   




}

@media screen and (max-width:1024px) {
    /* root steper for all */
    .dmpc__wr .MuiStepper-root.MuiStepper-horizontal{
        padding: 16px;
    }
    /* 1st step start */
    .dmpc__wr .dmpc__steper__content .dmpc_1st_middle{
        margin: 40px auto 0 auto;
    }
    .dmpc__wr .dmpc__steper__content .dmpc_1st_middle__title{
        font-size: 24px;
        line-height: 29px;
    }
    .dmpc__wr .dmpc__setper_wr .MuiStepLabel-labelContainer .MuiStepLabel-label{
        font-size: 12px;
    }

    /* ==============================Second Step Start===================== */
     /* second step start */
     .dmpc__wr .dmpc_2nd_middle .dmpc_tv_right .dmpc_tv_right_inner{
        padding-left:0;
    }
    .dmpc__wr .dmpc_2nd_middle .dmpc_2nd_middle_top{
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        padding: 8px 0px 0 0px;
    }
    .dmpc__wr .dmpc_2nd_middle .dmpc_templete_view{
        flex-direction: column;
    }
    .dmpc__wr .dmpc_2nd_middle .dmpc_templete_view .dmpc_tv_left{
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .dmpc__wr .dmpc_2nd_middle .dmpc_templete_view .dmpc_tv_right{
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    /* canva upload */
    .dmpc__wr .dmpc_2nd_middle .dmpc_templete_upload .dmpc_templete_ui_single{
        width: 100%;
    }

    /* select image  canva */
    .dmpc_canva_modal_body .dmpc_canva_modal_gallery .dmpc_canva_modal_g_sigle{
        max-width: 320px;
        flex-basis: 320px
    }
     /* ==============================Second Step End===================== */
     .dmpc__wr .dmpc_3rd_middle .dmpc_3rd_templete_view{
        flex-direction: column;
    }
    .dmpc__wr .dmpc_3rd_middle .dmpc_3rd_templete_view .dmpc_3rd_tv_left{
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
    }
    .dmpc__wr .dmpc_3rd_middle .dmpc_3rd_templete_view .dmpc_3rd_tv_right{
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
    }
    .dmpc__wr .dmpc_3rd_middle .dmpc_3rd_tv_right .dmpc_3rd_tv_right_inner{
        padding-left: 0;
    }
    .dmpc__wr .dmpc_3rd_middle .dmpc_3rd_templete_view .dmpc_3rd_tv_left .dmpc_expand_scroll_icon{
        display: none;
    }
    .dmpc__wr .dmpc_3rd_middle .dmpc_3rd_no_image_found{
        padding: 97px 38px 50px 47px;
    }
     /* =====================Third Step End============================== */
     .dmpc__wr .dmpc_4th_middle .dmpc_4th_mc_tab_single{
        font-size: 12px;
        padding: 12px 8px;
    }
    /* =====================Fourth Step End============================== */
}


@media screen and (max-width:800px){
    .dmpc__wr .dmpc_4th_middle .dmpc_4th_mc_tab_single{
        font-size: 12px;
        padding: 12px 6px;
    }
}















/* global const  */

/* main css will be added before  */

/* pypepro font family */
.pype_Allura_font_family{
    font-family: 'Allura', cursive !important;
}
.pype_Lobster_font_family{
    font-family: 'Lobster', cursive !important;
}
.pype_Freehand_font_family{
    font-family: 'Freehand', cursive !important;
}
.pype_Handlee_font_family{
    font-family: 'Handlee', cursive !important;
}
.pype_Shrikhand_font_family{
    font-family: 'Shrikhand', cursive !important;
}
.pype_Fasthand_font_family{
    font-family: 'Fasthand', cursive !important;
}
.pype_Gochi_Hand_font_family{
    font-family: 'Gochi Hand', cursive !important;
}
.pype_Bilbo_Swash_Caps_font_family{
    font-family: 'Bilbo Swash Caps', cursive !important;
}
.pype_Playfair_Display_font_family{
    font-family: 'Playfair Display', serif !important;
}
.pype_Edu_TAS_Beginner_font_family{
    font-family: 'Edu TAS Beginner', cursive !important;
}
.global_post_card_disabled_btn{
    opacity: 0.5 !important;
    cursor: not-allowed !important;
    background-color: #3c7ef396 !important;
}