.mainWrp{
  width: 100%;
  /* height: auto; */
  background-color: #dddddd72;
  padding: 10px;
  box-sizing: border-box;
}
.conversationWrp{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.incomingWrp{
  width: 100%;
}
.outgoingWrp{
  width: 100%;
}
.message{
  width: fit-content;
  max-width: 65%;
  min-width: 100px;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  align-items: center;
  font-size: 12px;
}
.incomingWrp .message{
  float: left;
}
.outgoingWrp .message{
  float: right;
  text-align: right;
}
.footerWrp{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
.loadMore{
  padding: 10px 20px;
  align-items: center;
  text-align: center;
  background-color: #ddd;
  border-radius: 7px;
  cursor: pointer;
  color: #a20f0f;
  font-size: 10px;
}
.startIndex{
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  margin-top: 8px;
}
.endIndex{
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}
.single{
  border-radius: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.wrapItem{
  border-radius: 0px;
}