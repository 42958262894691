.runningLabelWrp{
	height: 20px;
	border-radius: 10px;
	background: #6fcf97;
	padding-right: 0.5rem;
	align-items: center;
	display: flex;
	color: #fff;
	box-sizing: inherit;
	cursor: pointer;
}
.runningLabelWrp .leftIcon{
	width: 15px;
	font-size: 14px;
	color: #fff;
	margin-left: 5px;
}
.runningLabelWrp .text{
	font-size: 10px;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	color: #fff;
	box-sizing: inherit;
}
.runningLabelWrp .rightIcon{
	font-size: 14px;
	width: 14px;
	color: #fff;
}


/* item */
.itemWrp{
	cursor: pointer;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	margin-bottom: 1px;
}
.itemWrp:first-child{
	margin-top: 4px;
}
.itemWrp:last-child{
	margin-bottom: 4px;
}
.itemWrp:hover{
	background-color: #cbd4ff;
}
.itemTitle{
  font-size: 15px;
	color: #133159;
	font-weight: 400;
}
.popupWrapper{
	padding-bottom: 5px !important;
}
