.update textarea.control-overflow__input {
    overflow: auto !important;
    position: static !important;
    padding: 0 !important;
}
.update .p-4 {
    all: unset !important;
}
.chat__bottom_wrapper.update .control-overflow__highlighter {
    all: unset !important;
    display: none !important;
}

.update .control-overflow__control {
    padding: 15px;
    font-size: 15px !important;
    color: #000;
    opacity: 1;
}