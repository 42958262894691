.pypepro__custom__drag__drop__container{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 105px;
    width: 100%;
    height: 305px;
    background: #FFFFFF;
    border: 1px dashed #3C7EF3;
    border-radius: 5px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    position: relative;
}
#pcddc .pcddc__image_preview__container{
    width: 150px;
    height: 200px;
    top: 5px;
    left: 5px;
    position: absolute;
    z-index: 9;
    overflow: hidden;
}
#pcddc .pcddc__upload__action{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 201px;
    height: 74.11px;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
}
#pcddc .pcddc__upload__action .pcddc__upload__action_title{
    font-size: 16px;
    font-weight: 600;
    color:#133159;
    line-height: 18px;
}
#pcddc .pcddc__upload__action .pcddc__upload__action_subtitle{
    font-size: 16px;
    font-weight: 300;
    color:#133159;
    line-height: 18px;
}
#pcddc .pcddc__image_preview__container .pcddc__ipc__image{
    width: 100%;
    height: 100%;
}
#pcddc .pcddc__image_preview__container .pcddc__ipc__delete{
    width: 20px;
    height: 20px;
    right: 2px;
    top: 2px;
    position: absolute;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
#pcddc .pcddc__image_preview__container.pcddc__hide{
    display: none !important;
}
#pcddc .pcddc__input__field__file{
    display: none;
}
#pcddc .pcddc__show__hints{
    top: 5px;
    right: 5px;
    z-index: 9;
    color: #FF264A;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
}
.pypepro__custom__drag__drop__container.pypepro__draging__container{
    background-color: aquamarine !important;
}