
.direct_mpc_modal_wr {
    padding: 20px;
    background: #fff ;
}
.direct_mpc_modal_wr .direct_mpc_modal_title{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: var(--dark_blue);
    margin: 0;
}
.direct_mpc_modal_wr .direct_mpc_modal_header{
    border-bottom: 1px solid #E5E8EF;
    padding-bottom: 10px;
}
.direct_mpc_modal_wr .direct_mpc_modal_close{}

