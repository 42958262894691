.modalMainWrp{
    width: 60vw;
    height: 75vh;
    border-radius: 10px;
    margin: auto;
    box-shadow: 0 6px 15px rgb(0 0 0 / 10%);
}
.modalBodyWrp{
    width: 100%;
    height: 100%;
    display: flex;
    background: #fff;
    border-radius: 10px;
}