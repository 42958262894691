.pypepro_global__custom__field__grouped__wraper .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock{
    color: black;
}
.pypepro_global__custom__field__grouped__wraper svg.MuiSvgIcon-root{
    color: black;
}

#menu__popup__pypepro .empty__containner{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 12px;
    color: var(--gray);
}
#menu__popup__pypepro .gift_card_main_wrpper{
    padding-bottom: 10px !important;
    height: calc(100vh - 340px) !important;
}
#menu__popup__pypepro .MuiPaper-root{
    height: unset !important;
}
.pypepro_global__custom__field__grouped__wraper .label_class_wrapper{
    display: flex;
    grid-gap: 4px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 7px;
}
.pypepro__group_wise__personlized_tags_container{
    padding: 16px 12px;
}
.pypepro__group_wise__personlized_tags_container .pgwptc__each__group{
    background-color: var(--accent-color-opacity1);
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
}
.pypepro__group_wise__personlized_tags_container .pgwptc__each__group + .pgwptc__each__group{
    margin-top: 8px;

}
.pypepro__group_wise__personlized_tags_container .pgwptc__eg_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.pypepro__group_wise__personlized_tags_container .pgwptc__eg_info span{
    display: inline-flex;
}
.pypepro__group_wise__personlized_tags_container .pgwptc__eg_all_list{
    font-size: 12px;
    padding: 8px 16px;
    cursor: pointer;
    color: var(--gray);
}
.pypepro__group_wise__personlized_tags_container .pgwptc__eg_all_list .pgwptc__group__each__item + .pgwptc__group__each__item{
    margin-top: 8px;
    border-top: 1px solid #b6b8bd;
    padding-top: 8px;
    cursor: pointer;
}