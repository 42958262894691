.modalWrapper {
    display: grid;
    place-content: center;
    font-family: "Poppins", serif;
}

.modalInner {

}
.modalInner:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.modalInner::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.modalInner::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}
.modalInner::-webkit-scrollbar {
    scrollbar-width: thin;
}
.modalInner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}
.modalInner::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}