.parent-loader-div{
    position: relative;
    height: 100%;
}
/* Default skeleton css start */
.parent-loader-div .default-skeleton {
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    width: 100%;
}

/* Dashboard skeleton css start */
.parent-loader-div .stage-distribution-card {
    width: 100%;
}

.parent-loader-div .dashboard-loader-parent .dashboard-loader-left {
    flex: 1
}
.parent-loader-div .dashboard-loader-parent .gap {
    gap: 27px;
}

.parent-loader-div .dashboard-loader-parent .deal-item {
    flex: 1
}

.parent-loader-div .dashboard-loader-parent .dashboard-loader-right {
    width: 378px;
    background-color: white;
}

.parent-loader-div .dashboard-loader-parent .funnel-left {
    flex: 1;
    background-color: white;
}

.parent-loader-div .dashboard-loader-parent .funnel-right {
    width: 500px;
    background-color: white;

}

.parent-loader-div .dashboard-loader-parent .mb-4 {
    margin-bottom: 28px;
}

.parent-loader-div .dashboard-loader-parent .funnel-structure {
    background-color: rgb(223, 223, 223);
    clip-path: polygon(0 0, 100% 0, 59% 84%, 59% 100%, 36% 99%, 36% 80%);
    width: 100%;
    height: 300px;
}

.parent-loader-div .dashboard-loader-parent .right-sub-item {
    flex: 1;
}

/* Deal css start */
.parent-loader-div .deal-loader-parent {
    background-color: #FFFFFF;
    flex-basis: 100%;
    /* border: 1px solid; */
}

.parent-loader-div .deal-loader-parent .deal-child-one {
    flex: 1;
    border: 1px solid #e5e8ef;
}

.parent-loader-div .deal-loader-parent .deal-child-two {
    clip-path: polygon(76% 0, 93% 48%, 75% 100%, 0% 100%, 0 52%, 0% 0%);
    height: 50px;
    background-color: #DFDFDF;
    flex: 1
}

.parent-loader-div .deal-loader-parent .deal-child-two-middle {
    clip-path: polygon(76% 0, 93% 48%, 75% 100%, 0% 100%, 11% 47%, 0% 0%);
    height: 50px;
    background-color: #DFDFDF;
    flex: 1
}

.parent-loader-div .deal-loader-parent .deal-child-two-end {
    clip-path: polygon(100% 0, 100% 41%, 100% 96%, 0% 100%, 11% 47%, 0% 0%);
    height: 50px;
    background-color: #DFDFDF;
    flex: 1
}

.parent-loader-div .deal-loader-parent .deal-child-three {
    flex: 1;
    gap: 10px;
}

.parent-loader-div .deal-loader-parent .loader-box {
    border: 1px solid #e5e8ef;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
}

/* Inbox css start  */


.parent-loader-div .inbox-loader-parent .child-one {
    flex: 1;
    background-color: #FFFFFF;
    /* border: 1px solid #e5e8ef; */

}

.parent-loader-div .inbox-loader-parent .child-two {
    flex: 2;
    background-color: #FFFFFF;
    /* border: 1px solid #e5e8ef; */
}

.parent-loader-div .inbox-loader-parent .subitem {
    flex: 1;
}

.parent-loader-div .inbox-loader-parent .child-two {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

/* ActivityCalendarModule css start  */

.parent-loader-div .activityCalendar-Parent .activity-child-one {
    flex: 1;
}

.parent-loader-div .activityCalendar-Parent .activity-child-two {
    flex: 1;
}

.parent-loader-div .activityCalendar-Parent .activity-child-three {
    flex: 1;
    gap: 5px;
}

.parent-loader-div .activityCalendar-Parent .CalendarClass {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 30px repeat(5);
    row-gap: 20px;
    column-gap: 10px;
}

.parent-loader-div .activityCalendar-Parent .CalendarClass-child {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #DFDFDF;
}

/* CalendarWidgetModule css start  */
.parent-loader-div .widget-parent-loader .widget-child-one {
    flex: 1;
    background-color: #e5e8ef;
}

.parent-loader-div .widget-parent-loader .widget-child-two {
    flex: 1;
    flex-basis: 100%;
}

.parent-loader-div .widget-parent-loader .widget-child-two-item-one-parent {
    background-color: #e5e8ef;
    flex-basis: 33%;
}

.parent-loader-div .widget-parent-loader .widget-child-two-item-one-parent .widget-child-two-item-one-subitem {
    flex: 1
}

/*ContactModuleSkeleton css start */

.parent-loader-div .contact-parent-loader .contact-child-one {
    flex: 1;
}

/* common gap */
.parent-loader-div .gap-5{
    gap:5px
}
.parent-loader-div .gap-10{
    gap:10px;
}
.parent-loader-div .gap-15{
    gap:15px;
}
.parent-loader-div .gap-20{
    gap:20px;
}

/* common borderRadius */

.parent-loader-div .borderRadius50px{
    border-radius: 50px;
}
.parent-loader-div .borderRadius50-percent{
    border-radius: 50%;
}

/*MarketingModule Skeleton css start */
.parent-loader-div .marketing-loader-parent{
    flex-basis: 100%;
}

.parent-loader-div .marketing-loader-parent .marketing-loader-left{
    background-color: #FFFFFF;
    width: 100%;
    max-width: 345px;
    border-radius: 8px;
    padding:16px 8px;
    height: calc(100vh - 140px);
}
.parent-loader-div .marketing-loader-parent .marketing-loader-right{
    background-color:  #FFFFFF;
    width: 100%;
    border-radius: 8px;
    padding: 16px 20px;
}

.parent-loader-div .marketing-loader-parent .marketing-loader-right .marketing-loader-child-card{
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    padding: 20px 32px;
}

/* MapFinderModule Skeleton CSS start */

.parent-loader-div .map-finder-parent{
    flex-basis: 100%;
    background-color: #EEEEEE;
}
.parent-loader-div .map-finder-parent .map-finder-child-one{
    flex: 1;
}
.parent-loader-div .map-finder-parent .map-finder-child-two{
    flex: 1;
}

.parent-loader-div .map-finder-parent .svg-icon{
    display: flex;
    justify-content: center;
    height: 600px;
    align-items: center;
    background-color: #FFFFFF;
}

/* TriggerModule Skeleton CSS start */
.parent-loader-div .trigger-skeleton-parent .trigger-child-one{
    flex: one;
    background-color: #FFFFFF;
    flex-basis: 100%;
}
.parent-loader-div .trigger-skeleton-parent .trigger-child-two{
    flex: one;
    background-color: #FFFFFF;
    flex-basis: 100%;
}
.parent-loader-div .trigger-skeleton-parent .trigger-child-three{
    flex: one;
    background-color: #E5E5E5;
    flex-basis: 100%;
}
.parent-loader-div .trigger-skeleton-parent .trigger-child-three-subitem{
    flex: one;
    background-color:#FFFFFF;
    flex-basis: 100%;
    border: 1px solid #e5e8ef;
}
.parent-loader-div .trigger-skeleton-parent .trigger-child-three-subitem-two{
    flex: one;
    background-color:#FFFFFF;
    flex-basis: 100%;
}

 /* ReportModule Skeleton CSS start */

 .parent-loader-div .report-skeleton-parent{
    flex-basis: 100%;
    background-color: #FFFFFF;
}
.parent-loader-div .report-skeleton-parent .report-child-two{
    flex-basis: 100%;
    background-color: #E5E5E5;
}
.parent-loader-div .report-skeleton-parent .graph-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100px; */
}
.parent-loader-div .report-skeleton-parent .report-child-three{
    background-color: #E5E5E5;
}

/* profileModuleSkeleton css start */

.parent-loader-div .profile-module-skeleton-parent .profile-module-child-one{
    background-color: #FFFFFF;
    flex-basis: 100%;
}