
.public_page_container .contact_us_page_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

}
.public_page_container .contact_us_page_wrapper .contact_us_image img {
    width: 247px;
    object-fit: contain;
}
.public_page_container .contact_us_page_wrapper .form_heading{
    font-size: 26px;
    margin: 0 0 9px 0;
}
.public_page_container .contact_us_page_wrapper .form__sub__heading{
    margin: 0 0 30px 0;
}
.public_page_container .contact_us_page_wrapper .success__msg{
    color: green;
}
.public_page_container .contact_us_page_wrapper .contact_us_image{
    width: 50%;
    margin: 10px;
    text-align: right;
    line-height: 75px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
}

.public_page_container .contact_us_page_wrapper .contact_us_form{
    width: 50%;
    margin: 10px;
    text-align: left;
}

.public_page_container .contact_us_page_wrapper .contact_us_form_wrapper {
   
    text-align: center;
    max-width: 472px;
    width: 100%;
    background: #fff;
    padding: 24px;
    border-radius: 6px;
}

.public_page_container .contact_us_page_wrapper .contact_us_form_wrapper .input_field {
    width: 100%;
    border-color: #ddd;
    margin:  0 0 24px 0;
    height: 40px;
    resize: none;
    border-radius: 6px;

}
.public_page_container .contact_us_page_wrapper .contact_us_form_wrapper .input_field:focus{
    outline: none;
}
.public_page_container .contact_us_page_wrapper .contact_us_form_wrapper textarea.input_field{
    padding: 10px;
    height: 64px;
}

/* .contact_us_page_wrapper .contact_us_form_wrapper .contact_us_textarea{
   width: 47%;
} */

.public_page_container .contact_us_page_wrapper .contact_us_form_wrapper .form_heading {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
} 

.public_page_container .contact_us_page_wrapper .contact_us_form_wrapper .conatct_us_form_button {
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: cornsilk;
    background-color: blue;
    padding: 10px;
    border: 1px solid blue;
    width: 100%;
    border-radius: 6px;
    display: inline-block;
    max-width: 154px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
} 

@media screen and (max-width:1024px) {
    .public_page_container .contact_us_page_wrapper .contact_us_image img{
        width: 200px;
    }
    .public_page_container .contact_us_page_wrapper .contact_us_image{
        width: 40%;
    }
}