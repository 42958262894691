.share_page__wrapper.trigger_share {
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share_page__wrapper.trigger_share .share_page__inner_wrapper {
    text-align: center;
}

.share_page__wrapper.trigger_share .share_page__inner_wrapper h5 {
    position: relative;
}

.share_page__wrapper.trigger_share .share_page__inner_wrapper .MuiCircularProgress-root{
    left: -52px;
    bottom: -7px;

}

.share_page__wrapper.unsubscribe_contact {
    height: 100vh;
}

.share_page__inner_wrapper .nylas_connect {
    width: 80px;
    height: 80px;
    background-color: #3c7ef3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 25px auto;
    svg {
        font-size: 50px;
        color: #fff;
    }
}