/*****************modal 08 Add Contact****************/
.add-contact-modal {
    top: 81px !important;
    max-width: 1170px;
    width: 100%;
    background: #FFFFFF !important;
    border-radius: 10px 0px 0px 10px !important;
    left: unset;
}

.add-contact-footer.manage-tag-footer.modal-footer {
    background: #FFFFFF;
    border-radius: 10px 0px 0px 10px;
}

/*.add-contact-content-body {
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
    background: #F6F7FB;
    padding: 10px 0px 70px 0px;
    width: 96%;
    margin: 0 auto;
}
*/
/* .contact-list-top{
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
    background: #F6F7FB;
    padding: 10px 0px 0px 0px;
    width: 96%;
    margin: 0 auto;
} */

.contact-list-bottom {
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
    background: #F6F7FB;
    padding: 0px 0px 0px 0px;
    width: 96%;
    margin: 0 auto;
    margin-top: 50px;
    border-top: none;
}

.custom-fild-row {
    background: #3C7EF3;
    border-radius: 10px 10px 0px 0px;
    padding: 15px 5px;
}

.custom-fild-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-fild-heading.col.s12 h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin: 0;
}

.custom-fild-arrow {
    position: absolute;
    right: 30px;
    top: 35px;
    pointer-events: none;
}

.margin-none-row .custom-input-field input {
    padding-left: 15px !important;
}

.custom-select-field select {
    padding-left: 15px !important;
}

.toggle-class1 {
    cursor: pointer;
}

.margin-none-row {
    margin-bottom: 0px;
}

.main-add-contact-modal .custom-input {
    position: relative;
    padding-left: 11px;

}

.custom-select-arrow {
    position: relative;
}

.custom-select-arrow:after {
    position: absolute;
    content: "";
    top: 70%;
    right: 30px;
    background: url(./assets/img/ad.png) no-repeat scroll 0 0 / 100% 100%;
    width: 14px;
    height: 7px;
    transform: translateY(-70%);
    pointer-events: none;
    cursor: pointer;
}

.custom-input select:focus {
    outline: unset !important;
}

.second__step__area__select .MuiSelect-select,
.MuiPaper-rounded ul,
.custom-input .MuiInputBase-root {
    font-size: 14px !important;
    line-height: 30px !important;
    /* line-height: 9px!important; */
    letter-spacing: 0.01em !important;
    color: #8896A8 !important;
    font-family: var(--Poppins) !important;
    font-style: normal !important;
    font-weight: normal !important;
}

.MuiMenuItem-root {
    font-family: var(--Poppins) !important;
}

.custom-input select {
    padding: 0px 50px;
    -webkit-appearance: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #8896A8;
    padding-left: 60px;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box;
    border-radius: 5px !important;
    background: #FFFFFF !important;
}

.add-action .MuiButtonBase-root {
    border: 1px solid rgba(60, 126, 243, 0.1) !important;
    padding: 8px 10px !important;
}

.add-action .MuiButtonBase-root:hover {
    box-shadow: unset !important;
}

.gIILoc {
    max-height: unset !important;
}

.ESptJ {
    width: 100% !important;
}

.custom-input input {
    -webkit-appearance: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #8896A8;
    padding-left: 12px !important;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    background: #FFFFFF !important;
    margin: 0px !important;
    position: unset !important;
    z-index: 0 !important;
}

.contact-list-input input {
    padding-left: 60px !important;
}

.custom-input input:focus {
    box-shadow: inherit !important;
}

.custom-input input:placeholder {
    opacity: 1 !important;
}

.custom-input input[type=text]::-webkit-input-placeholder {
    color: #8896A8;
    opacity: 1 !important;
}

.custom-input input[type=text]::-moz-placeholder {
    color: #8896A8;
    opacity: 1 !important;
}

.custom-input input[type=text]:-ms-input-placeholder {
    color: #8896A8;
    opacity: 1 !important;
}

.custom-input input[type=text]::-ms-input-placeholder {
    color: #8896A8;
    opacity: 1 !important;
}

.custom-input input[type=text]::placeholder {
    color: #8896A8;
    opacity: 1 !important;
}

.main-add-contact-modal .custom-select {
    position: absolute;
    top: 33px;
    left: 24px;
    pointer-events: none;
}

.add-contact-header.manage-tag-header {
    border-bottom: none;
}


.custom-input label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #546376 !important;
    display: block;
    padding-bottom: 5px;
}

.custom-input label:focus {
    color: #546376 !important;
    opacity: 1 !important;
}


span.phone-svg {
    position: absolute;
    text-align: center;
    width: 32px;
    height: 32px;
    line-height: 32px;
    left: 0;
    top: 5px;
}

span.phone-svg2 {
    top: 6px;
}

/* .add-contact-content-body {
    height: 579px;
    height: unset;
} */

tr.row-blank {
    height: 10px !important;
    border: 0px !important;
}

/*input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
*/

/*=========Custom Field css============*/
.custom_field {
    border-top: unset !important;
    border-left: unset !important;
    border-right: unset !important;
    box-shadow: unset !important;
    margin-top: 23px;
}

.custom_field_title {
    border-bottom: unset;
    background: #3C7EF3;
    border-radius: 10px 10px 0px 0px;
    color: var(--white);
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
}

.custom_field_title span {
    margin-left: auto;
}

.custom_field_body {
    background: #F6F7FB;
    border: 1px solid #E5E8EF;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
}

/* .custom_input_forms label {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
} */
.custom_contact_field input {
    padding: 0 12px !important;
}

 .custom_input_forms input {
    background: #FFFFFF !important;
    border: 1px solid #E5E8EF !important;
    border-radius: 5px !important;
    margin-top: 4px !important;
    font-size: 15px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #36454F;
    position: relative;
    box-sizing: border-box !important;
    height: 36.25px !important;
}

.custom_input_forms input::placeholder {
    color: #929DA9;
}

.custom_input_forms label,
.custom_input_forms_select label {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    text-transform: capitalize;
}

.custom_input_forms input:focus {
    box-shadow: unset !important;
    -webkit-box-shadow: unset !important;
}

.single_contact_form_group .custom_input_forms span {
    position: absolute;
    background: #E5E8EF;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    top: 16px;
    right: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.custom_drp_dwn span {
    background: unset !important;
}

.manage-tag-header h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #133159;
    letter-spacing: 0.01em;
}

.manage-tag-footer.modal-footer {
    justify-content: center;
    align-items: center;
    display: flex !important;
    background: #F6F7FB;
    border-radius: inherit;
    text-align: center;
    height: auto;
    padding-top: 16px;
    border-top: 1px solid #E5E8EF;
    padding-bottom: 16px !important;
}

.manage-tag-footer.modal-footer a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    display: inline-block;
    padding: 5px 20px;
    height: auto;
    text-transform: capitalize;
}

a.manage-tag-footer-btn-right.waves-effect.waves-green.btn-flat svg {
    position: relative;
    top: 4px;
    right: 4px;
}

a.manage-tag-footer-btn-left.modal-close.waves-effect.waves-green.btn-flat svg {
    position: relative;
    top: 6px;
}

a.manage-tag-footer-btn-right.waves-effect.waves-green.btn-flat {
    background: #3C7EF3;
    border-radius: 5px;
}

a.manage-tag-footer-btn-left.modal-close.waves-effect.waves-green.btn-flat {
    background: #FF264A;
    border-radius: 5px;
    margin: 0px 12px;
}

.manage-tag-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E8EF;
    padding: 20px 20px 10px;
}

.create-collaborate-modal .manage-tag-header {
    padding: 0px 20px 10px;
}

.multi-manage-tag-header.manage-tag-header {
    width: 99%;
    margin: 0 auto;
    border-bottom: 2px solid #fff;
}

a.manage-tag-footer-btn-left.modal-close.waves-effect.waves-green.btn-flat svg {
    position: relative;
    top: 6px;
}

a.manage-tag-footer-btn-left.modal-close.waves-effect.waves-green.btn-flat {
    background: #FF264A;
    border-radius: 5px;
    margin: 0px 12px;
}

button.custom-next.custom-close.modal-close.action-button {
    background: #FF264A !important;
}

.select-wrapper {
    width: 100%;
    height: auto;
    display: block;
}

.contact-list-input .ppc__lead_source input {
    padding-left: 0px !important;
}

.ppc__lead_source .second__step__area__select .MuiSelect-select.MuiSelect-select {
    padding-left: 60px;
    box-sizing: border-box;
}

.dash-bord-select.col.s12 .custom-select-arrow:after {
    right: 32px;
    top: 24px;
}

.custom-select-arrow .select-wrapper svg {
    display: none;
}

.second__step__area__select {
    border: 1px solid rgba(60, 126, 243, 0.1) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    position: relative !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500;
    padding: 10px 8px 14px !important;
    height: 42px !important;
    box-sizing: border-box;
}

.second__step__area__select.MuiInput-underline:before {
    border-bottom: 0px solid rgba(0, 0, 0, 0.0) !important;
}

.second__step__area__select .MuiSelect-selectMenu {
    overflow: visible;
    min-height: auto;
    white-space: wrap;
    text-overflow: initial;
    min-width: 100% !important;
    display: flex;

}

.second__step__area__select .MuiSelect-select {
    width: 100% !important;
    height: 100% !important;
}

.second__step__area__select .MuiSelect-select:focus {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.0) !important;
    width: 100% !important;
    height: 100% !important;
}

.second__step__area__select.assign__stage_select_wrapper .MuiSelect-select:focus {
    height: 50px !important;
    background: #fff !important;
}

/* .lead_design{
    background-color: #fff;
  } */
.lead_design .MuiSelect-select:focus {
    height: 0px;
    background: none;
}

.contact__list_table_wrapper {
    position: relative;
}

/* .second__step__area__select .MuiSelect-selectMenu {
    overflow: visible; 
    min-height: auto;
    white-space: wrap;
    text-overflow: initial;
    color: #3C7EF3 !important;
    min-width: 100% !important;
    display: flex;
    
  } */
.second__step__area__select__100 {
    width: 100%;
}

.second__step__area__select__100 .MuiInput-underline:before {
    border-bottom: 0 !important;
}

.second__step__area__select__100 .MuiInput-underline::after {
    border-bottom: unset !important;
}

.droupdown__helper .MuiFormControl-root.second__step__area__select.second__step__area__select__100 {
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
    display: flex;
    height: 45px !important;
}

.droupdown__helper .new_mv_slect__wr .MuiSelect-select {
    width: 160px !important;
    overflow-x: auto !important;

}

.droupdown__helper .new_mv_slect__wr .MuiSelect-select:focus {
    width: 160px !important;
}

.droupdown__helper .new_mv_slect__wr .MuiSelect-select::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.0);
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.0);
}

.droupdown__helper .new_mv_slect__wr .MuiSelect-select::-webkit-scrollbar {
    width: 0px;
}



.manage-list-modal.manage-tag-modal.modal {

    background: #fff !important;
}

.addd-campaigns-modal.upload-deal-modal.manage-tag-modal .MuiPaper-root {
    max-width: 750px;
}

.manage-tag-modal .manage-tag-modal-content.modal-content {
    padding: 0;
}

.ppc__notValid {
    color: rgba(255, 0, 0, 0.877);
    font-size: 12px;
    margin-left: 13px;
    display: block;
}

.margin-none-row.row {
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
}

.custom-input.col.s12 span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
}

.main-add-contact-modal .new-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    display: block;
}

.main-add-contact-modal .new-loader>div {
    margin: 0;
    height: 60px;
}

.main-add-contact-modal .new-loader button {
    all: unset;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 60px;
    left: 50%;
    display: block;
    text-align: center;
}

.main-add-contact-modal .new-loader .MuiCircularProgress-root {
    width: 60px !important;
    height: 60px !important;
    margin: 0 !important;
    top: unset;
    left: unset;
}

.main-add-contact-modal .add-auto-complete .css-yk16xz-control {
    height: unset;
    min-height: unset;
    border: 1px solid #E5E8EF !important;
    border-radius: 5px;
}

.main-add-contact-modal .add-auto-complete .css-1hwfws3 {
    width: 100%;
    padding: 0;
}

.main-add-contact-modal .add-auto-complete .css-1g6gooi {
    width: 100%;
    padding: 0;
    margin: 0;
}

.main-add-contact-modal .add-auto-complete .css-1g6gooi>div {
    width: 100%;
    display: block !important;
}

.main-add-contact-modal .add-auto-complete .css-1uccc91-singleValue {
    padding-left: 60px;
}

.main-add-contact-modal .add-auto-complete input {
    width: 100% !important;
    display: inline-block;
    border: none !important;
}

.main-add-contact-modal .add-auto-complete .css-1wa3eu0-placeholder {
    /* display: none; */
    left: 60px;
}

.main-add-contact-modal .add-auto-complete .css-1wy0on6 {
    display: none;
}


input#number-input-field::placeholder {
    color: #8f9cad !important;
}

/* contact limit */
.main-add-contact-modal .loading___container {
    padding: 20px 0;
    text-align: center;
}

.main-add-contact-modal .main_content_main_section_content {
    max-width: 70px;
    margin: 0 auto;
    height: auto;
    max-height: 70px;
}

.main-add-contact-modal h3.main_content_main_section_heading.center {
    font-size: 22px;
    margin: 20px 0 10px;
}

.main-add-contact-modal .main_content_main_section_details a {
    display: block;
    max-width: fit-content;
    margin: 20px auto !important;
    box-shadow: unset;
}

.main-add-contact-modal .main_content_main_section {
    padding: 40px 0;
}

.main-add-contact-modal .main_content_main_section_details b {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.main-add-contact-modal .main_content_main_section_content>div svg {
    height: 100%;
}

.main-add-contact-modal .main_content_main_section_content p {
    padding: 0 !important;
}

.main-add-contact-modal .custom-field-textarea {
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    background: #FFFFFF !important;
    height: 60px;
    resize: none;
    padding: 12px;
}

.main-add-contact-modal .custom-field-textarea::placeholder{
    /* color: #929DA9; */
    opacity: 1;
}

.main-add-contact-modal .custom-field-textarea:focus {
    outline: none;
}

.main-add-contact-modal-v2 .globalModalTitle {
    background: #f6f7fb;
}

.main-add-contact-modal-v2 .global-modal-v2-footer {
    background: rgb(0, 4, 46);
}

.main-add-contact-modal-v2 .custom_input_forms input {
    margin-top: 0px !important;
    border: 1px solid #ced4da !important;
}

.main-add-contact-modal-v2 .custom_input_forms textarea {
    margin-top: 0px !important;
    border: 1px solid #ced4da !important;
    margin-bottom: 0px !important;
}

.main-add-contact-modal-v2 .custom_input_forms select {
    margin-top: 0px !important;
    border: 1px solid #ced4da !important;
    margin-bottom: 0px !important;
}

.main-add-contact-modal-v2 {
    max-width: 850px !important;
}
.main-add-contact-modal-v2 .custom_contact_field input {
    padding: 0 12px !important;
}

.main-add-contact-modal-v2 .custom_input_forms input {
    background: #FFFFFF !important;
    border: 1px solid #E5E8EF !important;
    border-radius: 5px !important;
    margin-top: 4px !important;
    font-size: 15px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #36454F;
    position: relative;
    box-sizing: border-box !important;
    height: 36.25px !important;
}

/* .main-add-contact-modal-v2 .custom_input_forms input::placeholder {
    color: #929DA9;
    opacity: 1;
} */

/* .main-add-contact-modal-v2 .custom_input_forms input {
    color: #133159;
    font-size: 15px !important;
    margin-bottom: 0px !important;
} */

.main-add-contact-modal-v2 .custom_input_forms select {
    border-radius: 4px;
}

.main-add-contact-modal-v2 select {
    color: #8896A8;
}

/* .main-add-contact-modal-v2 .MuiSelect-selectMenu {
    color: #8896A8;
} */

/* .main-add-contact-modal-v2 .my-google-places-autocomplete__placeholder {
    color: #8896A8;
} */



.custom_input_forms .customMultiText {
    margin-top: 4px;
    border-radius: 4px;
    padding-left: 12px;
    padding-top: 8px;
    border: 1px solid #E5E8EF;
    color: #36454F;
    font-size: 15px !important;
    height: 36.25px !important;
}

.custom_input_forms .customMultiText:focus {
    outline: none;
}

.custom_input_forms .customMultiText::placeholder {
    color: #929DA9;
}

.custom_input_forms .radioOptionContainer {
    margin-top: 16px;
    margin-left: 8px;
}

.custom_input_forms .radioOptionContainer .radioGroupStyle {
    display: flex;
    align-items: center;
    gap: 10px;
}