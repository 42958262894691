.align-items-start {
    align-items: flex-start !important;
}
.global_modal_overflow_hidden .globalModalWrapper{
    overflow: hidden !important;
}
.flex-1 {
    flex: 1 !important;
}
h3 {
    margin: 0;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
	display: none;
}

.global__send_modal_wrapper .selected-contact-container > div {
    padding-right: 10px;
}
.global__send_modal_wrapper .selected-contact-container {
	/* max-height: 60px; */
	padding-bottom: 0;
    word-break: break-all;
}

.global__send_modal_wrapper
	.virtual-number-container
	.ds__row
	.trigger__modal_title {
	flex: 1 1;
	margin: 0;
}

.global__send_modal_wrapper .ds__row .alt.customFormControl {
	flex: 2 1;
}

.globalModalWrapper .global__send_modal_wrapper .customFormControl.alt {
	margin-top: 0 !important;
}

.global__send_modal_wrapper .MuiFormControl-root {
	border: 0;
	margin: 0;
	display: inline-flex;
	padding: 0;
	position: relative;
	min-width: 0;
	flex-direction: column;
	vertical-align: top;
}

.global__send_modal_wrapper .customFormControl {
	border: 1px solid #e5e8ef !important;
	border-radius: 6px !important;
	padding: 0 12px !important;
	box-shadow: unset !important;
	width: 100% !important;
	box-sizing: border-box !important;
	margin: 0 !important;
	min-height: 45px;
	display: inline-flex;
	justify-content: center;
}

.global__send_modal_wrapper .ds__system_defined {
	margin-top: 15px;
	border: 1px solid #ddd;
	border-radius: 6px;
}

.global__send_modal_wrapper
	.ds__system_defined
	.MuiFormControl-root.form-control {
	border: 1px solid #ddd;
	border-radius: 0;
	padding: 0 10px;
	border-top: 0;
	border-left: 0;
	border-bottom-right-radius: 6px;
}

.global__send_modal_wrapper .ds__followup {
	margin-top: 15px;
	border: 1px solid #ddd;
	border-radius: 6px;
	padding: 10px;
}

.global__send_modal_wrapper
	.ds__followup
	.trigger__modal_title:not(.ds__alter) {
	text-align: center;
	font-weight: 700;
	font-size: 18px;
}

.global__send_modal_wrapper .trigger__modal_title {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 10px;
}
.time-setting-tabs {
	justify-content: center;
	display: flex;
}

.global__send_modal_wrapper .modal__footer_btn button,
.global__send_modal_wrapper .ds__followup button {
	border: 1px solid #ddd !important;
	border-radius: 6px !important;
	margin: 0 5px 0 0 !important;
	min-height: 45px;
	box-shadow: unset !important;
    transition: all 0.3s linear;
    cursor: pointer;
}
.global__send_modal_wrapper .modal__footer_btn button:focus,
.global__send_modal_wrapper .modal__footer_btn button:hover,
.global__send_modal_wrapper .ds__followup button.active,
.global__send_modal_wrapper .ds__followup button.Mui-selected,
.global__send_modal_wrapper .ds__followup button:focus {
	/* background: #3c7ef3 !important; */
	color: #fff;
}
.global__send_modal_wrapper .ds__send_immediately {
	font-size: 16px;
	text-align: center;
	padding: 20px;
}
.global__send_modal_wrapper .ds__custom_date_wrapper {
	padding: 20px 0 0;
}
.global__send_modal_wrapper .modal__footer_btn button {
    margin: 0 !important;
    min-width: 100px;
}

.global__send_modal_wrapper .react-datepicker__input-container .trigger__modal_textField {
    margin: 0 !important;
    box-shadow: unset !important;
}
/*  */

.global__send_modal_wrapper .global_send_template {
	position: relative;
}

.global__send_modal_wrapper .global_send_template textarea::placeholder {
	color: #202021;
}

.global__send_modal_wrapper .global_send_template textarea {
	padding: 60px 20px 20px;
	height: auto;
	border: 1px solid #e5e8ef;
	border-radius: 6px;
	resize: unset;
	color: #202021;
	box-shadow: unset;
	outline: 0;
}

.global__send_modal_wrapper .template_topbar {
	display: flex;
	align-items: center;
	position: absolute;
}

.global__send_modal_wrapper .template_topbar .customFormControl {
	border-top: 0 !important;
	border-left: 0 !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	border-radius: 0 !important;
}

.global__send_modal_wrapper .template_topbar .sms-send-module-template {
	margin: 0 !important;
	border-bottom: 1px solid #e5e8ef ;
	border-right: 1px solid #e5e8ef ;
	min-height: 45px;
	display: inline-flex;
	align-items: center;
	padding: 10px;
	border-bottom-right-radius: 6px;
    cursor: pointer;
}

.global__send_modal_wrapper
	.template_topbar
	div#gloabal-sms-send-personalized-selector {
	font-size: 14px;
}


.global__send_modal_wrapper .ds__custom_date_wrapper .customFormControl {
    padding: 0 !important;
    /* border: 0 !important; */
	margin: 0 !important;
}

.global__send_modal_wrapper .ds__custom_date_wrapper .customFormControl.alt.alt {
    border: 1px solid #e5e8ef ;
    padding-left: 12px ;
}
.border-1{
	border: 1px solid #e5e8ef!important;
}
.height-48{
	height: 48px;
}
.global__send_modal_wrapper .trigger__modal_textField,
.global__send_modal_wrapper .addAppointment_wrapper select {
    border: 0 !important;
}

.topOption p {
    margin: 0;
}

.global_send_module_singleItem + .global_send_module_singleItem {
    margin-top: 10px;
}
.global_send_module_singleItem p {
    margin-top: 0;
    margin-bottom: 0;
}
.global_send_module_singleItem p + p {
    margin-top: 10px;
}

.global_send_module_singleItem {
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    
}

.global__send_modal_wrapper .back_button {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px 15px;
    min-width: 50px;
    min-height: 45px;
    cursor: pointer;
}

.email__template_desc {
    padding-bottom: 0;
    max-height: 40px;
}

.send-voice-mail-submit .btn{
	padding: 5px 23px;
    height: auto;
}
.btn.btn-primary{
	background-color: var(--light_blue);
}

.voice-mail-toolbar-tab > div{
	background-color: #E5E8EF;
	padding: 5px 20px;
	border-radius: 8px;
	cursor: pointer;
}
.voice-mail-toolbar-tab .voice-record.active{
	background-color: var(--light_blue);
	color: #FFF;
}
.voice-mail-toolbar-tab .voice-pre-template.active{
	background-color: var(--light_blue);
	color: #FFF;
}
.voice-audio-lists {
	max-height: 300px;
	margin:15px 0 0 0;
}
.voice-audio-lists > div + div{
	margin:10px 0 0 0 ;
}
.voice-audio-list{
	background-color: rgba(60, 126, 243, 0.1);
    padding: 10px;
    border-radius: 10px;

}
.voice-mail-list-left {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0 10px 0 0;
}
.voice-mail-vn > p{
	margin-right: 20px;
}

/* design modify */
.global_send_module_singleItem .sms-template-title{

}

.global_send_module_singleItem .sms-template-message{
	font-size: 12px;
    color: #8a8484;
}

.unset-flex{
	flex: unset !important;
}