.borderedDiv {
    position: relative;
    border: 2px solid #cdcdcd;
    padding: 10px;
    margin:20px 30px 5px 30px;
    min-height: 300px;
}
.borderedDivV1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 2px solid #cdcdcd;
    padding: 10px;
    margin:20px 30px 5px 30px;
    height: 300px;
}

.topText {
    position: absolute;
    top: -22px; 
    right: 325px;
    background-color: white; 
    padding:5px;
    font-size: 25px;
    color: #181f48;
}

.textColorDarkBlue
{
    color: #181f48 !important;
}

.topTextV1 {
    position: absolute;
    top: -22px;
    color: #181f48;
    /*right: 304px; */
    background-color: white; 
    padding:5px;
    font-size: 25px;
}

.borderedDiv .content {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.mainWrp{
    width: 800px;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header{
    width: 100%;
    min-height: 40px;
    padding: 14px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: 1px solid #e7dada;
    font-size: 22px;
    font-weight: 600;
}

.bodyWrp{
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    /*padding: 20px;*/
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: justify;
    /* border: 1px solid; */
}

.bodyWrp:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.bodyWrp::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.bodyWrp::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}
.bodyWrp::-webkit-scrollbar {
    scrollbar-width: thin;
}
.bodyWrp::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}
.bodyWrp::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.validatorResult {
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    border: 2px solid #00cec9;
    border-radius: 6px;
}

.validatorResultTitle {
    margin-bottom: 15px;
    color: #2a2a2e;
    font-size: 20px;
}

.validatorResultContact {
    margin-bottom: 5px;
    font-weight: 700;
}

.footerWrp{
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    box-sizing: border-box;
    gap: 20px;
}

.closeButtonDiv {
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    box-sizing: border-box;
    gap: 20px;
}
.closeButton{
     background-color: #006df5;
     border: 0;
     color: #fff;
     padding: 8px 30px;
     border-radius: 4px;
}

.downloadButton{
    background-color: #181f48;
    border: 0;
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    height: 50px;
    width: 170px;
}

.overlay{
    background-color: #00000080;
}

.statusChip {
    background-color: #00c2a8 !important;
    color: #ffffff !important;
    padding: 4px 8px !important;
    border-radius: 14px !important;
    font-size: 14px !important;
}

.done {
    background-color: #00ff91 !important;
    color: #333333 !important;
}

.failed {
    background-color: #F44336 !important;
    color: #ffffff !important;
}

.successTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background-color: #00ff91;
    padding: 10px;
    border-radius: 4px;
    color: #000000;
    margin: 0;
}

.failedTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background-color: #ff1e1e;
    padding: 10px;
    border-radius: 4px;
    color: #fff;
    margin: 0;
}
.resultShown{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.displayHidden{
    display: none;
}

.displayVisible {
    display: block; /* Or any other display property to show the element */
}

.unsetMargin
{
    margin: 0;
}

.svgIcon
{
    margin-left: 20px;
}

