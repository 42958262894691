.handwriting_comp_wrapper {
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 1px;
    padding: 3px 10px;
    background: #fff;
    cursor: pointer;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray);
}

.handwriting_comp_popover .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    min-width: 610px;
}

.handwriting_comp_popover .styles_pics_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.handwriting_comp_popover .styles_pics_wrapper img{
    opacity: 0.4;
    width: 150px;
    cursor: pointer;
    margin-bottom: 10px;
}

.handwriting_comp_popover .styles_pics_wrapper img:hover,
.handwriting_comp_popover .styles_pics_wrapper img.selected{
    opacity: 1;
}

.handwriting_comp_popover .styles_pics_wrapper .styles__recommended_text {
    margin: -5px 0 2px 0;
}