.notification-skeleton-parent .skeleton_each_notification {
    display: flex;
    align-items: center;
    padding: 15px 0;
  }

  .notification-skeleton-parent .skeleton_notification_edit_icon {
    margin-left: 5px;
    margin-right: 5px;
  }

  .notification-skeleton-parent .skeleton_notification_details {
    display: flex;
    flex-direction: column;
  }

  .notification-skeleton-parent .no-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 18px;
    color: #777;
  }




