.global___sms_send_modal__alt .sms__email__wrapper__top__select__innter .trigger__select {
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: unset !important;
    max-width: unset !important;
    width: 100% !important;
    height: 50px !important;
}
.global___sms_send_modal__alt .sms__email__wrapper__top__select__innter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.global___sms_send_modal__alt .sms__email__wrapper__top__title{
    font-family: var(--poppins);
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
    margin: 0;

}
.global___sms_send_modal__alt .sms__email__wrapper__top__select{
    width: 50%;
}
.global___sms_send_modal__alt .sms__email__wrapper__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px 0;
}
.global___sms_send_modal__alt textarea.g__sms__message {
    border: 0;
    resize: none;
    height: 70px;
    padding: 0 10px 0 0px;
    line-height: 24px;
    color: black;
}
.global___sms_send_modal__alt .g__sms__text__area__active textarea.g__sms__message{
    height: 100% !important;
}
.global___sms_send_modal__alt .g__sms__text__area__divided{
    display: flex;
    width: 100%;
}
.global___sms_send_modal__alt .g__sms__text__area__divided__left{
    width: 100%;
}
.global___sms_send_modal__alt .g__sms__text__area__divided__left.g__sms__text__area__active{
    border-right: 2px solid #ddd;
    margin: 0 12px 0 0px;
    width: 60% !important;
}
.global___sms_send_modal__alt .g__sms__text__area__divided__right{
    width: 40%;
}
.global___sms_send_modal__alt .g__sms__text__area__divided__right.d-none{
    width: unset !important;
}
.global___sms_send_modal__alt .g__sms__text__area__divided__right img {
    width: 192px;
    height: 132px;
    object-fit: cover;
    object-position: top;
}
.global___sms_send_modal__alt .preview__holder__sms__module{
    position: relative;
    margin: 7px 0 0 0;
}
.global___sms_send_modal__alt .preview__holder__sms__module .g__sms__img__close{
    position: absolute;
    top: -20px;
    right: 117px;
    color: #fff;
    z-index: 999;
    opacity: 1111;
    height: 20px;
    width: 20px;
    background: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.global___sms_send_modal__alt .preview__holder__sms__module .g__sms__img__close svg{
    font-size: 20px;
}

.global___sms_send_modal__alt textarea.g__sms__message:focus {
    outline: 0;
}

.global___sms_send_modal__alt .g__sms__send__textarea__alt {
    border: 1px solid #ddd;
    background: var(--white);
    padding: 16px;
    border-radius: 6px;
}

.global___sms_send_modal__alt .g__sms__send__options {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    margin: 0 0 16px 0;
}
.global___sms_send_modal__alt .g-personalized-button svg {
    margin: 0 5px 0 0;
}
.global___sms_send_modal__alt .g__template__button,
.global___sms_send_modal__alt .g__attachment__button,
.global___sms_send_modal__alt .g__sms__send__options .g-personalized-button{
    border: 1px solid rgba(19,49,89,.35)!important;
    border-radius: 5px!important;
    height: 30px!important;
    align-items: center;
    display: inline-flex;
    box-sizing: border-box!important;
    padding: 5px 8px;
    color: var(--dark_blue);
    font-size: 14px;
    cursor: pointer;
}
.global___sms_send_modal__alt .g__attachment__button svg{
    font-size: 18px;
}
.global___sms_send_modal__alt .g__attachment__button,
.global___sms_send_modal__alt .g__template__button{
    display: flex;
    align-items: center;
    grid-gap: 5px;
}
.global___sms_send_modal__alt .g__attachment__button span,
.global___sms_send_modal__alt .g__template__button span{
    display: inline-flex;
}
.global___sms_send_modal__alt .g__attachment__button .g__sms__only__image{
    font-size: 10px;
    color: var(--red);

}
.global___sms_send_modal__alt .g__save__as__template__title input{
    min-height: unset!important;
    padding: 12px 15px;
    background: #fff;
    border: 1px solid #e5e8ef!important;
    border-radius: 5px;
    color: rgba(19,49,89,.6509803921568628)!important;
    font-family: var(--Poppins);
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 24px;
}
.global___sms_send_modal__alt .g__save__as__template__title input:focus{
    box-shadow: 0 0 0 rgba(0,0,0,0.0) !important;
}


/* global time setting css start */
.global___sms_send_modal__alt .global__send__module__time__setting{
    text-align: center;
    border: 1px solid #ddd;
    padding: 23px 20px;
    border-radius: 6px;
    margin: 10px 0 0 0;
    width: 100%;
}
.global___sms_send_modal__alt .g__sms__custom_date_wrapper{
    display: flex;
    padding: 20px 0 0;
    grid-gap: 20px;
    justify-content: center;
}
.global___sms_send_modal__alt .time__setting__tabs{
    display: flex;
    justify-content: center;
    grid-gap: 15px;
}
.global___sms_send_modal__alt .global__send__module__time__setting__title{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.global___sms_send_modal__alt .time__setting__tabs button{
    box-shadow: 0,0,0 rgba(0,0,0,0.0);
}
.global___sms_send_modal__alt .g__sms__send__date__alt__title,
.global___sms_send_modal__alt .g__sms__send__time__alt__title
{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--dark_blue);
    text-align: left;
}
.global___sms_send_modal__alt .g__sms__send__date__alt .MuiTextField-root,
.global___sms_send_modal__alt .g__sms__send__time__alt .MuiTextField-root {
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 6px;
}
.global___sms_send_modal__alt .gloabal__send__module__time__setting__container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.global___sms_send_modal__alt .g__sms__send__date__alt .MuiTextField-root .MuiInputBase-input,
.global___sms_send_modal__alt .g__sms__send__time__alt .MuiTextField-root .MuiInputBase-input{
    height: 48px;
    margin: 0;
}
/* global time setting css end */
.g-sms-send-content.awesome__scroll_bar, .g__sms__message.awesome__scroll_bar{
    padding-bottom: unset !important;
}

/* quick reply css */
.global___sms_send_modal__alt .g-template-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
}
.global___sms_send_modal__alt .g-template-back{
    text-align: right;
    cursor: pointer;
}
.global___sms_send_modal__alt .g-template-back .quick__back__btn__alt{
    display: inline-block;
    padding: 5px 10px;
    border-radius: 6px;
}
.globalModalWrapper.global___sms_send_modal__alt .massage_send_close_button .send_massage span{
    border: 0 !important;
}
.globalModalWrapper.global___sms_send_modal__alt .g__save__as__content{
    margin-left: 15px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.global___sms_send_modal__alt .modal__text__couter__wrapper__v2{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.global___sms_send_modal__alt .modal__text__couter__wrapper__v2 p{
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: var(--accent-color);

}
.global___sms_send_modal__alt .modal__text__couter__wrapper__v2 .MuiFormControlLabel-root{
    margin-left: -22px;
}
.global___sms_send_modal__alt .modal__text__couter__wrapper__v2 .MuiFormControlLabel-root .MuiIconButton-root{
    padding: 6px !important;
}

.global___sms_send_modal__alt .mtcwv__unsub_wraper{
    display: flex;
    align-items: center;
    grid-gap: 8px;
}
.global___sms_send_modal__alt .mtcwv__unsub_wraper .add__unsubscried__text{
    display: flex;
    align-items: center;
    grid-gap: 3px;
}

.global___sms_send_modal__alt .sms_module__global_custom__field .label_class_wrapper {
    border: 1px solid rgba(19,49,89,.35)!important;
    border-radius: 5px!important;
    height: 30px!important;
    align-items: center;
    display: inline-flex;
    box-sizing: border-box!important;
    padding: 5px 8px;
    color: var(--dark_blue);
    font-size: 14px;
    cursor: pointer;
}

.global___sms_send_modal__alt .sms_module__global_custom__field .label_class_wrapper svg path {
    fill: var(--dark_blue);
}

/* global scroll bar for pdad */
.tdp_scroll_bar{
    height: 100%;
    padding-bottom: 4px;
    overflow-y: auto;
}
.tdp_scroll_bar::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.tdp_scroll_bar::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}
.tdp_scroll_bar::-webkit-scrollbar {
    scrollbar-width: thin;
}
.tdp_scroll_bar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #949DB2;
    box-sizing: border-box;
}
.tdp_scroll_bar::-webkit-scrollbar-track {
    background: #F4F5F7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}