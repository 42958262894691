.mainWrp{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 14px;
    background-color: #FFFFFF;
}
.headerWrp{
    width: 100%;
    height: 80px;
    padding: 5px 10px 5px 20px;
    border-bottom: 1px solid #E5E8EF;
    display: flex;
    align-items: center;
    gap: 10px
}
.headerTitle{
    font-size: 18px;
    font-weight: 600;
}
.bodyWrp{
    width: 100%;
    height: calc(100% - 81px);
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
}
.bodyTitle{
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 5px;
}
.bodyText{
    display: flex;
    width: 100%;
    font-size: 14px;
    text-align: justify;
    letter-spacing: 0.15px;
}
.marginTop20{
    margin-top: 20px;
}
.buttonWrp{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
    gap: 20px;
}
.button{
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    font-weight: bold;
}
.editButton{
    border: 1px solid #ff0000;
    color: #ff0000;
}
.sendButton{
    background-color:#2358e9;
    color: #FFF;
}