.not__found__page {
    background: url('https://app.pypepro.com/assets/img/error/404.png') no-repeat fixed center/cover;
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
}

.not__found__page p {
    font-size: 20px;
    font-weight: 600;
    color: teal;
    margin-top: 210px;
}