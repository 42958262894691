.free_virtual_number .tbody_content {
    max-height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
}
.free_virtual_number .tbody_content tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.free_virtual_number thead, .free_virtual_number .tbody_content tr {
    table-layout: fixed;
    display: table;
}
.free_virtual_number thead {
    width: 100%;
}
.onboard_number_stage .tag_list h5 {
    margin: 0;
}

.free_virtual_number th, .free_virtual_number tr, .free_virtual_number button {
    font-size: 12px;
}