
/* Update Video Email from inbox */
.v3_video_email_send_button.send-button-area {
    min-width: 130px;
}

.v3_custom_select_wrapper .form-control, .v3_video_email_send_button.send-button-area a {
    border-radius: 6px;
    width: 168px !important;
    height: 40px;
    border: 1px solid rgb(0 0 0 / 10%);
    box-sizing: border-box;
    font-weight: normal;
    font-size: 14px;
    color: #133159;
    display: inline-block;
    line-height: 40px;
    text-align: center;
}
.v3_custom_select_wrapper .form-control {
    text-align: left;
    padding-left: 15px;
}
.v3_custom_select_wrapper.personiliz-input-field svg {
    position: static;
}
.v3__video_email_field_wrapper .borderStyle1px, .v3__video_email_field_wrapper input.borderStyle1px {
    height: 40px !important;
    margin: 0 !important;
    width: auto;
}
.v3__video_email_field_wrapper .customWidth .borderStyle1px {
    width: 100%;
    max-width: 453px;
}
.v3__video_email_field_wrapper .borderStyle1px.alt svg {
    position: static;
}

.v3__video_email_field_wrapper .borderStyle1px.alt input {
    height: auto;
}

.v3__video_email_field_wrapper.alt .borderStyle1px {
    border: 0 !important;
    border-radius: 0 !important;
    height: 38px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}
.v3_modal__scroll .video_contents {
    margin: 10px;
}
.v3__video_email_field_wrapper.alt .mixed_col {
    border: 1px solid rgba(19, 49, 89, 0.35) !important;
    border-radius: 5px !important;
    height: 40px !important;
    box-sizing: border-box !important;
    padding: 0 !important;
    display: flex;
    width: 100%;
    max-width: 453px;
}
.v3_modal__scroll .v3_video_email_send_button.send-button-area ,
.v3_modal__scroll .tiny-mce {
    padding: 0;
}
.videoEmailModalWrapperV3 {
    width: 950px;
}
.videoEmailModalWrapperV3 .massage_send_close_button .send_massage {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.videoEmailModalWrapperV3 .massage_send_close_button .send_massage > span i {
    font-size: 20px;
    margin: 0;
}
.videoEmailModalWrapperV3 .massage_send_close_button > .send_massage span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.input-field.number-input-field.personiliz-input-field.v3_custom_select_wrapper {
    margin: 0;
    width: auto !important;
}
.saved_reply_modal_wrapper .modal_thumbnail .manage_modal_card {
    height: 250px !important;
}
.video_library_modal_wrapper .video_thumbnails_scroll.awesome__scroll_bar {
    height: 100%;
}

.video_library_modal_wrapper .video_thumbnails .card {
    border: 2px solid #d9d9d9;
    padding: 5px !important;
    box-sizing: border-box;
    border-radius: 4px;
    transition: all 0.3s linear;
    cursor: pointer;
}
.video_library_modal_wrapper .card .card-image {
    height: 100%;
}
.video_library_modal_wrapper .lazy-load-image-background.blur.lazy-load-image-loaded > img {
    height: 100%;
    object-fit: cover;
}
.video_library_modal_wrapper .lazy-load-image-background.blur.lazy-load-image-loaded {
    height: 100%;
}
.video_library_modal_wrapper .video_thumbnails .video_thumbnails_card::before {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
}
.video_library_modal_wrapper .video_thumbnails_scroll.awesome__scroll_bar {
    height: 100%;
}
.video_library_modal_wrapper .video_thumbnails .card:hover {
    border-color: #fcd462;
}
.video_library_modal_wrapper .no-gutter .card_content {
    z-index: 2;
}

.video_library_modal_wrapper .card .card-image .MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-wave {
    width: 160px !important;
}
.video_library_modal_wrapper .video_folders a {
    text-transform: capitalize;
}
.video_library_modal_wrapper .video_folders a:not(:first-child) {
    color: #133159;
}
.video_library_modal_wrapper .video_library_top_folder li {
    cursor: pointer;
}

.video_library_modal_wrapper .video_folders p {
    margin-top: 0;
}
.video_library_modal_wrapper .card .card-image .card-title {
    width: calc(100% - 60px);
}
.video_library_modal_wrapper .video_thumbnails .card .card_content .right_icons {
    display: inline-flex;
    align-items: center;
}
.video_library_modal_wrapper .card_content .right_icons span {
    margin-right: 0;
}
.video_library_modal_wrapper .card_content .right_icons svg {
    margin-right: 6px;
}

.v3_video_email_send_button .alter.input-field.number-input-field.personiliz-input-field.v3_custom_select_wrapper .MuiFormControl-root.form-control {
    line-height: unset !important;
    display: flex;
    justify-content: center;
}
