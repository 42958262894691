.send_mail_to{
  min-width: 35px;
  font-size: 14px !important;
  font-weight: 400;
  color: #777;
  width: 20px;
  cursor: pointer;
}
.send_email_select em{
  background-color: #e0e0e0;
  padding: 10px 12px;
  border-radius: 22%
}
.send_add_bcc,
.send_add_cc{
  border: 1px solid #ddd;
  border-radius: 6px;
  margin: 0;
  padding: 6px 10px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.send_add_cc{
  margin-right: 10px;
}
.ds__cc_wrapper, .ds__singleItem {
  align-items: flex-start!important;
}
.ds__cc_wrapper {
  padding: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
  position: relative;
  min-height: 60px;
}
.common__round_circle {
  border: 1px solid #ddd;
  border-radius: 6px;
  margin: 0;
  padding: 6px 10px;
}
.ds__email_to_cc_bcc{
  font-size: 14px!important;
  font-weight: 400;
  color: #777;
  width: 20px;
  cursor: pointer;
}
.ds__cc_wrapper .ds__singleItem span, 
.ds__email_to_cc_bcc {
  flex: unset!important;
  min-width: 35px;
  font-size: 14px!important;
  font-weight: 400;
  color: #777;
  width: 20px;
  cursor: pointer;
}
.customColumns {
  width: calc(100% - 35px);
  position: relative;
  padding-right: 76px;
  flex-wrap: wrap;
}
.clear_all {
  border-color: red !important;
  font-size: 13px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.common__round_circle {
  border: 1px solid #ddd;
  border-radius: 6px;
  margin: 0;
  padding: 6px 10px;
  margin-right: 10px;
}
.common__round_circle:last-child{
  margin-right: 0px;
}
.common__round_circle input:focus, 
.addAppointment_wrapper select:focus{
  box-shadow: unset !important;
  border-bottom: 1px solid #ddd !important;
}
.trigger__modal_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.trigger__select {
  margin: 0!important;
  padding: 0!important;
  box-shadow: unset!important;
  max-width: 295px !important;
  width: 100% !important;
  height: 50px !important;
}
/* .customFormControl.alt .trigger__select .MuiSelect-select, 
.trigger__modal_textField {
  border: 1px solid #ccd2d2!important;
} */
.trigger__select .MuiSelect-select {
  min-height: unset!important;
  border: 1px solid #e5e8ef!important;
  padding: 12px 15px;
  background: #f6f7fb;
  border: 1px solid #e5e8ef;
  border-radius: 5px;
  color: rgba(19,49,89,.65);
  font-family: Poppins;
  font-weight: 400!important;
  font-size: 16px;
  line-height: 24px;
}

.ds__system_defined .MuiFormControl-root.form-control {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 0 10px;
  border-top: 0;
  border-left: 0;
  border-bottom-right-radius: 6px;
}
.ds__selects_wrapper .form-control {
  margin: 0 !important;
  padding: 0 !important;
  height: 43px;
}
.send_email_from{
  font-size: 14px;
    font-weight: 400;
    color: #777;
    width: 20px;
    cursor: pointer;
    color: #777;
}
.ds__row .trigger__modal_title {
  /* flex: 1 1; */
  margin: 0;
  margin-right: 5px;
  width: max-content;
}
.email_send_btn{
  background-color: var(--light_blue);
    color: var(--white);
    border-radius: 5px;
    padding: 12px 20px;
    margin-right: 35px;
    transition: all .3s;
    cursor: pointer;
    text-align: center;
    width: 100px;
    margin-left: auto;
}
.send_email_personalizes{
  margin: 10px 0px;
}
.send_email_personalizes .ds__template{
  margin-left: 20px;
}
.personalized_select_content input{
  height: 45px;
    border-radius: 5px !important;
    padding: 10px !important;
    color: #133159;
    font-size: 14px !important;
    width: calc(100% - 15px) !important;
    border: 1px solid #ccd2d2 !important;
    width: 100% !important;height: 45px;
    border-radius: 5px !important;
    padding: 10px !important;
    color: #133159;
    font-size: 14px !important;
    width: calc(100% - 15px) !important;
    border: 1px solid #ccd2d2 !important;
    width: 100% !important;
}
.personalized_select_content input:focus{
  box-shadow: unset !important;
  border-bottom: 1px solid #ccd2d2 !important;
}
.send_email_from .send_email_from_select{
  min-height: unset!important;
  border: 1px solid #e5e8ef!important;
  padding: 12px 15px;
  background: #f6f7fb;
  /* border: 1px solid #e5e8ef; */
  border-radius: 5px;
  color: rgba(19,49,89,.65);
  font-family: Poppins;
  font-weight: 400!important;
  font-size: 16px;
  line-height: 24px;
}
.ds__mt20pxSpace{
  margin-bottom: 20px;
}

.subject-container-alt{
  display: flex !important;
  /* justify-content: space-between; */
  width: 100%;
  height: 45px;
}

.border-none{
  border: unset !important;
}

.subject-container-alt > div {
  width: 200px !important;
}

.subject-container-alt > input {
  border: unset !important;
}

.subject-container-alt .MuiFormControl-root .personalized-select-subject #gloabal-email-send-personalized-selector-subject{
  padding : unset !important;
  line-height: unset !important;
  border: unset !important;
}

.global__send_modal_wrapper{
  /* min-height: 400px; */
  /* max-height: 400px; */
  /* overflow-y: auto; */
}

/* Send Email Modal */
.v2__send_email .v2__send_email_select .MuiChip-root {
    background: transparent;
    margin: 0;
    height: auto;
}
.v2__send_email .v2__send_email_select.MuiInputBase-formControl.MuiInput-formControl {
    background-color: transparent;
    padding: 8px 10px;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid #ddd;
    font-size: 12px;
}
.v2__send_email .v2__send_email_select .MuiSelect-select.MuiSelect-select {
    padding: 0;
}
.v2__send_email .v2__send_email_select.send_email_select.MuiInputBase-formControl.MuiInput-formControl input {
    height: auto;
    margin: 0;
}
.v2__send_email .v2__send_email_select.send_email_select em {
    background: transparent;
    border-radius: 0;
    padding: 0 20px 0 0;
}
.v2__send_email .v2__send_email_select .MuiChip-label {
    padding-left: 0;
}