
:root {
    --montserrat:'Montserrat', sans-serif;
    --roboto:'Roboto', sans-serif;
}
body{
    margin: 0;
    padding: 0;
    font-family: var(--roboto);
}

.pypepro__privacy__policy .pypepro__container{
    max-width: 1170px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.pypepro__privacy__policy .pypepro__container .pypepro__privacy__logo img{
    cursor: pointer;
    width: auto;
    height: 70px;
    object-fit: cover;
}

.pypepro__header .pypepro__container {
    height: 100%;
}

.pypepro__privacy__policy .pypepro__header{
    height: 75px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.15);
    z-index: 22;

}

.pypepro__privacy__policy .pypepro__banner{
    background-color: #F4F4FF;
    border-radius: 0px 0px 100px 100px;
    height: 300px;
    position: relative;

}
.pypepro__privacy__policy .pypepro__banner__overly{
    background-image: url(http://pypepro.com/wp-content/uploads/2020/08/About-BG.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.05;
    min-height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    border-radius: 0px 0px 100px 100px;
}
.pypepro__privacy__policy .pypepro__banner__content{
    line-height: 300px;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pypepro__privacy__policy .pypepro__banner__content h1{
    font-family: var(--montserrat);
    color: #000000;
    font-size: 60px;
    font-weight: 200;
    text-transform: uppercase;
    line-height: 1.2em;
    z-index: 2;
}
.pypepro__privacy__policy .pypepro__banner .pypepro__container{
    height: 100%;
}

.pypepro__privacy__policy .pypepro__container p{
    font-size: 15px;
    line-height: 27px;
    font-weight: normal;
    color: #3a3a3a;
}
.pypepro__privacy__policy .pypepro__inner__content{
    padding: 50px 0 50px 0;
}
.pypepro__privacy__policy .pypepro__single__item h5{
    font-size: 15px;
    line-height: 27px;
    margin: 0 0 2px 0;
    font-weight: 600;
    color: #3a3a3a;
}
.pypepro__privacy__policy .pypepro__single__item + .pypepro__single__item{
    margin: 10px 0 0 0;
} 
.pypepro__privacy__policy .pypepro__single__item p{
    margin: 0;
}