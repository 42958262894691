.contentWrapper {

}

.contentHeader {

}

.contentTitle {
    font-size: 16px;
    color: #e81313;
    margin: 0;
    padding: 0;
}

.contentDescription {
    font-size: 12px;
    color: #3a414a;
    margin: 0;
}

.contentBody {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 14px;
    padding-bottom: 20px;
}

.contentBodyLeft {
    width: 60%;
}

.contentBodyRight {
    width: 40%;
    margin-top: 22px;
}

.formControl {
    width: 100%;
}

.formControl:not(:last-child) {
    margin-bottom: 10px;
}

.formControl .label {
    color: #3a414a;
    font-size: 14px;
    margin-left: 5px;
}

.formControl .MuiInputBase-root {
    color: #3a414a !important;
}

.required {
    color: red;
}

.formControl .formInput {
    font-size: 14px !important;
    height: auto !important;
    color: #707070 !important;
    border-radius: 4px !important;
    position: relative;
    border: 1px solid #ced4da!important;
    padding: 10px 12px !important;
    margin-bottom: 0!important;
}

.formControl .formInput:focus,
.formControl .formInput:focus-visible {
    color: #707070 !important;
    box-shadow: none!important;
    border-color: #3a414a !important;
    outline-color: #3a414ac4 !important;
}

.formControl .formInput + label {
     margin-top: 24px;
     color: #3a414a !important;
     font-size: 20px !important;
}

.formControl .formSelect {
    display: block !important;
    font-size: 14px !important;
    height: auto !important;
    color: #707070 !important;
    border-radius: 4px !important;
    position: relative;
    border: 1px solid #ced4da!important;
    padding: 10px 12px !important;
    margin-bottom: 0!important;
}

.formControl .formSelect:focus,
.formControl .formSelect:focus-visible {
    color: #707070 !important;
    box-shadow: none!important;
    border-color: #3a414a !important;
    outline-color: #3a414ac4 !important;
}

.formControl .formSelect + label {
    margin-top: 24px;
    color: #3a414a !important;
    font-size: 20px !important;
}

.einDesc {
    height: 100%;
    font-size: 12px;
    padding: 20px 10px;
    border: 1px solid;
    border-radius: 4px;
    background-color: #e4ebf1;
    color: #3a414a;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.einDesc .einDescLinkIcon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.einDesc .einDescLinkIcon a {
    text-decoration: underline;
}

.einDesc .einDescStrong {
    font-weight: bold;
}

.formControl .checkboxGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow:dense;
    text-align: center;
    padding: 10px;
}

.formControl .checkboxGrid:not(:last-child) .einDescCheckbox {
    margin-bottom: 10px;
}

.radioGroupOverwrite {
    flex-direction: row !important;
    width: 100%;
    padding: 12px 20px;
    gap: 30px;
}

.addAnotherRep {
    margin: 0;
}

.contentTitleAnother {
    font-size: 16px;
    color: #3a414a;
    margin: 0 0 15px;
    padding: 0;
}

.termCheckbox {
    font-size: 14px;
    margin: 14px 14px 10px;
    padding-left: 15px;
    gap: 20px;
}

.formControlMessage {
    font-size: 12px;
    margin-left: 5px;
    color: #3a414a;
}

.processingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.processingWrapper img {
    width: 80px;
}

.processingWrapper .processingTitle {
    font-size: 17px;
    font-weight: 500;
    margin: 16px 0 35px 0;
    line-height: 24px;
}

.processingWrapper .processingMessage {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin:0;
    text-align: center;
    width: 80%;
}

.responseMessageIcon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue-2);
    border-radius: 50%;
    margin: 0 auto;
}

.responseMessageIcon svg path {
    fill: #fff;
}

.responseMessageButton {
    background-color: var(--blue-2);
    padding: 7px 11px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

.responseMessage {
    font-size: 17px;
    font-weight: 500;
    margin: 16px 0 35px 0;
    line-height: 24px;
}

.responseMessageListWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}