.global-search-box-wrapper {
    line-height: 0;
    position: relative;
}

.global-search-box-wrapper input,
.global-search-box-wrapper input[type=text],
.global-search-box-wrapper input[type=search]
{
    border: 1px solid #546376;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 0 18px;
    height: 40px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: .01em;
    color: #a6acbe;
    margin: 0;
    padding-right: 30px!important;
    background-color: #fff;
}

.global-search-box-wrapper input[type=text]:not(.browser-default):focus:not([readonly]),
.global-search-box-wrapper input[type=search]:not(.browser-default):focus:not([readonly]) {
    box-shadow: none;
    border: 1px solid #546376;
    color: #444;
}

.global-search-box-wrapper i {
    font-size: 24px;
    line-height: 32px!important;
    position: absolute;
    top: 6px;
    right: 11px;
    width: 24px;
    height: 24px!important;
    transition: color .2s ease;
    color: var(--dark_blue);
}