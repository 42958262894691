.tdpMbWrp{
    background-color: #f7efef;
    text-align: justify;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
}
.tdpMwMessage{
    color: #ff0000c6;
    font-size: 12px;
}
.tdpMwReason{
    color: #ff0000c6;
    font-size: 14px;
    font-weight: bold;
    animation: animation 2s infinite ease-in-out;
    transform: scale(1);
    text-align: center;
    padding: 5px 0px;
}
.tdpMbWrp:hover .tdpMwReason{
    animation-play-state: paused;
}
@keyframes animation {
    0% {
        transform: scale(0.8);
    }
    50% {
        transform: scale(1);
    }
    100%{
        transform: scale(0.8);
    }
}

.announceTitle {
    display: flex;
    padding: 0.5em 0.5em;
    font-weight: bold;
    letter-spacing: .1em;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    align-items: center;
    grid-gap: 8px;
    gap: 6px;
    color: #ff0000c6;
}