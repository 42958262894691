.empty-container-contact-details{
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accent-bg-opacity:hover{
    background-color: var(--accent-color-opacity1) !important;
}