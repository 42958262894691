.h__100__percent {
    height: 100vh;
}
.singin_content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.singin_form{
    max-width: 450px;
    margin: auto;
    margin-top: 160px;
}

.account_text{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
}
.account_text a{
    color: var(--light_blue);
}
.login_form{
    margin-top: 60px;
}

.login_form_input input{
    background: #FFFFFF !important;
    border: 1px solid rgba(60, 126, 243, 0.1) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #8896a8;
    padding: 2px 10px !important;
    margin-bottom: 10px !important;
}
.login_form_input input:focus{
    box-shadow: unset !important;
}
.login_form_input label{
    top: -15px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
}
input:not([type]):focus:not([readonly])+label, input[type=text]:not(.browser-default):focus:not([readonly])+label, input[type=password]:not(.browser-default):focus:not([readonly])+label, input[type=email]:not(.browser-default):focus:not([readonly])+label, input[type=url]:not(.browser-default):focus:not([readonly])+label, input[type=time]:not(.browser-default):focus:not([readonly])+label, input[type=date]:not(.browser-default):focus:not([readonly])+label, input[type=datetime]:not(.browser-default):focus:not([readonly])+label, input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, input[type=tel]:not(.browser-default):focus:not([readonly])+label, input[type=number]:not(.browser-default):focus:not([readonly])+label, input[type=search]:not(.browser-default):focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label{
    color: #3C7EF3;
}
.check_box{
    margin-bottom: 40px;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;
}
.check_box .tabs{
    width: unset !important;
}
.check_box p label span{
    margin-left: -110px !important;
}
.check_box p a{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--light_blue);
}

 
.main_logo .login-page-logo,
.topbar_content .logo .sign_up__logo,
.congratulations_page .site_logo img {
    width: auto;
    height: 75px;
    object-fit: cover;
}



[type="checkbox"].filled-in:not(:checked)+span:not(.lever):after{
    border: 1px solid #3C7EF3 !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
}
[type="checkbox"].filled-in:checked+span:not(.lever):after{
    border: 2px solid #3C7EF3 !important;
    background-color: #3C7EF3 !important;
}
[type="checkbox"]+span:not(.lever){
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.01em !important;
    color: var(--dark_blue);
}
.login_btn{
    padding: 13px 0px;
    background: #0B9EE5;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: white;
    display: block;
    margin: 0 auto;
    width: calc(100% - 22px);
    border: none;
}
.licence_text{
    margin-bottom: 40px;
}

.reset_password h2{
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    margin: 0;
}
.reset_password p{
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: var(--dark_blue);
}
/* ===============Responsvi css================= */
@media only screen and (max-width: 1600px) {
    .singin_form{
        margin-top: 50px;
    }
}
.text-danger {
    color: #ff0000;
}

.text-success {
    color: #28a745!important;
}

.singin_content .singin_form .login_form_input {
    position: relative;
}

.singin_content .singin_form .login_form_input .visible__span {
    position: absolute;
    right: 26px;
    top: 10px;
    cursor: pointer;

}

.otp_screen_logo_container{
    text-align: center;
}