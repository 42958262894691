/* notification css start */
body {
    position: relative;
}

#pypepro_notification_wrp_id {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    gap: 40px;
    position: absolute;
    min-width: 480px;
    min-height: 48px;
    height: auto;
    /* right: 24px; */
    right: -100vh;
    bottom: 24px;
    /* top: 104px; */
    border-bottom: 2px solid #006DF5;
    box-shadow: 0px 2px 4px -1px rgba(148, 157, 178, 0.2), 0px 4px 5px rgba(148, 157, 178, 0.14), 0px 1px 10px rgba(148, 157, 178, 0.12);
    border-radius: 4px 4px 0px 0px;
    z-index: 21474836470;
    transition: all 0.7s ease-in-out;
    transform: rotateX(0deg);
}

#pypepro_notification_wrp_id.animate {
    right: 24px;
    transition: all 0.7s ease-in-out;
}

#pypepro_notification_wrp_id .pypepro_nt_left_wrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px;
    gap: 8px;
    max-width: 420px;
    min-height: 36px;
    height: auto;
}

#pypepro_notification_wrp_id .pypepro_nt_left_wrp .pypepro_nt_li_wrp {
    width: 20px;
    height: 20px;
    margin: 0px 10px;
}

#pypepro_notification_wrp_id .pypepro_nt_left_wrp .pypepro_nt_info_wrp {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15px;
}

#pypepro_notification_wrp_id .pypepro_nt_left_wrp .pypepro_nt_info_wrp a {
    text-decoration: underline;
}

#pypepro_notification_wrp_id .pypepro_nt_left_wrp .pypepro_nt_info_wrp .pypepro_nt_text {
    display: block;
}

#pypepro_notification_wrp_id .pypepro_nt_left_wrp .pypepro_nt_info_wrp .pypepro_nt_des {
    display: block;
    font-size: 11px;
}

#pypepro_notification_wrp_id .pypepro_nt_close_wrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 70px;
    height: 28px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.46px;
    text-transform: capitalize;
    color: #006DF5;
    cursor: pointer;
}

#pypepro_notification_wrp_id.pypepro_nt_hide {
    right: -100vw;
    display: none !important
}

#pypepro_notification_wrp_id.pypepro_nt_replace {
    transform: rotateX(360deg);
}

/* dynamic css */
#pypepro_notification_wrp_id.info {
    background: #e1effe;
}

#pypepro_notification_wrp_id.error {
    background: #fde8e8;
}

#pypepro_notification_wrp_id.success {
    background: #def7ec;
}

#pypepro_notification_wrp_id.warning {
    background: #fdf6b2;
}

#pypepro_notification_wrp_id.default {
    background: #f3f4f6;
}

#pypepro_notification_wrp_id.info .pypepro_nt_left_wrp .pypepro_nt_info_wrp {
    color: #1a56db;
}

#pypepro_notification_wrp_id.error .pypepro_nt_left_wrp .pypepro_nt_info_wrp {
    color: #c81e1e;
}

#pypepro_notification_wrp_id.success .pypepro_nt_left_wrp .pypepro_nt_info_wrp {
    color: #28a745;
}

#pypepro_notification_wrp_id.warning .pypepro_nt_left_wrp .pypepro_nt_info_wrp {
    color: #ffc107;
}

#pypepro_notification_wrp_id.default .pypepro_nt_left_wrp .pypepro_nt_info_wrp {
    color: #374151;
}

#pypepro_notification_wrp_id.info .pypepro_nt_left_wrp .pypepro_nt_li_wrp svg {
    fill: #1a56db;
}

#pypepro_notification_wrp_id.error .pypepro_nt_left_wrp .pypepro_nt_li_wrp svg {
    fill: #c81e1e;
}

#pypepro_notification_wrp_id.success .pypepro_nt_left_wrp .pypepro_nt_li_wrp svg {
    fill: #28a745;
}

#pypepro_notification_wrp_id.warning .pypepro_nt_left_wrp .pypepro_nt_li_wrp svg {
    fill: #ffc107;
}

#pypepro_notification_wrp_id.default .pypepro_nt_left_wrp .pypepro_nt_li_wrp svg {
    fill: #374151;
}


.pypepro_notification_wrp_class_v2 {
    min-width: 380px !important;
    display: block !important;


    /* top: 104px; */
    background-color: #DEDEFF;
    border: 1px solid #b3b3ff !important;

    border-radius: 6px !important;

}

.pypepro_notification_wrp_class_v2 .pypepro_nt_left_wrp{

    display: block !important;
}

.pypepro_notification_wrp_class_v2 .pypepro_nt_left_wrp .pypepro_nt_header{
    margin: 0 !important;
}


.pypepro_notification_wrp_class_v2 .pypepro_nt_left_wrp .pypepro_nt_body{
    font-weight: 300 !important;
}

.pypepro_notification_wrp_class_v2 .pypepro_nt_left_wrp .pypepro_nt_close{
    background-color: #fff !important;
    color: #3e50f7;
    border: 1px solid #3e50f7;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
}


/* notification css end */


