/*==================Type of Campaign Css======================*/
.type_of_campaign {
    background: var(--white);
    border-radius: 10px 0px 0px 10px;
    max-width: 822px;
    left: unset;
    top: 80px !important;
    max-height: unset !important;
    position: fixed;
    right: 0 !important;
}

.top_content {
    border-bottom: 1px solid #E7EFF0;
}

.cross_button {
    background: var(--red);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
}

.Campaign_type_title h3 {
    font-size: 16px;
    line-height: 24px;
    color: var(--dark_blue);
    border-bottom: 1px solid #E7EFF0;
    padding: 30px;
}

.campaign_types_content {
    border: 1px solid #E5E8EF;
    border-radius: 10px;
}

.communication_options {
    padding: 10px 30px;
}

.communication_options ul li {
    margin-bottom: 8px;
}

.communication_options ul li a {
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: rgba(19, 49, 89, 0.5);
}

.communication_options ul li a svg {
    margin-right: 5px;
}

.full_featured_button a {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: var(--light_blue);
    border-radius: 5px;
    padding: 14px 25px;
}

.email_button {
    margin-top: 117px;
}

.cancel_button a {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: var(--red);
    border-radius: 5px;
    padding: 14px 20.5px;
}

.cancel_button a span {
    background: #fff;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: inline-block;
    text-align: center;
    line-height: 17px;
    margin-right: 10px;
}