.custom-switch-for-dm .react-switch-bg {
  display: flex;
}
.custom-switch-for-dm-off-icon{
  padding: 0px 6px 0 0;
}
.custom-switch-for-dm-on-icon{
  padding: 4px;
}
.custom-switch-for-dm-active-mode .react-switch-handle {
  top: 4px !important;
}
.custom-switch-for-dm-active-mode .react-switch-bg{
  background-color: #fff !important;
  border: 1px solid rgba(229, 232, 239, 1) !important;
}
.custom-switch-for-dm-inactive-mode .react-switch-bg {
  border: 1px solid #e1e5ec !important;
  background-color: #e1e5ec !important;
}
.custom-switch-for-dm-inactive-mode .react-switch-handle{
  top: 4px !important;
  left: 4px
}
.custom-switch-for-dm .react-switch-bg{
  height: 28px !important;
  line-height: 28px;
  border-radius: 30px !important;
}

.custom-switch-for-dm .react-switch-bg>div{
  height: unset !important;
}

.custom-switch-for-dm .react-switch-handle {
  height: 20px !important;
  width: 20px !important;
}