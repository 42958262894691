.right-side-note{
    padding: 5px !important;
}
.right-side-note .right-side-each-note-content{
    width: 100%;
    border: 1px solid #ebf2fe;
    border-radius: 6px;
    padding: 10px;
    /* background-color: #f1e472; */
    background-color: var(--yellow-10);
    margin-bottom: 5px;
}
.right-side-note .each-note-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.right-side-note .each-note-header.boder-bottom{
    border-bottom: 1px solid #ebf2fe;
    padding-bottom: 12px;
}
.right-side-note .info-content{
    width: 160px;
    display: flex;
    flex-direction: column;
}
.right-side-note .header-title{
    width: 100%;
    display: flex;
    font-size: 14px;
    color: var(--dark_blue);;
}
.right-side-note .header-date{
    width: 100%;
    display: flex;
    font-size: 12px;
    color: rgb(82, 76, 76);
}
.right-side-note .option-icon{
    padding: 0px 2px;
    cursor: pointer;
    color: var(--accent-color);
}
.right-side-note .each-note-details{
    padding: 5px;
    width: 100%;
}
.right-side-note .each-note-details-text{
    font-size: 14px;
    white-space: pre-wrap;
}

.right-side-add-note-contet .note-text-area{
    padding: 10px;
    height: 200px;
    border-radius: 6px;
    resize: none;
    background: #fff;
}