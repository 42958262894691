.ib__voice_send_modal .g-voice-send-file-content{
    display: flex;
    padding: 10px;
    background-color: #ebf2fe;
    margin: 30px 0px;
    border-radius: 6px;
    height: 180px;
}
.ib__voice_send_modal .g-voice-send-file-content .g-file-action-icon{
    display: flex;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
}
.ib__voice_send_modal .g-file-action-icon .g-action-icon-each{
    margin: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.ib__voice_send_modal .g-action-icon-each .g-action-icon{
    height: 40px;
    width: 40px;
    background: #d9e6fd;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 10px;
}
.ib__voice_send_modal .g-action-icon-each .g-icon-label{
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: #546376;
    line-height: 26px;
}
.ib__voice_send_modal .g-action-icon.g-start {
    color: #3C7EF3;
}
.ib__voice_send_modal .g-action-icon.g-stop {
    color: #FF264A;
}
.ib__voice_send_modal .g-action-icon.g-upload {
    color: #3C7EF3;
}
.ib__voice_send_modal .g-action-icon.g-save {
    color: var(--accent-color);
}
.ib__voice_send_modal .g-action-icon.g-start.g-disabled,
.ib__voice_send_modal .g-action-icon.g-stop.g-disabled,
.ib__voice_send_modal .g-action-icon.g-upload.g-disabled,
.ib__voice_send_modal .g-action-icon.g-save.g-disabled
{
    cursor: not-allowed !important;
    opacity: 0.5;
} 
.ib__voice_send_modal .g-action-icon-each.g-margin-alt{
    margin-left: 10px !important;
}
.ib__voice_send_modal .g-file-content{
    display: flex;
    padding: 20px;
    border: 1px solid #d9e6fd;
    border-radius: 6px;
    background: #fff;
    flex: 1;
}
.ib__voice_send_modal .g-file-content .g-empty-file{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    position: relative;
}
.ib__voice_send_modal .g-empty-file .g-empty-bg-icon{
    position: absolute;
    justify-content: center;
    align-items: center;
    color: #d9e6fd;
}
.ib__voice_send_modal .g-empty-title{
    z-index: 9;
    color: var(--dark_blue);
}
.ib__voice_send_modal .g-empty-file .g-empty-bg-icon .material-icons{
    font-size: 80px;
}
.ib__voice_send_modal .g-file-control-content{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.ib__voice_send_modal .g-file-control-content .g-record-count-down{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9e6fd;
    height: 100px;
    width: 100px;
    border-radius: 50px;
    font-weight: bold;
    color: var(--dark_blue);
}
.ib__voice_send_modal .g-file-max-info{
    align-items: center;
    display: flex;
    color: #ff6b6b;
    margin-top: -20px;
}
.ib__voice_send_modal .g-file-max-info svg{
    margin-right: 10px;
}
.ib__voice_send_modal .g-template-each-voice{
    display: flex;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid rgba(60, 126, 243, 0.1);
    flex-direction: column;
    margin-bottom: 10px;
}
.ib__voice_send_modal .g-template-each-voice .g-template-each-title-voice{
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    align-items: center;
    color: var(--dark_blue);
}
.ib__voice_send_modal .g-template-each-voice .g-template-file-view{
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ib__voice_send_modal .g-template-each-voice .g-template-each-select{
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 6px;
    color: var(--dark_blue);
}

.ib__voice_send_modal .g-template-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 20px 0px;
}
.ib__voice_send_modal .g-template-header .g-template-title{
    color: var(--dark_blue);
    font-size: 18px;
}

.ib__voice_send_modal .g-template-header .g-template-back {
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    background-color: var(--accent-color);
    color: #fff;
}
.ib__voice_send_modal .isGlobalLoading {
    display: grid;
    place-items: center;
    height: 100%;
} 
.ib__voice_send_modal .g-voice-send-vn-content {
    display: flex;
    align-items: center;
}
.ib__voice_send_modal .g-voice-send-vn-content .g-voice-send-vn-label{
    color: var(--dark_blue);
    font-size: 18px;
    margin-right: 20px;
}
.ib__voice_send_modal .g__save__as__content{
    margin-left: 11px;
    margin-top: 10px;
}
.ib__voice_send_modal .g__save__as__template__title input{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0px 10px;
    margin-top: 10px;
}