.contact-details-container .addName__actions_wrapper .outcome_tabs {
    margin-bottom: 2px;
    display: flex;
}
.contact-details-container .addName__actions_wrapper .outcome_tabs li {
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #dddddd;
}
.contact-details-container .addName__actions_wrapper .outcome_tabs li.active{
    border-bottom: 1px solid;
    border-bottom-color: var(--accent-color);
}
.contact-details-container .addName__actions_wrapper .outcome_tabs li.active a {
    color: var(--accent-color);
}
.contact-details-container .addName__actions_wrapper .outcome_tabs li a {
    color: #3c3c3c;
}
.contact-details-container .addName__actions_wrapper .outcome_tabs li a:hover {
    color: var(--accent-color);
}
.contact-details-container .addName__actions_wrapper .action_icons .contact__info_lists{
    margin: 0;
}

.contact-details-container .addName__actions_wrapper .call__review_wrapper {
    background: white;
    padding: 30px 0;
}

.contact-details-container .addNote__message textarea{
    border-radius: 6px;
    height: 182px;
    width: 834px;
    background: #fff;
    padding: 10px;
    resize: none;
}
.contact-details-container .addNote__message .clear__note__btn{
    align-items: center;
    padding: 13px 15px;
    width: 136px;
    height: 50px;
    background: #e71046;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .01em;
    color: #fff;
    cursor: pointer;
    border: 0!important;
    /* margin: 20px 0 20px auto; */
}

.contact-details-container .note__blue_btn{
    margin: 0 !important;
}

.contact-details-container .addNote__message .edit__and__add__note__section{
    display: flex;
    grid-gap: 16px;
    justify-content: flex-end;
    padding: 0 13px;
    margin: 13px 0 18px 0;
}

.noteList {
    background: #f6f7fb;
    border-radius: 10px 10px 10px 0px;
    position: relative;
}

.noteList_header {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #133159;
    padding: 20px 20px 13px;
    border-bottom: 1px solid #e5e8ef;
}

.noteList_content > *,
.noteList_content {
    padding: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #546376;
}
