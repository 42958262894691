.video__mail__modal__top__wrapper .borderStyle1px__alt{
    border: 1px solid rgba(19,49,89,.35)!important;
    border-radius: 5px!important;
    padding: 0 15px!important;
    height: 40px!important;
    margin: 0!important;
    align-items: center;
    display: inline-flex;
    box-sizing: border-box!important;
    width: 100%;
    max-width: 453px;
}

.video__mail__modal__top__wrapper .video__email__left__label{
    font-size: 14px;
}

.video__mail__modal__top__wrapper .video__select__input__wr{
    border: 1px solid #ced4da !important;
    border-radius: 5px!important;
    height: 38px!important;
    box-sizing: border-box!important;
    padding: 0!important;
    display: flex;
    width: 100%;
    max-width: 477px
}

.video__mail__modal__top__wrapper .video__select__input___left{
    border: 0!important;
    border-radius: 0!important;
    height: 36px!important;
    background-color: #fff!important;
    padding: 0 10px!important;
    border-top-left-radius: 10px!important;
    border-bottom-left-radius: 10px!important;
    color: var(--gray);
}
.video__mail__modal__top__wrapper .video__select__input___left:focus{
    box-shadow: none !important;
    -webkit-box-shadow: unset !important;
    border-bottom: unset !important;
}
.video__select__input__wr__left input::placeholder{
    color: rgb(8 40 82 / 44%) ;
    font-size: 15px;
}
.video__mail__modal__top__wrapper .video__select__input__wr__right.personiliz__input__alt svg{
    position: static;
}

.video__mail__modal__top__wrapper .video__select__input__wr__left{
    position: relative;
    margin: 0!important;
    width: 100%;
}
.video__mail__modal__top__wrapper .video__select__input__wr__right{
    border: 0!important;
    border-radius: 0!important;
    height: 38px!important;
    border-top-left-radius: 10px!important;
    border-bottom-left-radius: 10px!important;
}
.video__mail__modal__top__wrapper .personiliz__input__alt .MuiSelect-select.MuiSelect-select{
    padding-right: 0 !important;
    padding: 10px 0px 10px 14px;
}

.video__mail__modal__top__wrapper .video_contents__alt{
    margin: 10px;
}

.video__mail__modal__top__wrapper .video_contents__alt .flexItemCenter__alt{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 250px;

}

.video__mail__modal__top__wrapper .video_contents__alt .video_email_bg_icon__alt {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    min-height: 280px;
    margin: 14px 0;
    width: 526px;
}
.video__mail__modal__top__wrapper .video_email_bg_icon__alt .overlay_text__alt{
    z-index: 1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 35px;
    left: 0;
    right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
}
.video__mail__modal__top__wrapper .video_email_bg_icon__alt .overlay_text__alt:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.55);
    z-index: -1;

}

.video__mail__modal__top__wrapper .voice_mail_icon__altr span, 
.video__mail__modal__top__wrapper  .voice_mail_icon__altr label{
    background: var(--light-blue-10);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    align-items: center;
}

.video__mail__modal__top__wrapper .cursor-pointer__alt{
    cursor: pointer;
}

.video__mail__modal__top__wrapper .video__mail__back .light__blue__icon {
    color: var(--light__blue);
}
.video__mail__modal__top__wrapper .text-gray__altr{
    color: var(--gray);
}

.video__mail__modal__top__wrapper .text-red__altr{
    color: var(--red);
}
.video__mail__modal__top__wrapper .voice_mail_icon__altr {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.video__mail__modal__top__wrapper .personiliz__extra__alt{
    position: relative;
    margin: 10px 0 0 0;
}

.video__mail__modal__top__wrapper .personiliz__extra__alt .MuiFormControl-root.form-control {
    line-height: unset!important;
    display: flex;
    justify-content: center;
}

.video__mail__modal__top__wrapper .personiliz__extra__alt  .MuiSelect-icon{
    right: 8px;
}

.video__mail__modal__top__wrapper .personiliz__extra__alt .form-control {
    text-align: left;
    padding-left: 15px;
    border-radius: 6px;
    width: 168px!important;
    height: 40px;
    border: 1px solid rgb(0 0 0/10%);
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    color: #133159;
    display: inline-block;
    line-height: 40px;
}

.video__mail__modal__top__wrapper .video__mail__back{
    background-color: var(--light-blue-10);
    border-radius: 10px;
}

.video__mail__modal__top__wrapper .video__mail__back .font__size__14{
    font-size: 14px;
}

.video__mail__modal__top__wrapper .video__mail__back .margin__top__bottom{
    margin: 1rem 0;
} 


/* Video Library Css */

.video_library_modal_wrapper__alt{
    width: 950px !important;
}

.video_library_modal_wrapper__alt .video_library_top_folder__alt{
    margin: 1rem;
}

.video_library_modal_wrapper__alt .video_folders__alt ul li {
    margin-right: 23px;
}
.video_library_modal_wrapper__alt .video_thumbnails_card__alt.card{
    z-index: 0;
    border: none;
    position: relative;
    background: unset!important;
    box-shadow: unset!important;
    position: relative;
    height: 198px;
    margin: 0 15px 15px 11px;
    padding: 0 !important;
}
.video_library_modal_wrapper__alt .video_folders__alt{
    padding: 0 30px;
}

.video_library_modal_wrapper__alt .no-gutter {
    padding: 0!important;
}

.video_library_modal_wrapper__alt .video_thumbnails__alt{
    margin: 0 17px;
    padding-bottom: 2px;
}
.video_library_modal_wrapper__alt .video_folders__alt ul li h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #133159;
    margin: 0;
    text-transform: capitalize;
}

.video_library_modal_wrapper__alt .video_folders__alt ul li p{
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: rgba(19,49,89,.65);
    margin: 0;

}

.video_library_modal_wrapper__alt .no-gutter .card_content__alt{
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

.video_library_modal_wrapper__alt .card_content__alt .card-title {
    font-weight: 500;
    font-size: 10.6978px;
    line-height: 18px;
    color: #fff!important;
    position: relative!important;
    margin: unset!important;
    top: unset!important;
    padding: 0!important;
}

.video_library_modal_wrapper__alt .card_content__alt .right_icons__alt {
    opacity: 0;
    visibility: hidden;
    transition: all .4s linear;
}

.video_library_modal_wrapper__alt .card_content__alt .right_icons__alt span {
    color: #fff;
    font-weight: 400;
    font-size: 10px;
    line-height: 17px;
    display: inline-flex;
    margin-right: 5px;
}

.video_library_modal_wrapper__alt .card .play_icon__alt{
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    position: absolute;
    height: 30px;
    opacity: 0;
    transition: all .4s linear;
    visibility: hidden;
}

.video_library_modal_wrapper__alt .no-gutter .card-image img{
    max-height: 100%;
    object-fit: cover;
    top: unset!important;
    bottom: unset!important;
    left: unset!important;
    right: unset!important;
    height: 198px
}

.video_library_modal_wrapper__alt .video_thumbnails_card__alt:hover:before {
    opacity: 1;
}

.video_library_modal_wrapper__alt .video_thumbnails_card__alt:before {
    content: "";
    background: rgba(60,126,243,.5);
    opacity: 0;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    transition: all .4s linear;
}

.video_library_modal_wrapper__alt .video_thumbnails__alt .card:hover span.play_icon__alt {
    opacity: 1;
    visibility: visible;
    z-index: 3;
}


@media screen and (max-width:1366px) {
    .video_library_modal_wrapper__alt{
        width: 100%;
    }
}


@media (max-width: 1024px){
    .video__mail__modal__top__wrapper .video_contents__alt .flexItemCenter__alt {
        min-height: unset;
        padding: 40px 0;
    }
    .video_library_modal_wrapper__alt{
        width: 90% !important;
    }
    .video_library_modal_wrapper__alt .video_library_wrapper__alt{
        width: 100%;
    }
}


.video_library_modal_wrapper .video_mail_module__global_custom__field .label_class_wrapper {
    border: none !important;
    width: 100% !important;
    align-items: center !important;
    padding: 9px 9px 9px 12px !important;
    background-color: var(--accent-color);
    color: #fff;
    font-size: 14px;
    font-weight: 600 !important;
}

.video_library_modal_wrapper .video_mail_module__global_body__custom__field .label_class_wrapper {
    height: 40px;
    display: inline-flex;
    align-items: center;
    column-gap: 24px;
    padding-left: 18px;
}

.video_library_modal_wrapper .video_mail_module__global_custom__field .label_class_wrapper svg path {
    fill: #fff;
}

.select_followup_buttons .override-Margin {
    margin: 0px 10px 32px !important
}







