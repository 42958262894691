.body{
    background-color: white;
}
.otp_form_wrapper{
    display: flex;
    justify-content: center;
}

.otp_form_wrapper .from_content{
    text-align: center;
}

.otp_form_wrapper .from_content p{
    color: #8a8a8a;
}

.otp_form_wrapper .from_content .btn_verify{
    color: white;
    background-color: rgb(60, 126, 243);

    width: 240px;
    height: 45px;
    font-size: 16px;
    /* text-transform: uppercase; */
    letter-spacing: 2.5px;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.otp_form_wrapper .from_content .btn_verify:hover{
    transform: translateY(-2px);
}

.otp_form_wrapper .from_content .btn_verify:focus{
    transform: translateY(-1px);
}

.form_content_title{
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: .01em;
    color: var(--dark_blue);
}

.form_content_time{
    color: #039be5;
}

.form_content_resend{

}

.otp_form_wrapper .from_content p.form_content_otp_error{
    color: #dc3545;
}

.form_content_resend .resend{
    color: #039be5;
}

.verification_code{
    display: flex;
    justify-content: center;
    gap: 10px;

    margin-block: 10px;
}

.verification_code input[type=text] {
    border-bottom: 2px solid #e1e1e1;
    width: 46px;
    height: 46px;
    padding: 10px;
    text-align: center;
    display: inline-block;
    box-sizing:border-box;
}

.otp_form_login_page{
    border: 1px solid #cdcdcd;
    padding: 50px;
    border-radius: 5px;
}