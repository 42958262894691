.ib__inbox_email_send_modal h3 {
	font-size: 14px;
	font-weight: 500;
	color: #777; 
	cursor: pointer;
	line-height: unset;
	max-width: 120px;
	width: 100%;
}

/*.ib__inbox_email_send_modal .modalBody .email_send_row * {*/
/*	margin: 0;*/
/*	padding:0;*/
/*}*/

.ib__inbox_email_send_modal .g-from-email-single-select {
	width: 100%;
}
.ib__inbox_email_send_modal .g-to-email-select {
	width: 100%;
	flex: 1;
}
.ib__inbox_email_send_modal .g-from-email-single-select .trigger__select,
.ib__inbox_email_send_modal .g-to-email-select .trigger__select {
	width: 100% !important;
	max-width: 100% !important;
}

.ib__inbox_email_send_modal .active-cc-bcc {
	border-color: #3C7EF3 !important;
}

.ib__inbox_email_send_modal .modalBody .email_send_row input {
	margin:0;
	font-weight: inherit;
}

.ib__inbox_email_send_modal .modalBody .email_send_row .MuiSelect-select .MuiChip-root {
	padding: unset;
	background: transparent;
	font-size: inherit;
	height: auto;
	color: inherit;
	line-height: 24px;
	font-weight: 700;
}

.ib__inbox_email_send_modal .g-email-send-subject-content {
	flex: 0 1 100%;
}

.ib__inbox_email_send_modal .email_send_row .g-from-email-content {
	flex: 1 0 100%;
	display: flex;
	align-items: center;
}

.align-content-center.d-flex.email_send_row.g-from-email-subject-content {
	flex-wrap: wrap;
}

.ib__inbox_email_send_modal .modalBody .email_send_row .MuiSelect-select {
	padding: 6px 24px 6px 14px;
	font-size: 15px;
	border: 1px solid #e5e8ef;
	box-sizing: border-box;
	min-height: unset;
	border-radius: 4px;
	color: #133159e0;
	min-width: 295px;
	line-height: 24px;
	background: white;
}
.ib__inbox_email_send_modal .modalBody .email_send_row .MuiSelect-select:focus{
	background: white !important;
}

.ib__inbox_email_send_modal .email_send_row {
	display: flex;
	align-items: center;
}

.ib__inbox_email_send_modal .email_send_row .g-subject {
	display: flex;
	align-items: center;
	border: 1px solid #ddd;
	border-radius: 4px;
	width: 100%;
	color: #374354;
	box-sizing: border-box;
} 

.ib__inbox_email_send_modal .modalBody .email_send_row  .g-personalized-button {
	padding: 0 10px;
	height: 46px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	border-left: 1px solid #ddd;
	background: var(--accent-color);
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	color: #fff;
}

.ib__inbox_email_send_modal .email_send_row {
	margin-bottom: 10px;
}

.ib__inbox_email_send_modal .tox-tinymce {
	background: #fff;
	box-shadow: 1px 2px 1px 0px #91919191;
}

.ib__inbox_email_send_modal .tox .tox-toolbar-overlord {
	width: 100%;
	max-width: fit-content;
	margin-left: 13px;
}

.ib__inbox_email_send_modal .tox .tox-menubar {
	background: unset;
}

.ib__inbox_email_send_modal .tox .tox-editor-container > .tox-editor-header {
	border-bottom: 1px solid #ddd;
	padding-bottom: 10px;
} 

.ib__inbox_email_send_modal .g-message {
	margin-bottom: 12px;
}

.ib__inbox_email_send_modal .g-email-send-options {
	display: flex;
}

.ib__inbox_email_send_modal .g-attachment-content button {
	box-shadow: unset;
}

.ib__inbox_email_send_modal label {
	font-size: .8rem;
	color: #133159e0;
} 

.ib__inbox_email_send_modal .email_send_row .g-subject {
	position: relative;
	display: flex;
	align-items: center;
	border: 1px solid #ddd;
	border-radius: 4px;
	width: 100%;
	color: #374354;
	box-sizing: border-box;
}

.ib__inbox_email_send_modal .overlay-disabled {
	position: absolute;
	left: 0;
	top: 0;
	user-select: none;
	width: 100%;
	height: 100%;
}

.ib__inbox_email_send_modal .email_send_row .g-subject input {
	padding-left: 14px;
	border: 0;
	box-shadow: unset;
	outline: 0;
	font-size: 14px;
	padding-right: 14px;
	width: 100%;
	box-sizing: border-box;
	color: #133159e0;
}
.ib__inbox_email_send_modal .email_send_row .g-subject input::placeholder {
	color: #929DA9;
	font-size: 15px;

}
.ib__inbox_email_send_modal .tox .tox-toolbar__primary {
	border-bottom: 0;
}
.ib__inbox_email_send_modal .email_send_row .g-subject input:focus {
	border: 0;
	box-shadow: unset;
}

.ib__inbox_email_send_modal .modalBody .email_send_row  .g-personalized-button {
	padding: 0 10px; 
	height: 46px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	border-left: 1px solid #ddd;
	background: var(--accent-color);
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	color: #fff;
}

.ib__inbox_email_send_modal .email_send_row {
	margin-bottom: 10px;
}

.ib__inbox_email_send_modal .tox-tinymce {
	background: #fff;
	box-shadow: 1px 2px 1px 0 #91919191;
}

.ib__inbox_email_send_modal .tox .tox-toolbar-overlord {
	width: 100%;
	max-width: fit-content;
	margin-left: 13px;
}

.ib__inbox_email_send_modal .tox .tox-menubar {
	background: unset;
}

.ib__inbox_email_send_modal .tox .tox-editor-container > .tox-editor-header {
	border-bottom: 1px solid #ddd;
	padding-bottom: 10px;
} 

.ib__inbox_email_send_modal .g-message {
	margin-bottom: 12px;
}  

.ib__inbox_email_send_modal .g-attachment-content button {
	box-shadow: unset;
}

.ib__inbox_email_send_modal .g-save-as-content {padding: 15px;background: #fff;border-radius: 4px;margin: 15px 0;display: flex;/* align-items: center; */justify-content: space-between;box-shadow: 0 1px 2px 3px #e1e1e1;}

.ib__inbox_email_send_modal .g-personalized-content, .ib__inbox_email_send_modal .g-template-button {
	background: #ddd;
	border-radius: 4px;
	margin: 0 0 0 8px;
	padding: 8px;
	font-size: 13px;
	cursor: pointer;
	box-sizing: border-box;
	display: inline-flex;
	align-items: center;
	transition: all 0.3s;
}

.ib__inbox_email_send_modal .g-personalized-content:hover, .ib__inbox_email_send_modal .g-template-button:hover {
	background: var(--accent-color);
	color: #fff;
}

.ib__inbox_email_send_modal .tox .tox-toolbar__primary {
	border-bottom: 0;
}

.ib__inbox_email_send_modal.globalModalWrapper .massage_send_close_button .send_massage svg {
	margin: 0;
	width: 18px;
	height: 18px;
}

.ib__inbox_email_send_modal .massage_send_close_button .send_massage span {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.ib__inbox_email_send_modal .massage_send_close_button .send_massage {
	display: inline-flex;
	align-items: center;
}


.ib__inbox_email_send_modal .g-template-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
}

.ib__inbox_email_send_modal .g-template-back {
        background: #ddd;
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
}

.ib__inbox_email_send_modal .g-template-each {
        background: #fff;
        border-radius: 4px;
        padding: 15px;
        margin-bottom: 10px;
        flex-direction: column;
        display: flex;
}

.ib__inbox_email_send_modal .g-template-each span.g-template-each-title {
        font-size: 17px;
}

.ib__inbox_email_send_modal span.g-template-each-subject,
.ib__inbox_email_send_modal span.g-template-each-message {
        font-size: 13px;
        color: #535353;
}
.ib__inbox_email_send_modal .g__subject__input__alt{
	display: flex !important;
    align-items: center !important;
    border: 1px solid #ddd !important;
    border-radius: 4px !important;
    width: 100% !important;
    color: #374354 !important;
    box-sizing: border-box !important;
    padding: 0 16px !important;
}

.isGlobalLoading {
        display: grid;
        place-items: center;
        height: 100%;
} 

/* cc bcc option */
.ib__inbox_email_send_modal  .g__email_send_cc_body{
	padding: 10px;
    min-height: 60px;
	border: 1px solid #ddd;
    border-radius: 6px;
	margin: 10px 0px 10px 120px;
}
.ib__inbox_email_send_modal .g__email_send_cc_container{
	display: flex;
	position: relative;
    padding-right: 107px;
}
.ib__inbox_email_send_modal .g__email_send_cc_bcc_chip {
	height: 22px;
	background-color: #3C7EF3;
	color: #fff;
}
.ib__inbox_email_send_modal .g__email_send_cc_bcc_chip.MuiChip-deletable:focus {
	background-color: #3C7EF3;
}
.ib__inbox_email_send_modal .g__email_send_cc_bcc_chip .MuiChip-deleteIcon {
	-webkit-tap-highlight-color: transparent;
	color: rgba(255, 255, 255, 0.7);
	font-size: 16px;
	cursor: pointer;
	margin: 0px 4px 0px -4px;
}
.ib__inbox_email_send_modal .g__email_modal_add_cc_button, .g__email_modal_add_bcc_button{
	border: 1px solid #ddd;
    border-radius: 6px;
    cursor: pointer;
    padding: 5px 15px !important;
    height: 38px;
    align-items: center;
    display: flex;
    background: #fff;
	margin-left: 10px !important;
}
.ib__inbox_email_send_modal .g__email_send_cc_title{
	width: auto !important;
    margin-right: 20px;
	cursor: default !important;
}
.ib__inbox_email_send_modal .g__email_send_cc_each_email{
	display: flex;
    border: 1px solid #ddd;
    padding: 3px 10px;
    align-items: center;
    border-radius: 30px;
    margin: 0px;
    grid-gap: 5px;
	background-color: #3C7EF3;
}
.ib__inbox_email_send_modal .g__email_send_cc_each_email_title{
	color: var(--white);
}
.ib__inbox_email_send_modal .g__email_send_cc_each_email_remove{
	cursor: pointer;
	color: var(--white);
	background-color: red;
	border-radius: 50%;
	width: 15px;
	height: 18px;
	text-align: center;
	line-height: 19px;
}
.ib__inbox_email_send_modal .g__email_send_cc_add_more{
	height: 26px !important;
    margin: 0px !important;
    border-bottom: 1px solid #ddd !important;
    max-width: 200px !important;
}
.ib__inbox_email_send_modal .g__email_send_cc_clear_all{
    height: 36.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
    background: var(--red);
	margin-left: 10px;
	position: absolute;
    right: -3px;
    top: 0px;
    width: 100px !important;
}
.ib__inbox_email_send_modal .g__email_send_cc_content {
    display: flex;
    justify-content: space-between;
	width: 100%;
}
.ib__inbox_email_send_modal .g__email_send_cc_mails_content__inner {
    display: flex;
    align-items: center;
    grid-gap: 8px;
	flex-wrap: wrap !important;
}
.ib__inbox_email_send_modal .g__email_send_cc_invalid{
	color: var(--red);
    margin: 14px 0 0 0;
    display: inline-block;
}

.ib__inbox_email_send_modal.globalModalWrapper .send_module__global_custom__field .label_class_wrapper {
	padding: 0 10px;
	height: 38px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	border-left: 1px solid #ddd;
	background: var(--accent-color);
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	color: #fff;
	font-size: 13px;
}

.ib__inbox_email_send_modal.globalModalWrapper .send_module__global_custom__field .label_class_wrapper svg path {
	fill: #fff;
}

.ib__inbox_email_send_modal.globalModalWrapper .send_module__quick__reply__global_custom__field .label_class_wrapper {
	padding: 0 10px;
	height: 46px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	background: #ddd;
	font-size: 13px;
}

.ib__inbox_email_send_modal.globalModalWrapper .send_module__quick__reply__global_custom__field .label_class_wrapper:hover {
	background: var(--accent-color);
	color: #fff;
}

/*.ib__inbox_email_send_modal.globalModalWrapper .send_module__quick__reply__global_custom__field .label_class_wrapper svg {*/
/*	display: none;*/
/*}*/

.ib__inbox_email_send_modal.globalModalWrapper .g__email_send_cc_mails_content__input input {
	max-width: unset !important;
	width: 100% !important;
}

.ib__inbox_email_send_modal.globalModalWrapper .g__email_send_cc_mails_content__input {
	width: 100%;
	padding: 10px 0px;
}

.ib__inbox_email_send_modal.globalModalWrapper .g__email_send_cc_mails_content {
	width: 100%;
}
