

.parent-loader-div .new-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    display: block;
  }
  .parent-loader-div .new-loader > div {
    margin: 0;
    height: 60px;
  }
  .parent-loader-div .new-loader button {
    all: unset;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 60px;
    left: 50%;
    display: block;
    text-align: center;
  }
  
  .parent-loader-div .new-loader .MuiCircularProgress-root {
    width: 60px;
    height: 60px;
    margin: 0 !important;
    top: unset;
    left: unset;
  }