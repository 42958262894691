.modalWrapper {
    display: grid;
    place-content: center;
}
.modalInner {

}

.headerWrapper {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

/* Modal header Starts here */

.headerTitle {
    margin: 0;
    font-family: Poppins, Arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #1c1b1f;
    font-weight: 500;
}

.headerClose {
    height: 40px;
    width: 40px;
    border-radius: 50%;

    display: grid;
    place-content: center;

    cursor: pointer;
}

.headerClose:hover {
    background-color: rgba(237, 0, 57, 0.03);
}

.headerClose svg path {
    color: red;
}

/* Modal body Starts here */
.modalBody {
    margin-top: 20px;
}

.required {
    color: red;
}

.formControl {
    width: 100%;
}

.formControl:not(:last-child) {
    margin-bottom: 10px;
}

.formControl .label {
    font-size: 14px;
    margin-bottom: 3px;
}

.formControl .MuiInputBase-root {
    color: black !important;
}
.MuiList-root li {
    color: red !important;
}
.textArea {
    color: #707070;
    font-size: 14px;
    border-radius: 4px;
    padding: 8.5px 14px;
    border-color: #ced4da;
    resize: none;
}

/* Modal footer Starts here */
.modalFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    padding: 0 14px 24px;
}

.save {
    background-color: green;
}

.tabStepper {
    padding: 12px 20px;
    flex: 1;
    transition: border-bottom 0.3s;
    border-bottom: 2px solid #e5d8d8;
    text-align: center;
}

.tabStepperActive {
    border-bottom: 2px solid #9670ff;
}

.tabStepperLabel {
    font-size: 12px;
    color: #5624e3;
}

.mr1 {
    margin-right: 8px !important;
}

.overwriteButton {
    background-color: #9670ff !important;
    box-shadow: none !important;
    border: 1px solid #9670ff;
}

.overwriteButton:hover {
    background-color: #9670ff !important;
}

.overwriteButton:active {
    background-color: #9670ff !important;
    box-shadow: none !important;
}

.redButton {
    border-color: #e81313 !important;
    color: #e81313 !important;
    border-width: 2px !important;
    font-weight: 700 !important;
}

.modalBodyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #3a414a;
    padding: 10px;
    margin-bottom: 20px;
    margin-left: 14px;
    margin-right: 14px;
}

.modalBodyTitleWrapper {

}

.modalBodyTitle {
    color: #3a414a;
    font-size: 18px;
    margin: 0!important;
    padding: 0 !important;
}

.modalBodyTools {

}

.bodyContainer {
    padding: 0 14px 24px;
    overflow-y: auto;
}

.modalInner:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.bodyContainer::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.bodyContainer::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}
.bodyContainer::-webkit-scrollbar {
    scrollbar-width: thin;
}
.bodyContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}
.bodyContainer::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.url {
    color: red !important;
    font-weight: bold !important;
}

.baseAlert {
    padding: 0.6rem 0.6rem;
    background-color: #f8f9fa;
    color: #33323a;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin: 0 auto 16px;
}

.baseAlert svg {
    height: 15px;
    width: 15px;
    margin-right: 4px;
}