.share_page__wrapper {
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share_page__inner_wrapper {
    text-align: center;
}

.share_page__wrapper {
    height: 100vh;
}

.progress_icon {
    position: unset !important;
    color: #46dfc7 !important;
    margin-bottom: 14px;
}