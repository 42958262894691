.modalWrapper {
    display: grid;
    place-content: center;
}

.headerWrapper {
    position: relative;
    /* color:white; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 24px 10px 24px;
    border-bottom: 1px solid rgba(148, 157, 178, 0.24);
}

.enhanceHeaderWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(148, 157, 178, 0.24);
}

.enhanceHeaderSubTitle {
    text-align: center;
    margin: 10px !important;
}

.headerClose {
    position: absolute;
    right: 10px ;
    top: 10px ;
    height: 40px;
    width: 40px;
    border-radius: 50% ;
    display: grid ;
    place-content: center;
    cursor: pointer;
}

.headerClose:hover {
    background-color: rgba(237, 0, 57, 0.03);
}

.headerClose svg path {
    color: red;
}

.modalInner {
    width: 850px;
    height: fit-content;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
}

.modalInner:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.modalInner::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.modalInner::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}

.modalInner::-webkit-scrollbar {
    scrollbar-width: thin;
}

.modalInner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #949db2;
    box-sizing: border-box;
}

.modalInner::-webkit-scrollbar-track {
    background: #f4f5f7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}

.paddingWrp {
    padding: 20px 40px;
}

.modalBody {

}

.modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.modalBackdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: #00000008;
}

.submitButton {
    color: #fff !important;
    background-color: #006df5 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    border-radius: 12px !important;
}

.cancelButton {
    padding: 10px 20px;
    color: #f3eeef !important;
    background-color: #d53d3d !important;
    border-radius: 4px !important;
    margin-right: 10px !important;
}
.headerSubTitle {
    text-align: center;
    margin-top: 10px;
}

.howThisWork{
    color: #181f48;
    background-color: #00ff91;
    margin-top: 10px;
    padding: 2px 10px;
    border-radius: 25px;
    display: none;
}
.modalTitle{
    position: relative;
    background-color: #ffffff;
    width: 100%;
    padding: '5px';
}
.modalTitleSubClass{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px;
}
.modalTitlePaperClass{
    width: 150px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff!important;
    color: #000000;
}

.optionBorder{
    display: flex;
    border: 1px solid #E5E8ED;
}
.optionBody{
    margin: 5px;
    padding: 5px;
}
.optionCheckbox{
    border: 1px solid #E5E8ED;
    margin-right: 0px !important;
}